export const SHAPES = Object.freeze({
  scoop: "scoop",
  circle: "circle",
  plain: "plain",
  layered: "layered",
  fact: "fact",
});

export const LOAD_ANIMATIONS = Object.freeze({
  scale: "1s",
  fade: ".5s",
  fadeDelay: ".5s",
});
