import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Container, Row, Col } from "react-awesome-styled-grid";
import { Link as GatsbyLink } from "gatsby";

import Settings from "src/stores/Settings";
import PageContext from "src/stores/Page";
import getLocalizedSlug from "src/utils/getLocalizedSlug";

import Picture, {
  TYPES as PICTURE_TYPES,
  FITS as PICTURE_FITS,
} from "src/atoms/Picture";
import Link, { TYPES } from "src/atoms/Link";
import { colors, spacing } from "src/styles/variables";
import { HEADER_HEIGHT, HOME_LINK, BEZIER } from "./constants";

const isMaintenance = process.env.GATSBY_MAINTENANCE === "true";

const Menu = React.memo(({ green, opened, setOpened, setWillClose }) => {
  const { headerLinks, headerLogo, locale } = useContext(Settings);

  const {
    headerHeight: [, setHeaderHeight],
  } = useContext(PageContext);

  useEffect(() => {
    setHeaderHeight(HEADER_HEIGHT.desktop);
    if (opened) {
      setOpened(false);
      setWillClose(false);
    }
  }, []);

  if (headerLinks.length === 3) {
    headerLinks.unshift(HOME_LINK);
  }

  return (
    <Contained transparent={!green}>
      <LogoContainer>
        <Logo to={getLocalizedSlug("/", locale)}>
          <Picture
            type={PICTURE_TYPES.fixed}
            small={headerLogo}
            fit={PICTURE_FITS.contain}
          />
        </Logo>
      </LogoContainer>
      <Container>
        <Row style={{ height: "100%" }}>
          {!isMaintenance &&
            [headerLinks.slice(0, 2), headerLinks.slice(2)].map(
              (slice, index) => (
                <Col key={index} offset={{ lg: index === 0 ? 2 : 0 }} lg={14}>
                  <DesktopLinks rightAligned={index !== 0}>
                    {slice.map((headerLink, index_) => (
                      <LinkStyled
                        activeClassName="active"
                        key={index_}
                        {...headerLink}
                        type={TYPES.navigation}
                      />
                    ))}
                  </DesktopLinks>
                </Col>
              )
            )}
        </Row>
      </Container>
    </Contained>
  );
});

Menu.propTypes = {
  green: PropTypes.bool,
  opened: PropTypes.bool,
  setOpened: PropTypes.func,
  setWillClose: PropTypes.func,
};

const Contained = styled.div`
  width: 100vw;
  height: 100%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: linear-gradient(
      to top,
      ${colors.activiaGradient[0]} 0%,
      ${colors.activiaGradient[1]} 100%
    );
    opacity: ${({ transparent }) => (transparent ? "0" : "1")};
    transition: opacity 300ms ${BEZIER} 300ms;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: ${colors.activiaGreen};
    opacity: ${({ transparent }) => (transparent ? "0" : "1")};
    transition: opacity 300ms ${BEZIER} 300ms;
  }
`;

const DesktopLinks = styled.div`
  flex: 1 1;
  display: flex;
  align-items: center;
  color: ${colors.white};
  ${({ rightAligned }) =>
    !!rightAligned &&
    css`
      justify-content: flex-end;
    `}

  a:first-child {
    margin-right: ${spacing.default.xl};
  }
`;

/* Common components */
const LinkStyled = styled(Link)`
  color: ${colors.white};
  text-decoration: none;
  line-height: 1.15;

  height: 100%;
  display: flex;
  align-items: center;

  &.active {
    color: ${colors.white};
    position: relative;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      width: 100%;
      height: 4px;
      background: ${colors.white};
    }
  }
`;

const LogoContainer = styled.div`
  opacity: 1;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 25px;
`;

const Logo = styled(GatsbyLink)`
  display: block;
  width: 160px;
  height: 41px;
`;

export default Menu;
