import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import Settings from "src/stores/Settings";
import {
  colors,
  mediaquery,
  icons,
  spacing,
  fontWeights,
} from "src/styles/variables";
import { Container, Row, Col } from "react-awesome-styled-grid";
import { InView } from "react-intersection-observer";

import PageContext from "src/stores/Page";

import { HeaderM, SubHeaderS } from "src/atoms/Typography";
import Link, { TYPES } from "src/atoms/Link";
import Button from "src/atoms/Button/Button";
import Picture, { FITS } from "src/atoms/Picture";
import FloatingButton, { SIZES } from "src/molecules/FloatingButton";

import Play from "src/atoms/Vectors/Standard/Play";

import VideoModal from "src/molecules/VideoModal";

const Hero = ({
  image,
  imageMobile,
  title,
  subtext,
  cta,
  ctaList,
  position = "left",
  video,
  videoPlayButtonStyle = undefined,
  useOverlay,
  ...rest
}) => {
  const [visible, setVisible] = useState(false);
  const [ioMargin, setIoMargin] = useState(0);
  const {
    transparentHeader: [, setTransparentHeader],
    forceVisibleHeader: [, setForceVisibleHeader],
    headerHeight: [headerHeight],
  } = useContext(PageContext);
  const { videoPlayButtonLabel } = useContext(Settings).translations;
  const defaulvideoPlayButtonLabel = videoPlayButtonLabel || "Play video";

  let offset, buttonsOffset;

  switch (position) {
    case "right":
      offset = { md: 8, lg: 21 };
      buttonsOffset = { lg: 1 };
      break;
    case "center":
      offset = { md: 4, lg: 12 };
      buttonsOffset = { lg: 2 };
      break;
    case "left":
    default:
      offset = { lg: 3 };
      buttonsOffset = { lg: 3 };
  }

  useEffect(() => {
    setIoMargin(window.innerHeight - headerHeight);
  }, [headerHeight]);

  return (
    <InView
      rootMargin={`0px 0px -${ioMargin}px 0px`}
      onChange={(inView) => {
        setTransparentHeader(inView);
        setForceVisibleHeader(inView);
      }}
    >
      <HeroContainer {...rest}>
        <CurvedContainer>
          <Curved>
            <Contained>
              {(useOverlay && <ImgOverlay />) || null}
              <Picture fit={FITS.cover} small={imageMobile} large={image} />
            </Contained>
            {(title || cta) && (
              <Contained>
                <TextContent>
                  <Container>
                    {!!title && (
                      <Row>
                        <Col md={8} lg={8} offset={offset}>
                          <HeroHeaderMedium as="h1" position={position}>
                            {title}
                          </HeroHeaderMedium>
                        </Col>
                      </Row>
                    )}
                    {!!subtext && (
                      <Row>
                        <Col md={8} lg={8} offset={offset}>
                          <HeroSubHeaderSmall as="p" position={position}>
                            {subtext}
                          </HeroSubHeaderSmall>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <CtasContainer
                        md={28}
                        lg={28}
                        offset={buttonsOffset}
                        position={position}
                      >
                        {!!cta && (
                          <ButtonContainer position={position}>
                            <Link {...cta} type={TYPES.button} />
                          </ButtonContainer>
                        )}
                        {!!ctaList &&
                          ctaList.map((ctaItem, key) => (
                            <ButtonContainer position={position} key={key}>
                              <Link {...ctaItem} type={TYPES.button} />
                            </ButtonContainer>
                          ))}
                        {!!video && videoPlayButtonStyle === "CTA" && (
                          <ButtonContainer position={position}>
                            <Button
                              buttonstyle="Light"
                              onClick={() => setVisible(true)}
                              title={defaulvideoPlayButtonLabel}
                            >
                              <span>
                                {defaulvideoPlayButtonLabel}
                                <ButtonPlay />
                              </span>
                            </Button>
                          </ButtonContainer>
                        )}
                      </CtasContainer>
                    </Row>
                  </Container>
                </TextContent>
              </Contained>
            )}
          </Curved>
        </CurvedContainer>
        {!!video && videoPlayButtonStyle !== "CTA" && (
          <Container>
            <Row>
              <Col md={8} lg={8} offset={{ lg: 3 }}>
                <StyledFloatingButton
                  onClick={() => setVisible(true)}
                  size={SIZES.medium}
                  title={defaulvideoPlayButtonLabel}
                >
                  <Play width={icons.xs} />
                </StyledFloatingButton>
              </Col>
            </Row>
          </Container>
        )}
      </HeroContainer>
      {!!visible && <VideoModal id={video} onClose={() => setVisible(false)} />}
    </InView>
  );
};

Hero.propTypes = {
  image: PropTypes.object.isRequired,
  imageMobile: PropTypes.object.isRequired,
  title: PropTypes.string,
  subtext: PropTypes.string,
  cta: PropTypes.instanceOf(Object),
  ctaList: PropTypes.arrayOf(PropTypes.object),
  position: PropTypes.string,
  video: PropTypes.string,
  videoPlayButtonStyle: PropTypes.string,
  useOverlay: PropTypes.bool,
};

Hero.defaultProps = {
  title: undefined,
  cta: undefined,
  video: undefined,
  useOverlay: true,
};

const HeroContainer = styled.section`
  position: relative;
  /* put the Hero over the top of whatever comes below when using HeroSpace */
  z-index: 1;
`;

const CurvedContainer = styled.div`
  overflow: hidden;
`;

const Curved = styled.div`
  position: relative;
  background: ${colors.activiaGreen};
  width: 120%;
  left: -10%;
  height: 150vw;
  max-height: 80vh;
  overflow: hidden;
  border-radius: 0 0 90% 90% / 0 0 30vw 30vw;

  ${mediaquery.md(css`
    height: 50vw;
    max-height: initial;
  `)}

  ${mediaquery.lg(css`
    height: 40vw;
  `)}
`;

const Contained = styled.div`
  width: 100vw;
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

const ImgOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1;
`;

const TextContent = styled.div`
  display: flex;
  height: 100%;
  padding-bottom: 10vw;
  align-items: flex-end;
`;

const HeroHeaderMedium = styled(HeaderM)`
  color: ${colors.white};
  opacity: 0.9;
  margin: ${spacing.stack.sm};

  ${mediaquery.md(css`
    text-align: ${({ position }) => position};
    margin: ${spacing.stack.md};
  `)}
`;

const HeroSubHeaderSmall = styled(SubHeaderS)`
  color: ${colors.white};
  opacity: 0.9;
  margin: ${spacing.stack.sm};
  font-weight: ${fontWeights.book};

  ${mediaquery.md(css`
    text-align: ${({ position }) => position};
    margin: ${spacing.stack.md};
  `)}
`;

const CtasContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0;

  ${mediaquery.sm(css`
    flex-direction: row;
  `)}
  ${mediaquery.md(css`
    justify-content: ${({ position }) =>
      ({ center: "center", right: "flex-end", left: "flex-start" }[position] ||
      "flex-start")};
  `)}
`;

const ButtonContainer = styled.div`
  display: flex;
  margin: ${spacing.default.xs} 0 0;

  ${mediaquery.sm(css`
    margin: ${spacing.default.xs} ${spacing.default.xs} 0 0;
  `)}
  ${mediaquery.md(css`
    justify-content: ${({ position }) =>
      ({ center: "center", right: "flex-end", left: "flex-start" }[position] ||
      "flex-start")};
    margin-right: ${({ position }) =>
      ({ center: "calc(" + spacing.default.xs + " / 2)", right: "0" }[
        position
      ] || spacing.default.xs)};
    margin-left: ${({ position }) =>
      ({ center: "calc(" + spacing.default.xs + " / 2)", left: "0" }[
        position
      ] || spacing.default.xs)};
  `)}
`;

const ButtonPlay = styled(Play)`
  width: 1.3rem;
  height: 1.3rem;
  border: 2px solid ${colors.activiaMediumGreen};
  border-radius: 100%;
  font-size: 5px;
  justify-content: center;
  align-items: center;
  padding: 3px 3px 3px 4px;
  margin-left: 5px;

  path {
    fill: ${colors.activiaMediumGreen};
  }
`;

const StyledFloatingButton = styled(FloatingButton)`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(50%);

  ${mediaquery.md(css`
    bottom: 2vw;
    transform: none;
    left: initial;
  `)}
`;

export default Hero;
