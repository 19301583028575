export const TYPES = Object.freeze({
  editorialPhotography: "ContentfulEditorialPhotographyWithCard",
  editorialIllustration: "ContentfulEditorialIllustrationWithCard",
  influencer: "ContentfulInfluencerWithCard",
  recipe: "ContentfulRecipeWithCard",
  product: "ContentfulProductFlavorWithCard",
  fact: "ContentfulFactJustCard",
  trackerCategory: "ContentfulTrackerCategory",
  blank: "blank",
});

export const DIMENSIONS = Object.freeze({
  width: 200,
  height: 300,
  gutter: 20,

  doubleTextWidth: 200,
  singleTextHeight: 100,
});

export const SHAPES = Object.freeze({
  scoop: "scoop",
  circle: "circle",
  plain: "plain",
  layered: "layered",
  fact: "fact",
  trackerCategory: "trackerCategory",
});

export const ICONS = Object.freeze({
  article: "article",
  video: "video",
  recipe: "recipe",
  podcast: "podcast",
  fact: "fact",
});

export const LOAD_ANIMATIONS = Object.freeze({
  scale: "1s",
  fade: ".5s",
  fadeDelay: ".5s",
});
