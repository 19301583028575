import React from "react";
import { Container, Row, Col } from "react-awesome-styled-grid";
import styled from "styled-components";
import { spacing } from "src/styles/variables";
import Link from "./Link";

const PageComponent = (props) => (
  <Container>
    <Row>
      <ColStyled md={6} offset={{ md: 5 }}>
        <ButtonContainer>
          <Link {...props} />
        </ButtonContainer>
      </ColStyled>
    </Row>
  </Container>
);

const ColStyled = styled(Col)`
  margin: ${spacing.stack.xl};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export default PageComponent;
