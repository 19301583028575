export default () => {
  const {
    country,
    environment,
    page,
    pageName,
    pageCategory,
    pageType,
    pageSubcategory,
    workEnv,
    brand,
  } = window.tc_vars;
  if (typeof window.tC.event.virtualPageview === "function") {
    window.tC.event.virtualPageview(this, {
      country,
      environment,
      page,
      page_category: pageCategory,
      page_type: pageType,
      page_name: pageName,
      page_subcategory: pageSubcategory,
      work_env: workEnv,
      brand,
    });
  }
};
