import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import Settings from "src/stores/Settings";

import { colors, spacing } from "src/styles/variables";
import { LabelL } from "src/atoms/Typography";

const ANIMATION_DURATION = 333;

const Dots = React.memo(
  ({ className, items, onSelect, large = false, showTitleOnHover = false }) => {
    const { productFindFlavorLabel } = useContext(Settings).translations;
    const defaultTitle =
      productFindFlavorLabel || "Find your favourite flavour";
    const defaultItem = { label: defaultTitle, color: colors.darkGrey };
    const [hoveredItem, setHoveredItem] = useState(defaultItem);

    const onMouseOver = (item) => {
      setHoveredItem(item);
    };

    const onMouseOut = () => {
      setHoveredItem(defaultItem);
    };
    return (
      <Outer className={className}>
        {showTitleOnHover && (
          <DotsTitle as="div" color={hoveredItem.color}>
            {hoveredItem.label}
          </DotsTitle>
        )}
        <Inner>
          {items.map((item, key) => (
            <ClickableDot
              key={key}
              onClick={(e) => {
                e.preventDefault();
                onSelect(item, key);
              }}
              href={item.slug}
            >
              <Dot
                active={(item || {}).active}
                color={(item || {}).color}
                large={large}
                onMouseOver={() => !item.active && onMouseOver(item)}
                onMouseOut={() => !item.active && onMouseOut()}
              >
                {item.label}
              </Dot>
            </ClickableDot>
          ))}
        </Inner>
      </Outer>
    );
  }
);

const isWhite = (color) => {
  if (!color) return false;
  const lowercaseColor = color.toLowerCase();
  return (
    lowercaseColor === "white" ||
    lowercaseColor === "#fff" ||
    lowercaseColor === "#ffffff" ||
    lowercaseColor === "rgb(255, 255, 255)"
  );
};

Dots.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      color: PropTypes.string,
      active: PropTypes.bool.isRequired,
    })
  ),
  onSelect: PropTypes.func,
  large: PropTypes.bool,
  showTitleOnHover: PropTypes.bool,
};

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DotsTitle = styled(LabelL)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  color: ${({ color }) => (isWhite(color) ? colors.darkGrey : color)};
  padding: ${spacing.stack.md};
`;

const Inner = styled.div`
  display: flex;
`;

const ClickableDot = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  width: 20px;
  height: 20px;
  cursor: pointer;
`;

const Dot = styled.div`
  display: block;
  width: ${({ active, large }) =>
    active ? (large ? "14px" : "10px") : large ? "8px" : "4px"};
  height: ${({ active, large }) =>
    active ? (large ? "14px" : "10px") : large ? "8px" : "4px"};
  background: ${({ color }) => color || colors.activiaGreen};
  border-radius: 100%;
  text-indent: -10000px;
  transition: width ${ANIMATION_DURATION}ms cubic-bezier(0.58, 0, 0.56, 1),
    height ${ANIMATION_DURATION}ms cubic-bezier(0.58, 0, 0.56, 1);

  ${({ color }) =>
    isWhite(color) &&
    css`
      border: 1px solid ${colors.darkGrey};
    `}
`;

export default Dots;
