import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-awesome-styled-grid";

import { spacing } from "src/styles/variables";
import QuestionAnswerList from "./QuestionAnswerList";

const PageComponent = (props) => (
  <Container>
    <RowStyled as="section">
      <Col md={10} offset={{ md: 3 }}>
        <QuestionAnswerList {...props} />
      </Col>
    </RowStyled>
  </Container>
);

const RowStyled = styled(Row)`
  margin: ${spacing.default.xl} 0;
`;

export default PageComponent;
