import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import Settings from "src/stores/Settings";
import { colors, outline, spacing, fontWeights } from "src/styles/variables";
import { LabelL } from "src/atoms/Typography";

const Accordion = React.memo(
  ({
    className,
    initial = false,
    hiddenLabel,
    visibleLabel,
    children,
    onChange = (v) => v,
    collapsibleStyle = "without separator",
    trackInteraction = undefined,
  }) => {
    const { useAccessibilityColor } = useContext(Settings);
    const [show, toggle] = useState(initial);
    // console.log("collpasible style in question: ", collapsibleStyle);
    useEffect(() => {
      toggle(initial);
    }, [initial]);

    return (
      <QuestionContainer
        className={className}
        collapsiblestyle={collapsibleStyle}
      >
        <Label
          as="button"
          onClick={() => {
            if (trackInteraction) {
              trackInteraction();
            }
            toggle(!show);
            onChange(!show);
          }}
          collapsiblestyle={collapsibleStyle}
          useAccessibilityColor={useAccessibilityColor}
        >
          {show ? visibleLabel : hiddenLabel}
        </Label>
        <Content open={!!show}>{children}</Content>
      </QuestionContainer>
    );
  }
);

Accordion.propTypes = {
  className: PropTypes.string,
  initial: PropTypes.bool,
  hiddenLabel: PropTypes.node.isRequired,
  visibleLabel: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func,
  collapsibleStyle: PropTypes.string,
  trackInteraction: PropTypes.func,
};

const QuestionContainer = styled.div`
  ${({ collapsiblestyle }) =>
    collapsiblestyle === "with separator"
      ? `
border-bottom:1px solid ${colors.lightGrey};
`
      : null}
`;

const Label = styled(LabelL)`
  cursor: pointer;
  user-select: none;
  color: ${({ collapsiblestyle, useAccessibilityColor }) =>
    collapsiblestyle === "with separator"
      ? colors.darkGrey
      : useAccessibilityColor
      ? colors.accessibilityGreen
      : colors.activiaGreen};
  font-weight: ${({ collapsiblestyle }) =>
    collapsiblestyle === "with separator" ? fontWeights.book : null};
  border-radius: 6px;
  outline: 0;
  text-align: left;

  ${({ collapsiblestyle }) =>
    collapsiblestyle === "with separator"
      ? `
      display: flex;
      width: 100%;
      padding: ${spacing.default.sm} 0;
      flex-direction: row-reverse;
      align-items: center;
      `
      : null}

      .question-title{
        ${({ collapsiblestyle }) =>
          collapsiblestyle === "with separator"
            ? `
              padding-right: ${spacing.default.sm};
              flex: 1;`
            : `padding-left:${spacing.default.xs};`}
      
  /* Removing because design asked for it */
  /*
  &:focus {
    outline: none;
    box-shadow: ${outline};
  }
  */
`;

const Content = styled.div`
  max-height: 0;
  transition: max-height 0.6s cubic-bezier(0, 1, 0, 1);
  overflow: hidden;

  ${({ open }) =>
    open &&
    css`
      max-height: 600rem;
      transition: max-height 0.6s cubic-bezier(0.895, 0.03, 0.685, 0.22);
    `};
`;

export default Accordion;
