import React, { useContext, useRef, useLayoutEffect } from "react";
import styled from "styled-components";

import Settings from "src/stores/Settings";
import Popup from "src/molecules/Popup";

const PopupsList = () => {
  const { locale, popups } = useContext(Settings);

  const languageSelectorRef = useRef(null);

  const { currentRegion: currentLanguage } = locale;

  useLayoutEffect(() => {
    // Ensuire language is properly set on initial load
    setTimeout(() => {
      if (languageSelectorRef.current) {
        languageSelectorRef.current.value = currentLanguage.pathPrefix;
      }
    }, 0);
  }, []);

  return (
    <PopupsContainer>
      {!!popups &&
        popups.map((popup, key) => {
          return (
            <PopupItem
              key={key}
              popupId={popup.popupId}
              popupContent={popup.popupContent}
              confirmationText={popup.confirmationText}
            />
          );
        })}
    </PopupsContainer>
  );
};
const PopupsContainer = styled.div``;

const PopupItem = styled(Popup)``;

export default PopupsList;
