import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { Body } from "src/atoms/Typography";

const Chip = React.memo(({ text, active, ...rest }) => (
  <Outer {...rest} active={active}>
    {text}
  </Outer>
));

const Outer = styled(Body)`
  display: inline-flex;
  background: ${({ active, theme }) =>
    active ? theme.chip.activeBg : theme.chip.bg};
  color: ${({ active, theme }) =>
    active ? theme.chip.activeColor : theme.chip.color};
  padding: 0 0.75em;
  border-radius: 10px;
  border: 2px solid
    ${({ active, theme }) =>
      active ? theme.chip.activeBorder : theme.chip.border};
  height: 30px;
  white-space: nowrap;
  align-items: center;
`;

Chip.propTypes = {
  active: PropTypes.bool.isRequired,
  text: PropTypes.node.isRequired,
};

export default Chip;
