import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { BLOCKS, INLINES, MARKS } from "@contentful/rich-text-types";

import {
  HeaderM,
  SubHeaderM,
  IntroText,
  Body,
  LabelXS,
} from "src/atoms/Typography";
import { mediaquery, colors, spacing } from "src/styles/variables";
import Link from "src/atoms/Link";

const Highlight = styled.span`
  font-weight: bold;

  .italics {
    font-weight: normal;
    font-style: normal;
    font-size: 0.7rem;
    vertical-align: super;
  }
`;

const Underline = styled.span`
  text-decoration: underline;
`;
const Italic = styled.span`
  font-style: italic;

  .bold {
    font-weight: normal;
    font-style: normal;
    font-size: 0.7rem;
    vertical-align: super;
  }
`;

const StyledBody = styled(Body)`
  color: ${({ bodyColor }) => bodyColor};
  margin: ${spacing.stack.md};
  &:empty {
    /* contentful puts an empty P at the end of a rich text blob if the last thing is a header */
    display: none;
  }
`;

const Heading1 = styled(HeaderM)`
  color: ${({ headColor }) => headColor};
  margin: ${spacing.stack.md};

  ${Highlight} {
    font-weight: inherit;
    color: ${colors.activiaGreen};
  }
`;

const Heading2 = styled(SubHeaderM)`
  color: ${({ headColor }) => headColor};
  margin: ${spacing.stack.md};

  ${Highlight} {
    font-weight: inherit;
    color: ${colors.activiaGreen};
  }
`;

const Heading3 = styled(IntroText)`
  color: ${({ headColor }) => headColor};
  margin: ${spacing.stack.md};

  ${Highlight} {
    font-weight: inherit;
    color: ${colors.activiaGreen};
  }
`;

const StyledUl = styled.ul`
  list-style: initial;
  padding: initial;
  padding-left: ${spacing.default.md};
  color: ${({ bodyColor }) => bodyColor};

  p {
    margin: 0;
  }
`;

const LinkOuter = styled.span`
  a {
    color: ${colors.activiaGreen} !important;
  }
`;

const LegalMention = styled(LabelXS)`
  color: ${({ bodyColor }) => bodyColor};
  font-style: italic;

  p,
  a {
    font-size: inherit;
    line-height: inherit;
  }
`;

const StyledTable = styled.table`
  box-shadow: #aec1cc 0px 0px 0px 1px;
  border-collapse: collapse;
  border-radius: 5px;
  border-style: hidden;
  width: 100%;
  table-layout: fixed;
  overflow: hidden;
  margin: ${spacing.stack.md};

  ${mediaquery.md(css`
    table-layout: initial;
  `)}

  tr,th,
  td {
    border: 1px solid #aec1cc;
    border-collapse: collapse;
  }

  th,
  td {
    padding: 10px 12px;
    min-width: 48px;
    position: relative;
    vertical-align: top;
  }

  th,
  td {
    p {
      margin-top: ${spacing.default.md};

      &:first-child {
        margin: 0;
      }
    }
  }
`;

// https://www.contentful.com/developers/docs/javascript/tutorials/rendering-contentful-rich-text-with-javascript/
const RichText = React.memo(
  ({
    doc,
    enableRenderer = false,
    headColor = colors.darkGreen,
    bodyColor = colors.darkGreen,
    renderNode = undefined,
    renderMark = undefined,
    renderText = undefined,
    alignment,
    ...rest
  }) => {
    const docOptions = {
      renderMark: renderMark || {
        [MARKS.BOLD]: (text) => <Highlight className="bold">{text}</Highlight>,
        [MARKS.UNDERLINE]: (text) => <Underline>{text}</Underline>,
        [MARKS.ITALIC]: (text) => <Italic className="italics">{text}</Italic>,
      },
      renderNode: renderNode || {
        [BLOCKS.PARAGRAPH]: (node, children) => (
          <StyledBody as="p" bodyColor={bodyColor}>
            {children}
          </StyledBody>
        ),
        [BLOCKS.HEADING_1]: (node, children) => (
          <Heading1 as="h2" headColor={headColor}>
            {children}
          </Heading1>
        ),
        [BLOCKS.HEADING_2]: (node, children) => (
          <Heading2 as="h3" headColor={headColor}>
            {children}
          </Heading2>
        ),
        [BLOCKS.HEADING_3]: (node, children) => (
          <Heading3 as="h4" headColor={headColor}>
            {children}
          </Heading3>
        ),
        [BLOCKS.UL_LIST]: (node, children) => (
          <StyledUl bodyColor={bodyColor}>{children}</StyledUl>
        ),
        [INLINES.HYPERLINK]: (node, children) => (
          <LinkOuter>
            <Link
              title={children
                .flatMap((v) => v)
                .filter((v) => v)
                .join()}
              url={node.data.uri}
            >
              {children}
            </Link>
          </LinkOuter>
        ),
        [BLOCKS.QUOTE]: (node, children) => (
          <LegalMention bodyColor={bodyColor}>{children}</LegalMention>
        ),
        [BLOCKS.TABLE]: (node, children) => (
          <StyledTable bodyColor={bodyColor}>{children}</StyledTable>
        ),
        [BLOCKS.TABLE_ROW]: (node, children) => {
          if (
            children.every((node) => node.nodeType === BLOCKS.TABLE_HEADER_CELL)
          ) {
            // all children are header cells, so we should wrap the row
            // with a <thead /> tag
            return (
              <thead>
                <tr>{children}</tr>
              </thead>
            );
          } else {
            // not a header row, so we can render an ordinary <tr />
            return (
              <tbody>
                <tr>{children}</tr>
              </tbody>
            );
          }
        },
        [BLOCKS.TABLE_HEADER_CELL]: (node, children) => <th>{children}</th>,
        [BLOCKS.TABLE_CELL]: (node, children) => <td>{children}</td>,
      },
      renderText:
        renderText ||
        ((text) => {
          return text.split("\n").reduce((children, textSegment, index) => {
            return [...children, index > 0 && <br key={index} />, textSegment];
          }, []);
        }),
    };

    let options = {};

    if (!!enableRenderer || !!renderNode || !!renderMark || !!renderText) {
      options = docOptions;
    }
    return (
      <TextWrapper align={alignment || "left"} {...rest}>
        {renderRichText(doc, options)}
      </TextWrapper>
    );
  }
);

const TextWrapper = styled.div`
  text-align: ${({ align }) => align || null};
`;

RichText.propTypes = {
  doc: PropTypes.object.isRequired,
  enableRenderer: PropTypes.bool,
  headColor: PropTypes.string,
  bodyColor: PropTypes.string,
  renderNode: PropTypes.object,
  renderMark: PropTypes.object,
  renderText: PropTypes.func,
  alignment: PropTypes.string,
};

export default RichText;
