import React from "react";

import SVG from "../SVG";

export default SVG(() => (
  <svg viewBox="0 0 24 24" fill="none">
    <g transform="translate(-3 0)">
      <path
        d="M12.036 14.882c1.11 0 2.01-.885 2.01-1.976 0-1.092-.9-1.977-2.01-1.977-1.11 0-2.009.885-2.009 1.977 0 1.091.9 1.976 2.01 1.976z"
        fill="#02BA44"
      />
      <path
        d="M15.587 16.4a4.885 4.885 0 0 0 0-6.988c-1.962-1.93-5.142-1.93-7.103 0a4.885 4.885 0 0 0 0 6.988m9.234 2.096a7.816 7.816 0 0 0 0-11.18c-3.138-3.088-8.226-3.088-11.364 0a7.816 7.816 0 0 0 0 11.18"
        stroke="#02BA44"
        strokeLinecap="round"
      />
    </g>
  </svg>
));
