import React from "react";
import styled from "styled-components";

import { spacing } from "src/styles/variables";

import ProductPush from "./ProductPush";

const PageComponent = (props) => <ProductPushStyled {...props} />;

const ProductPushStyled = styled(ProductPush)`
  margin: ${spacing.stack.xl};
`;

export default PageComponent;
