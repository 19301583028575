import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Settings from "src/stores/Settings";
import { colors } from "src/styles/variables";
import { LabelXS, SubHeaderS } from "src/atoms/Typography";
import { DIMENSIONS, ICONS } from "./constants";
import FactIcon from "src/atoms/Vectors/Card/Fact";
import PlayIcon from "src/atoms/Vectors/Card/Play";
import PodcastIcon from "src/atoms/Vectors/Card/Podcast";
import RecipeIcon from "src/atoms/Vectors/Card/Recipe";
import ReadIcon from "src/atoms/Vectors/Card/Read";

const IconMap = {
  [ICONS.article]: ReadIcon,
  [ICONS.video]: PlayIcon,
  [ICONS.podcast]: PodcastIcon,
  [ICONS.recipe]: RecipeIcon,
  [ICONS.fact]: FactIcon, // not used here, see CardFact
};

const CardCopy = React.memo(
  ({
    cardHighlighted,
    whiteText,
    title = "",
    category = "",
    icon,
    green = "false",
  }) => {
    const { useAccessibilityColor } = useContext(Settings);
    const color = whiteText ? colors.white : undefined;
    const Icon = IconMap[icon];
    const resolvedColor = color
      ? color
      : useAccessibilityColor
      ? colors.accessibilityGreen
      : colors.activiaGreen;
    return (
      <Outer cardHighlighted={cardHighlighted}>
        <Inner cardHighlighted={cardHighlighted}>
          <Category color={color} resolvedColor={resolvedColor} green={green}>
            {!!Icon && <Icon stroke={resolvedColor} size="2em" />}
            <span>{category}</span>
          </Category>
          <Title as="h2" color={color} green={green}>
            {title}
          </Title>
        </Inner>
      </Outer>
    );
  }
);

CardCopy.propTypes = {
  cardHighlighted: PropTypes.bool,
  whiteText: PropTypes.bool,
  title: PropTypes.string,
  category: PropTypes.string,
  icon: PropTypes.oneOf(Object.values(ICONS)),
  green: PropTypes.bool,
};

const Outer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: ${({ cardHighlighted }) =>
    cardHighlighted ? "row" : "column"};
  justify-content: ${({ cardHighlighted }) =>
    cardHighlighted ? "flex-start" : "flex-end"};
`;

const Inner = styled.div`
  width: ${({ cardHighlighted }) =>
    cardHighlighted ? `${DIMENSIONS.doubleTextWidth}px` : "100%"};
  height: ${({ cardHighlighted }) =>
    cardHighlighted ? "100%" : `${DIMENSIONS.singleTextHeight + 4}px`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
`;

const Category = styled(LabelXS)`
  display: flex;
  align-items: center;
  color: ${({ resolvedColor }) => resolvedColor};
  margin-bottom: 4px;
  text-shadow: ${({ color, green }) =>
    color && !green && `1px 1px 2px ${colors.mediumShadow}`};
`;

const Title = styled(SubHeaderS)`
  color: ${({ color }) => color || colors.black};
  text-shadow: ${({ color, green }) =>
    color && !green && `1px 1px 2px ${colors.mediumShadow}`};
`;

export default CardCopy;
