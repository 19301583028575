import React from "react";
import styled from "styled-components";

import { spacing } from "src/styles/variables";

import Spacer from "src/organisms/PageComponentList/Spacer";
import RecipeDetails from "./RecipeDetails";

const RecipeDetailsStyled = styled(RecipeDetails)`
  margin: ${spacing.stack.xl};
`;

const PageComponent = (props) => (
  <>
    <Spacer />
    <RecipeDetailsStyled {...props} />
    <Spacer />
  </>
);

export default PageComponent;
