import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import { mediaquery, paddingWidth } from "src/styles/variables";

const GridPadding = ({ children, className }) => (
  <Container className={className}>{children}</Container>
);

GridPadding.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

const Container = styled.div`
  padding: 0 ${paddingWidth.xs}rem;

  ${mediaquery.sm(css`
    padding: 0 ${paddingWidth.sm}rem;
  `)}
  ${mediaquery.md(css`
    padding: 0 ${paddingWidth.md}rem;
  `)}
  ${mediaquery.lg(css`
    padding: 0 ${paddingWidth.lg}rem;
  `)}
  ${mediaquery.xl(css`
    padding: 0 ${paddingWidth.xl}rem;
  `)}
`;

export default GridPadding;
