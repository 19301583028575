import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { colors, spacing } from "src/styles/variables";
import { HeaderM } from "src/atoms/Typography";
import ChipSelector from "src/molecules/ChipSelector";
import QuestionAnswer from "src/molecules/QuestionAnswer";
// import trackEvent from "./trackEvent";

const FilteredList = ({ title, items }) => {
  const [selectedFilters, setSelectedFilters] = useState([]);

  const filters =
    items.length &&
    Object.values(
      items.reduce((acc, item) => {
        if (!item.topics) {
          return acc;
        }
        return {
          ...acc,
          ...item.topics.reduce(
            (acc_, topic) => ({
              ...acc_,
              [topic.id]: { key: topic.id, label: topic.name },
            }),
            {}
          ),
        };
      }, {})
    );

  const onChange = (newFilters, key) => {
    // const topicName = filters.find((filter) => filter.key === key).label;
    // trackEvent(topicName);
    setSelectedFilters(newFilters);
  };

  return (
    <div>
      <Title as="h2">{title}</Title>
      {filters && <ChipSelectorStyled items={filters} onChange={onChange} />}
      {items
        .filter(
          ({ topics }) =>
            selectedFilters.length < 1 ||
            (topics &&
              topics.find((topic) => selectedFilters.includes(topic.id)))
        )
        .map(({ id, question, answer }) => (
          <QuestionAnswer key={id} question={question} answer={answer} />
        ))}
    </div>
  );
};

FilteredList.propTypes = {
  collapsible: PropTypes.bool,
  title: PropTypes.node.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      question: PropTypes.string.isRequired,
      answer: PropTypes.object.isRequired,
      topics: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        })
      ).isRequired,
    })
  ),
};

const Title = styled(HeaderM)`
  color: ${colors.mediumGreen};
  margin: ${spacing.stack.sm};
`;

const ChipSelectorStyled = styled(ChipSelector)`
  margin: ${spacing.stack.sm};
`;

export default FilteredList;
