import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import Settings from "src/stores/Settings";

import {
  spacing,
  mediaquery,
  colors,
  rem,
  lineHeight,
  fontWeights,
} from "src/styles/variables";
import { LabelL, LabelS } from "src/atoms/Typography";

const NumericFact = React.memo(
  ({
    label,
    value,
    align,
    isEnergyNutritionalFact,
    secondaryValue,
    colorCode,
    percentage,
    ...rest
  }) => {
    const { useTrafficLightNutritionFacts } = useContext(Settings);
    const translations = useContext(Settings).translations;

    return (
      <Outer
        align={align}
        useTrafficLight={useTrafficLightNutritionFacts}
        isEnergy={isEnergyNutritionalFact}
        colorCode={colorCode}
      >
        <Circle
          useTrafficLight={useTrafficLightNutritionFacts}
          isEnergy={isEnergyNutritionalFact}
        >
          {value}
        </Circle>
        <LabelStyled useTrafficLight={useTrafficLightNutritionFacts}>
          {label}
        </LabelStyled>
        {!!useTrafficLightNutritionFacts &&
          !!secondaryValue &&
          !!isEnergyNutritionalFact && (
            <SecondaryLabelStyled>{secondaryValue}</SecondaryLabelStyled>
          )}
        {!!useTrafficLightNutritionFacts && (!!colorCode || !!percentage) && (
          <ColorPerWrapper>
            {!!colorCode && (
              <ColorCode>
                {translations[`NutritionalFact${colorCode}`]}
              </ColorCode>
            )}
            {!!percentage && <Percentage>{percentage}</Percentage>}
          </ColorPerWrapper>
        )}
      </Outer>
    );
  }
);

NumericFact.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  align: PropTypes.string,
  isEnergyNutritionalFact: PropTypes.bool,
  secondaryValue: PropTypes.string,
  colorCode: PropTypes.string,
  percentage: PropTypes.string,
};

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 20%;
  margin: ${({ theme }) => theme.numericFact.margin};
  ${({ align, useTrafficLight }) =>
    align === "left" &&
    !useTrafficLight &&
    css`
      margin: 0 ${spacing.default.xs} 15px;
    `}
  max-width: 20%;

  ${mediaquery.lg(css`
    max-width: 25%;
  `)}

  ${mediaquery.xl(css`
    max-width: 20%;
  `)}

   ${({ useTrafficLight, isEnergy, colorCode }) =>
    useTrafficLight &&
    css`
      border: 1px solid ${colors.black};
      border-radius: 30px;
      background-color: ${isEnergy
        ? colors.white
        : (colorCode && colors[`trafficLight${colorCode}`]) ||
          colors.lightGray};
      align-items: center;
      text-align: center;
      padding: ${spacing.default.sm} ${spacing.default.xs};
      margin: 0;
      flex: 1;

      ${mediaquery.md(css`
        flex: 0 1 auto;
      `)}

      ${mediaquery.lg(css`
        border-radius: 2.3vw;
      `)}

      * {
        order: 2;
      }
    `}
`;

const Circle = styled(LabelL)`
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  white-space: normal;

  ${({ useTrafficLight, isEnergy }) =>
    useTrafficLight
      ? css`
          order: 1;
          font-size: ${isEnergy ? rem(12) : rem(16)};
          line-height: ${isEnergy ? lineHeight(12, 12) : lineHeight(16, 16)};
          font-weight: ${fontWeights.bold};
          display: block;
          margin-bottom: ${isEnergy ? "2px" : "5px"};
        `
      : css`
          border: 2px solid
            ${({ theme }) => theme.numericFact.circleBorderColor};
          border-radius: 100%;
          width: 70px;
          height: 70px;
          margin: 0;
          color: ${({ theme }) => theme.numericFact.circleTextColor};
          padding: 3px;
        `}
`;

const LabelStyled = styled(LabelS)`
  ${({ useTrafficLight }) =>
    useTrafficLight
      ? css`
          order: 0;
          font-size: ${rem(12)};
          line-height: ${lineHeight(12, 12)};
          font-weight: ${fontWeights.medium};
          margin-bottom: 5px;
        `
      : css`
          margin-top: 5px;
          color: ${({ theme }) => theme.numericFact.labelColor};
          text-align: center;
        `}
`;

const SecondaryLabelStyled = styled(LabelS)`
  font-size: ${rem(12)};
  line-height: ${lineHeight(12, 12)};
  font-weight: ${fontWeights.medium};
  display: block;
  margin-bottom: 5px;
`;

const ColorPerWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const ColorCode = styled(LabelS)`
  font-size: ${rem(12)};
  line-height: ${lineHeight(12, 12)};
  font-weight: ${fontWeights.regular};
  background: ${colors.white};
  border: 1px solid ${colors.black};
  border-radius: 50px;
  padding: 2px ${spacing.default.xs} 1px;
  white-space: nowrap;
  margin-bottom: 5px;
`;

const Percentage = styled(LabelS)`
  font-size: ${rem(12)};
  line-height: ${lineHeight(12, 12)};
  font-weight: ${fontWeights.bold};
`;

export default NumericFact;
