import React, { useEffect, useRef, useState, useContext } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Link } from "gatsby";
import { useInView } from "react-intersection-observer";
import { Container, Row, Col } from "react-awesome-styled-grid";
import { BLOCKS, MARKS } from "@contentful/rich-text-types";

import Settings from "src/stores/Settings";
import RichText from "src/atoms/RichText";
import Picture, { TYPES } from "src/atoms/Picture";
import { SubHeaderM } from "src/atoms/Typography";

import { getSlugForFlavor } from "src/utils/slugger";
import getLocalizedSlug from "src/utils/getLocalizedSlug";
import debounce from "src/utils/debounce";
import { mediaquery, colors, spacing } from "src/styles/variables";
import trackEvent from "./trackEvent";

const renderMark = {
  [MARKS.BOLD]: (text) => (
    <span style={{ color: colors.activiaGreen }}>{text}</span>
  ),
};
const renderNode = {
  [BLOCKS.PARAGRAPH]: (node, children) => (
    <Paragraph as="p">{children}</Paragraph>
  ),
};

const ProductPush = ({ title, description, image, flavor, ...rest }) => {
  const { locale } = useContext(Settings);

  const pictureRef = useRef(null);
  const animationRef = useRef(null);

  const [pictureCenter, setPictureCenter] = useState(0);
  const [pathLength, setPathLength] = useState(0);
  const [windowWidth, setWindowWidth] = useState(375);

  const [ref, inView] = useInView({ triggerOnce: true, threshold: 0.5 });
  const slug = getSlugForFlavor(flavor);
  const { name } = flavor;

  useEffect(() => {
    const onResize = () => {
      if (!pictureRef.current) return;
      animationRef.current.style.left = `-${pictureRef.current.offsetLeft}px`;
      setPictureCenter(
        pictureRef.current.offsetLeft + pictureRef.current.offsetWidth / 2
      );
      setWindowWidth(window.innerWidth);
      setTimeout(() => {
        setPathLength(
          animationRef.current.querySelector("path").getTotalLength()
        );
      });
    };

    const onResizeDebounced = debounce(onResize, 250);
    window.addEventListener("resize", onResizeDebounced, false);
    onResize();

    return () => window.removeEventListener("resize", onResizeDebounced, false);
  }, []);

  return (
    <Container {...rest}>
      <Row>
        <Col md={11} lg={22} offset={{ md: 5, lg: 10 }}>
          <PushContainer
            ref={ref}
            to={getLocalizedSlug(slug, locale)}
            onClick={() => {
              trackEvent(title, name);
            }}
          >
            <TextContainer>
              <RichText
                doc={description}
                renderMark={renderMark}
                renderNode={renderNode}
              />
            </TextContainer>
            <PictureContainer ref={pictureRef}>
              <Animation
                playAnimation={inView}
                ref={animationRef}
                pathLength={pathLength}
              >
                <path
                  fill="none"
                  stroke={colors.activiaGreen}
                  strokeWidth="4"
                  d={`M 0 0
                      Q 50 250 ${pictureCenter} 250
                      C ${pictureCenter + 150} 250 ${
                    pictureCenter + 150
                  } 150 ${pictureCenter} 150
                      C ${pictureCenter - 150} 150 ${pictureCenter - 150} 300 ${
                    pictureCenter + 150
                  } 300
                      C ${pictureCenter + 300} 300 ${
                    pictureCenter + 350
                  } 200 ${windowWidth} 200`}
                />
              </Animation>
              <Picture type={TYPES.fluid} small={image} />
            </PictureContainer>
          </PushContainer>
        </Col>
      </Row>
    </Container>
  );
};

ProductPush.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.object.isRequired,
  image: PropTypes.object.isRequired,
  flavor: PropTypes.object.isRequired,
};

const PushContainer = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;

  ${mediaquery.md(css`
    flex-direction: row;
  `)}
`;

const PictureContainer = styled.div`
  position: relative;
  width: 200px;
  max-width: 200px;
  flex: 1;
`;

const Animation = styled.svg`
  position: absolute;
  width: 100vw;
  height: 310px;
  bottom: -90px;
  left: 0;
  pointer-events: none;

  path {
    stroke-dasharray: ${({ pathLength }) => `${pathLength / 2} ${pathLength}`};
    stroke-dashoffset: ${({ pathLength }) => pathLength * 2};
    opacity: 0;

    ${({ playAnimation }) =>
      playAnimation &&
      css`
        animation: dash 3s ease-in;
      `};
  }

  @keyframes dash {
    2% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      stroke-dashoffset: ${({ pathLength }) => pathLength / 2};
    }
  }
`;

const TextContainer = styled.div`
  margin-bottom: ${spacing.default.xl};
  flex: 0;

  ${mediaquery.md(css`
    order: 1;
    margin-left: ${spacing.default.xl};
    margin-bottom: 0;
  `)}
`;

const Paragraph = styled(SubHeaderM)`
  color: ${colors.mediumGreen};
`;

export default ProductPush;
