import React from "react";

import SVG from "../SVG";

export default SVG(() => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
    <path
      fill="#02BA44"
      d="M6.57621 15.4477v2.916c0 .902.715 1.6363 1.59335 1.6363h6.66114c.8784 0 1.5931-.7343 1.5931-1.6363v-2.916C17.775 14.8054 19 13.3315 19 11.3169c0-1.79966-1.1211-3.85139-3.3724-4.41822C15.2056 5.78893 13.9107 4 11.5 4 9.58117 4 8.00223 5.24319 7.37212 6.89868 5.49651 7.37077 4 9.11501 4 11.3169c.00061 1.9142 1.12751 3.4419 2.57621 4.1308zm1.48793-7.73828c.36868-1.5982 1.76712-2.78726 3.43616-2.78726 1.6691 0 3.0672 1.18906 3.4362 2.78726 1.7784.1879 3.1658 1.73103 3.1658 3.60748 0 1.6627-1.0901 3.0639-2.5762 3.4919v3.5549c0 .3944-.3113.7145-.6954.7145h-1.2323v-4.2741c-.3561-.1045-.6889-.2653-.9896-.4718v4.7455h-2.2173v-4.7455c-.3004.2065-.63349.3673-.98961.4718v4.2737H8.16956c-.38401 0-.69569-.32-.69569-.7144v-3.5549c-1.48609-.4277-2.5759-1.8292-2.5759-3.4919.0003-1.87615 1.3877-3.4196 3.16617-3.60718z"
    />
  </svg>
));
