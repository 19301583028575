import React from "react";

import SVG from "../SVG";

export default SVG(() => (
  <svg viewBox="0 0 16 16" fill="none">
    <rect x="3" y="2" width="4" height="12" rx="1" fill="#02BA44" />
    <rect x="10" y="2" width="4" height="12" rx="1" fill="#02BA44" />
  </svg>
));
