import React, { createRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import lottie from "lottie-web";

const Lottie = (data) => {
  const Animation = ({
    loop = false,
    play = true,
    position = 0,
    direction = 1,
    onComplete = (v) => v,
    onLoopComplete = (v) => v,
    onEnterFrame = (v) => v,
    onSegmentStart = (v) => v,
    onConfigReady = (v) => v,
    onDataReady = (v) => v,
    onDataFailed = (v) => v,
    onLoadedImages = (v) => v,
    onDOMLoaded = (v) => v,
    ...rest
  }) => {
    const [animation, setAnimation] = useState();
    const el = createRef();

    useEffect(() => {
      if (!animation) {
        const thisAnimation = lottie.loadAnimation({
          container: el.current,
          renderer: "svg",
          loop: loop,
          autoplay: false,
          animationData: data,
        });
        Object.entries({
          complete: onComplete,
          loopComplete: onLoopComplete,
          enterFrame: onEnterFrame,
          segmentStart: onSegmentStart,
          config_ready: onConfigReady,
          data_ready: onDataReady,
          data_failed: onDataFailed,
          loaded_images: onLoadedImages,
          DOMLoaded: onDOMLoaded,
        }).forEach(([name, fn]) => {
          thisAnimation.addEventListener(name, fn);
        });

        setAnimation(thisAnimation);
        return;
      }

      animation.setDirection(direction);
      const totalFrames = animation.getDuration(true);
      const startFrame = totalFrames * position;
      animation[play ? "goToAndPlay" : "goToAndStop"](startFrame, true);
    }, [undefined, animation, loop, play, position, direction]);

    useEffect(() => {
      if (animation) {
        animation.destroy();
      }
    }, [data]);

    return <div {...rest} ref={el} />;
  };

  Animation.propTypes = {
    loop: PropTypes.bool,
    play: PropTypes.bool,
    position: PropTypes.number,
    direction: PropTypes.oneOf([1, -1]),

    // will only bind the first function passed, don't change function reference once rendered!
    onComplete: PropTypes.func,
    onLoopComplete: PropTypes.func,
    onEnterFrame: PropTypes.func,
    onSegmentStart: PropTypes.func,
    onConfigReady: PropTypes.func,
    onDataReady: PropTypes.func,
    onDataFailed: PropTypes.func,
    onLoadedImages: PropTypes.func,
    onDOMLoaded: PropTypes.func,
  };

  return Animation;
};

export default Lottie;
