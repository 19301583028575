import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Picture, { FITS, TYPES } from "src/atoms/Picture";
import { colors, spacing } from "src/styles/variables";
import { SubHeaderM } from "src/atoms/Typography";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Title = styled(SubHeaderM)`
  color: ${colors.activiaGreen};
  margin: ${spacing.stack.xl};
  width: 230px;
  text-align: center;
`;

const PictureContainer = styled.div`
  width: 330px;
  margin: ${spacing.stack.md};
`;

const LinkWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 400px;
  margin: ${spacing.stack.xl};
`;

const Link = styled.a`
  width: 112px;
  height: 112px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  box-shadow: 0 6px 10px 2px ${colors.translucidBlack};
  margin: ${spacing.default.sm} ${spacing.default.xs};
  padding: ${spacing.default.sm};
`;

const Logo = styled(Picture)`
  width: 90%;
`;

const RetailerPush = ({ title, image, retailers }) => {
  return (
    <Wrapper>
      {image && (
        <PictureContainer>
          <Picture fit={FITS.contain} small={image} />
        </PictureContainer>
      )}
      <Title>{title}</Title>
      <LinkWrapper>
        {retailers.map(({ name, url, logo }) => (
          <Link href={url} target="_blank" key={name}>
            <Logo type={TYPES.fluid} fit={FITS.none} small={logo} alt={name} />
          </Link>
        ))}
      </LinkWrapper>
    </Wrapper>
  );
};

RetailerPush.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.object,
  retailers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      logo: PropTypes.object.isRequired,
    })
  ),
};

export default RetailerPush;
