import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled, { css, keyframes } from "styled-components";
import { Transition } from "react-transition-group";
import { Container, Row, Col, Visible } from "react-awesome-styled-grid";
import { useSwipeable } from "react-swipeable";

import {
  colors,
  icons,
  mediaquery,
  paddingWidth,
  spacing,
} from "src/styles/variables";
import debounce from "src/utils/debounce";
import { HeaderXS, Body } from "src/atoms/Typography";
import Picture, { FITS } from "src/atoms/Picture";
import Video from "src/molecules/VideoPush";
import RichText from "src/atoms/RichText";
import FloatingButton from "src/molecules/FloatingButton";
import ArrowLeft from "src/atoms/Vectors/Arrows/ArrowLeft";
import ArrowRight from "src/atoms/Vectors/Arrows/ArrowRight";
import Dots from "src/atoms/Dots";
// import { trackNavigation, trackVideo } from "./trackEvents";

const ANIMATION_DURATION = 580;

const FeaturedPostCarousel = ({ items, ...rest }) => {
  const outer = React.createRef();
  const currentOuter = React.createRef();
  const [currentKey, setCurrentKey] = useState(0);
  const [[showLeftArrow, showRightArrow], setShowArrows] = useState([
    false,
    items.length > 1,
  ]);
  const [lastSlide, setLastSlide] = useState(undefined);
  const [nextSize, setNextSize] = useState(0);
  const nextKey = currentKey + 1 >= items.length ? null : currentKey + 1;
  const swipeHandlers = useSwipeable({
    onSwipedLeft: (e) => {
      slide(+1);
    },
    onSwipedRight: (e) => {
      slide(-1);
    },
  });

  const slideTo = (toKeyDirty) => {
    // trackNavigation();
    const toKey = Math.min(Math.max(0, toKeyDirty), items.length - 1);
    const direction = toKey > currentKey ? "right" : "left";
    setLastSlide(direction);
    setTimeout(() => {
      // setTimeout: ensure that the last component state that Transition sees is one which will animate *out*
      setCurrentKey(toKey);
      setShowArrows([toKey > 0, toKey < items.length - 1]);
    });
  };

  const slide = (direction) => {
    const toKey = currentKey + direction;
    slideTo(toKey);
  };

  const onResize = () => {
    currentOuter.current &&
      setNextSize(Math.round(currentOuter.current.clientWidth * 0.65));
  };

  const onKeypress = (e) => {
    const direction = {
      ArrowRight: +1,
      ArrowLeft: -1,
    }[e.code];
    direction && slide(direction);
  };

  useEffect(() => {
    onResize();

    const debouncedOnResize = debounce(onResize, 250);
    window.addEventListener("resize", debouncedOnResize, false);

    const el = outer.current;
    el.addEventListener("keyup", onKeypress);

    return () => {
      window.removeEventListener("resize", debouncedOnResize, false);
      el.removeEventListener("keyup", onKeypress);
    };
  });

  return (
    <Outer {...rest} ref={outer} tabIndex={0}>
      <Container {...swipeHandlers}>
        <Row>
          <Col xs={8} md={7}>
            <CurrentOuter ref={currentOuter}>
              <CurrentInner videos={items[currentKey].isVideo}>
                {items.map(({ picture, video, isVideo }, key) => (
                  <Transition
                    key={key}
                    in={key === currentKey}
                    timeout={ANIMATION_DURATION}
                  >
                    {(state) => (
                      <PictureAnimation state={state} sliding={lastSlide}>
                        {isVideo ? (
                          state !== "exited" ? (
                            <CarouselVideoOuter>
                              <Video
                                forcePortrait
                                squareCorners
                                {...video}
                                // trackInteraction={trackVideo}
                              />
                            </CarouselVideoOuter>
                          ) : (
                            <>
                              {/* stop videos from playing when not visible */}
                            </>
                          )
                        ) : (
                          <CarouselPicture
                            picture={picture || video.videoCover}
                            style={{ position: "absolute" }}
                          />
                        )}
                      </PictureAnimation>
                    )}
                  </Transition>
                ))}
              </CurrentInner>

              <Visible md lg xl>
                {!!showLeftArrow && (
                  <LeftContainer>
                    <FloatingButton>
                      <ArrowLeft size={icons.m} onClick={() => slide(-1)} />
                    </FloatingButton>
                  </LeftContainer>
                )}
              </Visible>
            </CurrentOuter>
          </Col>
          <CopyCol as={Col} xs={8} md={5} offset={{ md: 1 }}>
            <CopyInner>
              {items.map(({ title, subtitle }, key) => (
                <Transition
                  key={key}
                  in={key === currentKey}
                  timeout={ANIMATION_DURATION}
                >
                  {(state) => (
                    <TextAnimation state={state}>
                      <Title as="h4">{title}</Title>
                      <Subtitle as="h5">
                        <RichText doc={subtitle} />
                      </Subtitle>
                    </TextAnimation>
                  )}
                </Transition>
              ))}
            </CopyInner>
            {items.length > 1 && (
              <DotsStyled
                items={items.map((_, key) => ({
                  label: `${key}`,
                  active: key === currentKey,
                }))}
                onSelect={(_, key) => slideTo(key)}
              />
            )}
          </CopyCol>
          <NextCol as={Col} xs={0} md={1} offset={{ md: 1 }}>
            {!!nextSize && (
              <NextInner size={nextSize}>
                <Visible md lg xl>
                  {!!showRightArrow && (
                    <RightContainer>
                      <FloatingButton>
                        <ArrowRight size={icons.m} onClick={() => slide(+1)} />
                      </FloatingButton>
                    </RightContainer>
                  )}
                </Visible>
                {items.map(({ picture, video, isVideo }, key) => (
                  <Transition
                    key={key}
                    in={key === nextKey}
                    timeout={ANIMATION_DURATION}
                  >
                    {(state) => (
                      <>
                        <PictureAnimation state={state} sliding={lastSlide}>
                          <CarouselPicture
                            picture={picture || video.videoCover}
                          />
                        </PictureAnimation>
                      </>
                    )}
                  </Transition>
                ))}
              </NextInner>
            )}
          </NextCol>
        </Row>
      </Container>
    </Outer>
  );
};

FeaturedPostCarousel.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      isVideo: PropTypes.bool.isRequired,
      title: PropTypes.string.isRequired,
      subtitle: PropTypes.object,
      picture: PropTypes.object,
      video: PropTypes.shape(Video.propTypes),
    })
  ).isRequired,
};

const Outer = styled.div`
  overflow: hidden;
  margin-bottom: ${spacing.default.lg};
`;
const CurrentOuter = styled.div`
  position: relative;
  margin-left: -${paddingWidth.md}rem;
  margin-right: -${paddingWidth.md}rem;
  margin-bottom: ${spacing.default.md};

  ${mediaquery.md(css`
    margin-left: -${paddingWidth.md}rem;
    margin-right: 0;
    margin-bottom: 0;
  `)}
  ${mediaquery.lg(css`
    margin-left: -${paddingWidth.lg}rem;
    margin-right: 0;
  `)}
  ${mediaquery.xl(css`
    margin-left: -${paddingWidth.xl}rem;
    margin-right: 0;
  `)}
`;
const CurrentInner = styled.div`
  position: relative;
  padding-bottom: ${({ videos }) => (videos ? `${(16 / 9) * 100}%` : "100%")};

  ${mediaquery.md(css`
    padding-bottom: 100%;
  `)}
`;
const generatePictureAnimation = (exiting, right) => {
  return keyframes`
    0% {
      opacity: ${exiting ? 1 : 0};
      transform: translateX(${exiting ? 0 : right ? 20 : -20}%);
    }
    100% {
      opacity: ${exiting ? 0 : 1};
      transform: translateX(${exiting ? (right ? 20 : -20) : 0}%);
    }
  `;
};
const pictureAnimationMap = {
  enteringright: generatePictureAnimation(false, true),
  enteringleft: generatePictureAnimation(false, false),
  exitingright: generatePictureAnimation(true, false),
  exitingleft: generatePictureAnimation(true, true),
};
const PictureAnimation = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  ${({ state, sliding }) =>
    state === "exited"
      ? css`
          display: none;
        `
      : state === "entered"
      ? css`
          display: block;
        `
      : pictureAnimationMap[state + sliding] &&
        css`
          animation: ${pictureAnimationMap[state + sliding]}
            ${ANIMATION_DURATION}ms cubic-bezier(0.58, 0, 0.56, 1) both;
        `}
`;
const CopyCol = styled.div`
  ${mediaquery.md(css`
    position: relative;
    display: flex;
    justify-content: center;
  `)}
`;
const CopyInner = styled.div`
  position: relative;
  margin-bottom: ${spacing.default.md};
`;
const ArrowContainer = styled.div`
  position: absolute;
  z-index: 1;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LeftContainer = styled(ArrowContainer)`
  right: 0px;
  transform: translateY(-50%) translateX(50%);
`;
const RightContainer = styled(ArrowContainer)`
  left: 0px;
  transform: translateY(-50%) translateX(-50%);
`;
const textAnimationEntering = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
const textAnimationExiting = keyframes`
0% {
  opacity: 1;
}
100% {
  opacity: 0;
}
`;
const TextAnimation = styled.div`
  top: 0;
  position: ${({ state }) => (state.match(/^enter/) ? "relative" : "absolute")};
  ${({ state }) => state === "exited" && `display: none`};
  animation: ${({ state }) =>
      state === "entering"
        ? textAnimationEntering
        : state === "exiting"
        ? textAnimationExiting
        : null}
    ${ANIMATION_DURATION}ms linear;
`;
const Title = styled(HeaderXS)`
  color: ${colors.mediumGreen};
  margin: ${spacing.stack.sm};
`;
const Subtitle = styled(Body)`
  color: ${colors.mediumGreen};
  margin: ${spacing.stack.sm};
`;
const DotsStyled = styled(Dots)`
  display: flex;
  justify-content: center;
  margin: ${spacing.stack.xs};
  ${mediaquery.md(css`
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: flex-start;
    margin: 0;
  `)};
`;

const NextCol = styled.div`
  display: none;

  ${mediaquery.md(css`
    display: flex;
    justify-content: center;
    width: 10%;
  `)}
`;
const NextInner = styled.div`
  position: relative;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;

  ${mediaquery.md(css`
    margin-right: -${paddingWidth.md}rem;
  `)}
  ${mediaquery.lg(css`
    margin-right: -${paddingWidth.lg}rem;
  `)}
  ${mediaquery.xl(css`
    margin-right: -${paddingWidth.xl}rem;
  `)};
`;

const CarouselVideoOuter = styled.div`
  margin: 0 auto;
  ${mediaquery.md(css`
    width: ${(9 / 16) * 100}%;
  `)}
`;

const CarouselPicture = ({ picture, ...rest }) => (
  <Picture small={picture} fit={FITS.cover} {...rest} />
);

CarouselPicture.propTypes = {
  picture: PropTypes.object.isRequired,
};

export default FeaturedPostCarousel;
