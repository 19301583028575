// this module is consumed by gatsby-node.js
// so keep it commonjs

const getSlugForCategory = (category, categoryList) => {
  const cl = categoryList || category.categoryList[0];
  const firstSubcategory = category.subcategories[0];
  return getSlugForSubcategory(firstSubcategory, category, cl);
};

const getSlugForSubcategory = (subcategory, category, categoryList) => {
  const ca = category || subcategory.category[0];
  const cl = categoryList || ca.categoryList[0];
  const firstFlavor = subcategory.flavors[0];
  return getSlugForFlavor(firstFlavor, subcategory, ca, cl);
};

const getSlugForFlavor = (flavor, subcategory, category, categoryList) => {
  const sc = subcategory || flavor.subcategory.find((s) => s.category);
  const ca = category || sc.category[0];
  const cl = categoryList || ca.categoryList[0];
  return getSlugForModelHierarchy(flavor, sc, ca, cl);
};

const getSlugForModelHierarchy = (
  flavor,
  subcategory,
  category,
  categoryList
) => {
  if (!(flavor || {}).slug) {
    throw new Error(
      `Cannot determine slug for Flavor "${flavor.slug}": missing parent flavor slug`
    );
  }
  if (!(subcategory || {}).slug) {
    throw new Error(
      `Cannot determine slug for Flavor "${flavor.slug}": missing parent subcategory slug`
    );
  }
  if (!(category || {}).slug) {
    throw new Error(
      `Cannot determine slug for Flavor "${flavor.slug}": missing parent category slug`
    );
  }
  if (!(categoryList || {}).slug) {
    throw new Error(
      `Cannot determine slug for Flavor "${flavor.slug}": missing parent categoryList slug`
    );
  }

  return [
    (categoryList || {}).slug,
    (category || {}).slug,
    (subcategory || {}).slug,
    (flavor || {}).slug,
  ]
    .map((slug) => (slug || "").replace(/^\/?/, "/"))
    .join("");
};

module.exports = {
  getSlugForCategory,
  getSlugForSubcategory,
  getSlugForFlavor,
};
