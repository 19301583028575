import React, { useState, createContext } from "react";
import PropTypes from "prop-types";

import { HEADER_HEIGHT } from "src/organisms/Header";

const Context = createContext();

export const PageStore = ({ children, initOverride = {} }) => {
  const initialState = {
    transparentHeader: false,
    visibleHeader: true,
    forceVisibleHeader: false,
    headerHeight: HEADER_HEIGHT.desktop,
  };

  const updatedInitialState = {
    ...initialState,
    ...initOverride,
  };

  const pageState = {
    transparentHeader: useState(updatedInitialState.transparentHeader),
    visibleHeader: useState(updatedInitialState.visibleHeader),
    forceVisibleHeader: useState(updatedInitialState.forceVisibleHeader),
    headerHeight: useState(updatedInitialState.headerHeight),
    cardNavigating: useState(false),
  };

  return <Context.Provider value={pageState}>{children}</Context.Provider>;
};

PageStore.propTypes = {
  initOverride: PropTypes.instanceOf(Object),
  children: PropTypes.node,
};

export default Context;
