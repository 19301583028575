import React, { useContext } from "react";
import PropTypes from "prop-types";

import PageContext from "src/stores/Page";

const Spacer = ({ children }) => {
  const {
    headerHeight: [headerHeight],
  } = useContext(PageContext);

  return <div style={{ paddingTop: `${headerHeight}px` }}>{children}</div>;
};

Spacer.propTypes = {
  children: PropTypes.node,
};

export default Spacer;
