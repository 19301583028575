import React from "react";
import styled from "styled-components";

import { spacing } from "src/styles/variables";

import Hero from "./Hero";

const PageComponent = (props) => <HeroStyled {...props} />;

const HeroStyled = styled(Hero)`
  margin: ${spacing.stack.xl};
`;

export default PageComponent;
