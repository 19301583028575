import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "styled-components";
import GlobalStyles from "src/styles/GlobalStyles";

import {
  columns,
  gutterWidth,
  paddingWidth,
  container,
  breakpoints,
  colors,
} from "src/styles/variables";
import { TouchStore } from "src/stores/Touch";

const gridConfig = {
  mediaQuery: "only screen",
  columns,
  gutterWidth,
  paddingWidth,
  container,
  breakpoints,
};

const theme = {
  awesomegrid: gridConfig,
  numericFact: {
    circleBorderColor: colors.lightGrey,
    circleTextColor: colors.activiaGreen,
    labelColor: colors.mediumGrey,
    margin: "0 0 15px 0",
  },
  chip: {
    bg: colors.lighterGrey,
    activeBg: colors.activiaGreen,
    color: colors.darkGrey,
    activeColor: colors.white,
    border: colors.lighterGrey,
    activeBorder: colors.activiaGreen,
  },
};
const Layout = ({ children }) => (
  <TouchStore>
    <GlobalStyles />
    <ThemeProvider theme={theme}>{children}</ThemeProvider>
  </TouchStore>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
