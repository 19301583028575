import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { colors } from "src/styles/variables";

const ProgressBar = React.forwardRef(({ progress, ...rest }, ref) => (
  <Outer ref={ref} {...rest}>
    <Inner progress={Math.min(100, Math.max(0, progress * 100))} />
  </Outer>
));

ProgressBar.propTypes = {
  progress: PropTypes.number, // 0.0 - 1.0
};

const Outer = styled.div`
  height: 8px;
  background: rgba(255, 255, 255, 0);
  transition: background 0.3s cubic-bezier(0.58, 0, 0.56, 1);
  &:hover {
    background: ${colors.translucidWhite};
  }
`;

const Inner = styled.div.attrs(({ progress }) => ({
  style: {
    width: `${progress}%`,
  },
}))`
  height: 100%;
  background: ${colors.activiaGreen};
  transition: width 0.1s linear;
`;

export default ProgressBar;
