import React from "react";

import SVG from "../SVG";

export default SVG(() => (
  <svg viewBox="0 0 16 16" fill="none">
    <path
      d="M6 2v11.409M1 7v6.409M10.5 9v4.409M15 6v7.409"
      stroke="#02BA44"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
));
