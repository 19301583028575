import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { outline } from "src/styles/variables";
import FloatingCircle, { SIZES } from "src/atoms/FloatingCircle";
import NotificationBadge from "src/atoms/NotificationBadge";

export { SIZES };

const FloatingButton = React.memo(({ size, badge, children, ...rest }) => (
  <Circle size={size} {...rest}>
    {(badge | 0) > 0 && <Badge>{badge}</Badge>}
    {children}
  </Circle>
));

FloatingButton.propTypes = {
  ...FloatingCircle.propTypes,
  badge: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
};

const Circle = styled(FloatingCircle)`
  position: relative;
  cursor: pointer;

  &:focus {
    outline: none;
    box-shadow: ${outline};
  }
`;

const Badge = styled(NotificationBadge)`
  position: absolute;
  top: 0;
  right: 0;
`;

export default FloatingButton;
