import React from "react";

import SVG from "../SVG";

export default SVG(({ stroke, fill }) => (
  <svg viewBox="0 0 16 16" fill={fill}>
    <path
      d="M9 7.99805L4 7.99805"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M12 7.99805H7"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
));
