import React from "react";

import SVG from "../SVG";

export default SVG(() => (
  <svg viewBox="0 0 24 24" fill="none">
    <g transform="translate(-5 0)">
      <path d="M6.96 7.5h9.955H6.96z" fill="#666" />
      <path d="M6.96 7.5h9.955" stroke="#02BA44" strokeLinecap="round" />
      <path d="M7.05 10.5h9.955H7.05z" fill="#666" />
      <path d="M7.05 10.5h9.955" stroke="#02BA44" strokeLinecap="round" />
      <path d="M6.96 13.5h9.955H6.96z" fill="#666" />
      <path d="M6.96 13.5h9.955" stroke="#02BA44" strokeLinecap="round" />
      <path d="M6.96 16.5h4.978H6.96z" fill="#666" />
      <path d="M6.96 16.5h4.978" stroke="#02BA44" strokeLinecap="round" />
    </g>
  </svg>
));
