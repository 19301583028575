// import React from 'react';
import styled, { css } from "styled-components";
import PropTypes from "prop-types";

import { rem, mediaquery, lineHeight, fontWeights } from "src/styles/variables";

const typographyProptypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // it can be an Object when rendered as a Gatsby Link
};

const linkReset = () => css`
  text-decoration: none;

  &:visited {
    color: inherit;
  }
`;

export const HeaderL = styled.span`
  font-size: ${rem(38)};
  line-height: ${lineHeight(38, 46)};
  font-weight: ${fontWeights.medium};
  ${mediaquery.md(css`
    font-size: ${rem(60)};
    line-height: ${lineHeight(60, 72)};
  `)}
`;

HeaderL.propTypes = typographyProptypes;

export const HeaderM = styled.span`
  font-size: ${rem(32)};
  line-height: ${lineHeight(32, 48)};
  font-weight: ${fontWeights.medium};
  ${mediaquery.md(css`
    font-size: ${rem(42)};
    line-height: ${lineHeight(42, 50)};
  `)}
`;

HeaderM.propTypes = typographyProptypes;

export const HeaderS = styled.span`
  font-size: ${rem(24)};
  line-height: ${lineHeight(24, 29)};
  font-weight: ${fontWeights.medium};
  ${mediaquery.md(css`
    font-size: ${rem(32)};
    line-height: ${lineHeight(32, 38)};
  `)}
`;

HeaderS.propTypes = typographyProptypes;

export const HeaderXS = styled.span`
  font-size: ${rem(19)};
  line-height: ${lineHeight(19, 23)};
  font-weight: ${fontWeights.medium};
  ${mediaquery.md(css`
    font-size: ${rem(28)};
    line-height: ${lineHeight(28, 34)};
  `)}
`;

HeaderXS.propTypes = typographyProptypes;

export const SubHeaderM = styled.span`
  font-size: ${rem(24)};
  line-height: ${lineHeight(24, 30)};
  font-weight: ${fontWeights.medium};
`;

SubHeaderM.propTypes = typographyProptypes;

export const SubHeaderS = styled.span`
  font-size: ${rem(14)};
  line-height: ${lineHeight(14, 24)};
  font-weight: ${fontWeights.medium};
`;

SubHeaderS.propTypes = typographyProptypes;

export const LabelL = styled.span`
  font-size: ${rem(19)};
  line-height: ${lineHeight(19, 28)};
  font-weight: ${fontWeights.medium};
`;

LabelL.propTypes = typographyProptypes;

export const LabelM = styled.span`
  font-size: ${rem(14)};
  line-height: ${lineHeight(14, 24)};
  font-weight: ${fontWeights.medium};
`;

LabelM.propTypes = typographyProptypes;

export const LabelS = styled.span`
  font-size: ${rem(14)};
  line-height: ${lineHeight(14, 24)};
  font-weight: ${fontWeights.book};
`;

LabelS.propTypes = typographyProptypes;

export const LabelXS = styled.span`
  font-size: ${rem(12)};
  line-height: ${lineHeight(12, 20)};
  font-weight: ${fontWeights.medium};
`;

LabelXS.propTypes = typographyProptypes;

export const LabelUnderlined = styled.span`
  font-size: ${rem(14)};
  line-height: ${lineHeight(14, 30)};
  font-weight: ${fontWeights.book};
  text-decoration: underline;
`;

LabelUnderlined.propTypes = typographyProptypes;

export const IntroText = styled.span`
  font-size: ${rem(19)};
  line-height: ${lineHeight(19, 32)};
  font-weight: ${fontWeights.book};
`;

IntroText.propTypes = typographyProptypes;

export const Body = styled.span`
  font-size: ${rem(16)};
  line-height: ${lineHeight(16, 30)};
  font-weight: ${fontWeights.book};
`;

Body.propTypes = typographyProptypes;

export const LinkText = styled.span`
  font-size: ${rem(16)};
  line-height: ${lineHeight(16, 30)};
  font-weight: ${fontWeights.medium};
  ${linkReset}
`;

LinkText.propTypes = typographyProptypes;

export const NavigationText = styled.span`
  font-size: ${rem(10)};
  line-height: ${lineHeight(10, 11)};
  font-weight: ${fontWeights.bold};
  text-transform: uppercase;
  letter-spacing: ${rem(2)};
  ${mediaquery.md(css`
    font-size: ${rem(12)};
    line-height: ${lineHeight(12, 11)};
  `)}
  ${linkReset}
`;

NavigationText.propTypes = typographyProptypes;

export const Detail = styled.span`
  font-size: ${rem(10)};
  line-height: ${lineHeight(10, 17)};
  font-weight: ${fontWeights.book};
  ${mediaquery.md(css`
    font-size: ${rem(12)};
    line-height: ${lineHeight(12, 18)};
  `)}
`;

Detail.propTypes = typographyProptypes;

export const FactLegalMention = styled.span`
  font-size: ${rem(6)};
  line-height: ${lineHeight(6, 10)};
  font-weight: ${fontWeights.medium};
  letter-spacing: -1%;
`;

FactLegalMention.propTypes = typographyProptypes;
