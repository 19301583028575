import React from "react";
import { Container } from "react-awesome-styled-grid";
import MediaAndText from "./MediaAndText";
import Spacer from "src/organisms/PageComponentList/Spacer";

const PageComponent = (props) => (
  <Container>
    <MediaAndText {...props} />
    <Spacer />
  </Container>
);

export default PageComponent;
