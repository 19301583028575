import React from "react";

import SVG from "../SVG";

export default SVG(() => (
  <svg viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 11.5l-6.023 5.847a1 1 0 0 1-1.368.023L8 16.818l5.478-5.318L8 6.182l.61-.552a1 1 0 0 1 1.367.023L16 11.5z"
      fill="#333"
      fillOpacity="0.4"
    />
  </svg>
));
