import React from "react";
import styled from "styled-components";

import { spacing } from "src/styles/variables";

import Spacer from "src/organisms/PageComponentList/Spacer";
import FeaturedPostCarousel from "./FeaturedPostCarousel";

const PageComponent = (props) => (
  <>
    <Spacer />
    <FeaturedPostCarouselStyled {...props} />
    <Spacer />
  </>
);

const FeaturedPostCarouselStyled = styled(FeaturedPostCarousel)`
  margin: ${spacing.stack.xl};
`;

export default PageComponent;
