import React from "react";

import SVG from "../SVG";

export default SVG(({ strokeColor, percent }) => (
  <svg width="100%" height="100%" viewBox="0 0 42 42" className="donut">
    <circle
      className="donut-hole"
      cx="21"
      cy="21"
      r="15.91549430918954"
      fill="none"
    />
    <circle
      className="donut-ring"
      cx="21"
      cy="21"
      r="15.91549430918954"
      fill="transparent"
      stroke="#000000"
      strokeWidth="4.5"
      opacity="0.2"
    />
    <circle
      className="donut-segment"
      cx="21"
      cy="21"
      r="15.91549430918954"
      fill="transparent"
      stroke={strokeColor}
      strokeWidth="4.5"
      strokeDasharray="0 100"
      strokeDashoffset="25"
      strokeLinecap="round"
    />
    <g className="donut-text">
      <text y="50%">
        <tspan x="50%" textAnchor="middle" className="donut-percent">
          {percent}%
        </tspan>
      </text>
    </g>
  </svg>
));
