// !!! Current limitations
// You can't have different aspect ratios on different sources
// Issue is opened : https://github.com/gatsbyjs/gatsby/issues/15189
// Workaround : give it a "cover" prop so it takes the size of its container
// and define your own mediaquery at the container level

// it's an abastraction of Gatsby-Image, have a look at the documentation
// https://www.gatsbyjs.org/packages/gatsby-image/#fragments
// Props ---
// small/medium/large : a ContentfulImage object ({ width, height, url })
// type : defaults to fluid, can be fixed
// fit : defaults to none, can be contain or cover (use a parent to restrict size)
//
// Usage :
// fixed : <Picture type={TYPES.fixed} small={Object} />
// fluid : <Picture small={Object} />
// fluid : <Picture small={Object} medium={Object} />
// fluid : <Picture small={Object} medium={Object} large={Object} />
// cover : <Picture fit={FITS.cover} small={Object} medium={Object} large={Object} />
//
// See Storybook for visual examples of all cases

import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";
import { breakpoints } from "src/styles/variables";

export const TYPES = Object.freeze({
  fixed: "fixed",
  fluid: "fluid",
});

export const FITS = Object.freeze({
  none: "none",
  contain: "contain",
  cover: "cover",
});

const fitMap = {
  none: {
    style: {},
    imgStyle: {},
  },
  contain: {
    style: { width: "100%", height: "100%" },
    imgStyle: { objectFit: "contain", maxWidth: "100%", maxHeight: "100%" },
  },
  cover: {
    style: { width: "100%", height: "100%" },
    imgStyle: {},
  },
};

const mediaString = (size) => `(min-width: ${size}rem)`;

const Picture = ({
  small,
  medium,
  large,
  type = TYPES.fluid,
  fit = FITS.none,
  style,
  imgStyle,
  alt,
  ...rest
}) => {
  const sources = [
    small,
    { ...medium, media: mediaString(breakpoints.md) },
    { ...large, media: mediaString(medium ? breakpoints.lg : breakpoints.md) },
  ].filter((source) => !!source.src);

  return (
    <Img
      {...rest}
      style={{
        ...fitMap[fit].style,
        ...style,
      }}
      imgStyle={{
        ...fitMap[fit].imgStyle,
        ...imgStyle,
      }}
      {...{ [type]: sources }}
      alt={
        alt ||
        (small && small.description) ||
        (large && large.description) ||
        ""
      }
    />
  );
};

Picture.propTypes = {
  small: PropTypes.object.isRequired,
  medium: PropTypes.object,
  large: PropTypes.object,
  fit: PropTypes.oneOf(Object.values(FITS)),
  style: PropTypes.object,
  imgStyle: PropTypes.object,
  type: PropTypes.string,
  alt: PropTypes.string,
};

export default Picture;
