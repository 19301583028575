import React from "react";

import SVG from "../SVG";

export default SVG(() => (
  <svg viewBox="0 0 32 32" fill="none">
    <path
      d="M12.545 16.546h8.409m-5.5 5l-4.293-4.293a1 1 0 0 1 0-1.415l4.293-4.292"
      stroke="#02BA44"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
));
