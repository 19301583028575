import { useState, useEffect } from "react";

import throttle from "src/utils/throttle";

export const DIRECTIONS = Object.freeze({
  up: "up",
  down: "down",
});

const useScrollDirection = ({
  initialDirection,
  thresholdPixels,
  scrollThrottle,
} = {}) => {
  const [scrollDir, setScrollDir] = useState(initialDirection);

  useEffect(() => {
    const threshold = thresholdPixels || 0;
    const throttleLimit = scrollThrottle || 0;
    let lastScrollY = window.pageYOffset;
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = window.pageYOffset;

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        // We haven't exceeded the threshold
        ticking = false;
        return;
      }
      setScrollDir(scrollY > lastScrollY ? DIRECTIONS.down : DIRECTIONS.up);
      lastScrollY = scrollY > 0 ? scrollY : 0;
      ticking = false;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    const onScrollThrottled = throttle(onScroll, throttleLimit);
    window.addEventListener("scroll", onScrollThrottled, false);

    return () => window.removeEventListener("scroll", onScrollThrottled, false);
  }, []);
  return scrollDir;
};

export default useScrollDirection;
