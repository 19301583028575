import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import { HeaderM, LabelS } from "src/atoms/Typography";
import { mediaquery } from "src/styles/variables";
import { Container, Row, Col } from "react-awesome-styled-grid";

import {
  Category as CategoryShape,
  Subcategory as SubcategoryShape,
  Flavor as FlavorShape,
} from "./types";

import ProductFlavorsCard from "./ProductFlavorsCard";
import Spacer from "src/organisms/PageComponentList/Spacer";

const ProductFlavorsCardsList = ({ title, subheadline, categoriesList }) => {
  const offset = { lg: 3 };
  // console.log(categoriesList);

  let subcategoriesList = [];
  !!categoriesList &&
    categoriesList.map((listItem) => {
      if (listItem.__typename === "ContentfulProductCategory") {
        !!listItem.subcategories &&
          listItem.subcategories.map((subcategory) => {
            const updatedSubCategory = {
              ...subcategory,
              parentCardType: "category",
            };
            subcategoriesList.push(updatedSubCategory);
          });
      } else {
        const updatedSubCategory = {
          ...listItem,
          parentCardType: "subcategory",
        };
        subcategoriesList.push(updatedSubCategory);
      }
    });
  //console.log(subcategoriesList);
  return (
    <Outer as="section">
      <Container>
        <Row>
          <Col lg={26} offset={offset}>
            <ProductsListHeadline>{title}</ProductsListHeadline>
            <ProductsListSubheadline>{subheadline}</ProductsListSubheadline>
          </Col>
        </Row>
        {subcategoriesList.length > 0 && (
          <>
            <Spacer />
            <Row>
              <Col lg={26} offset={offset}>
                <ProductsCardsRow>
                  {subcategoriesList.map((listItem, index_) => (
                    <ProductsCardsCol key={`${listItem.id}-${index_}`}>
                      {/* <ProductFlavorsCard subcategory={listItem} /> */}
                      <ProductFlavorsCard
                        categoryTitle={listItem.category[0].title}
                        categorySubtitle={listItem.category[0].subtitle}
                        subcategoryTitle={listItem.title}
                        flavorsList={listItem.flavors}
                        parentCardType={listItem.parentCardType}
                        flavorsRelativeSlug={`${listItem.category[0].categoryList[0].slug}${listItem.category[0].slug}${listItem.slug}`}
                      />
                    </ProductsCardsCol>
                  ))}
                </ProductsCardsRow>
              </Col>
            </Row>
          </>
        )}
      </Container>
    </Outer>
  );
};

ProductFlavorsCardsList.propTypes = {
  title: PropTypes.string.isRequired,
  subheadline: PropTypes.string,
  categoriesList: PropTypes.arrayOf(
    PropTypes.shape({
      categories: PropTypes.arrayOf(
        PropTypes.shape(
          Object.assign({}, CategoryShape, {
            subcategories: PropTypes.arrayOf(
              PropTypes.shape(
                Object.assign({}, SubcategoryShape, {
                  flavors: PropTypes.arrayOf(
                    PropTypes.shape(Object.assign({}, FlavorShape))
                  ),
                })
              )
            ),
          })
        )
      ),
      subcategories: PropTypes.arrayOf(
        PropTypes.shape(
          Object.assign({}, SubcategoryShape, {
            flavors: PropTypes.arrayOf(
              PropTypes.shape(Object.assign({}, FlavorShape))
            ),
            category: PropTypes.arrayOf(
              PropTypes.shape(Object.assign({}, CategoryShape))
            ),
          })
        )
      ),
    })
  ),
};

const Outer = styled.div`
  padding-bottom: 0;
`;

const ProductsListHeadline = styled(HeaderM)``;

const ProductsListSubheadline = styled(LabelS)``;

const ProductsCardsRow = styled(Row)``;

const ProductsCardsCol = styled(Col)`
  width: 100%;
  flex: 0 0 auto;

  ${mediaquery.md(css`
    width: 33.33%;
  `)}
`;

export default ProductFlavorsCardsList;
