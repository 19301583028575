import React from "react";
import { colors } from "src/styles/variables";

import SVG from "../SVG";

export default SVG(() => (
  // <svg viewBox="0 0 40 40" fill="none">
  //   <path
  //     d="M15.862 30C25.296 30 30.456 22.3047 30.456 15.6314C30.456 15.4129 30.456 15.1953 30.441 14.9787C31.4448 14.2638 32.3114 13.3787 33 12.3647C32.0639 12.7731 31.0708 13.0409 30.054 13.1592C31.1247 12.5281 31.9261 11.5355 32.309 10.3661C31.3022 10.9543 30.2006 11.3688 29.052 11.5918C28.2787 10.7822 27.2559 10.2461 26.1419 10.0665C25.0279 9.88683 23.8848 10.0737 22.8895 10.5981C21.8943 11.1225 21.1023 11.9553 20.6362 12.9675C20.1701 13.9797 20.0558 15.1149 20.311 16.1976C18.2718 16.0969 16.2768 15.5752 14.4556 14.6662C12.6345 13.7572 11.0278 12.4813 9.74 10.9213C9.08409 12.0331 8.8832 13.3491 9.17823 14.6015C9.47326 15.854 10.242 16.9486 11.328 17.6626C10.5117 17.6388 9.71328 17.422 9 17.0305C9 17.0512 9 17.0728 9 17.0945C9.00032 18.2604 9.41026 19.3903 10.1603 20.2926C10.9103 21.1949 11.9542 21.8139 13.115 22.0448C12.3599 22.2476 11.5676 22.2772 10.799 22.1315C11.1268 23.1349 11.7649 24.0124 12.6241 24.6412C13.4833 25.27 14.5206 25.6186 15.591 25.6384C13.7747 27.0438 11.5311 27.8067 9.221 27.8044C8.8129 27.8037 8.4052 27.7793 8 27.7316C10.3456 29.2136 13.0749 29.9997 15.862 29.9961"
  //     fill="#02BA44"
  //   />
  // </svg>
  <svg viewBox="0 0 40 40" fill="none">
    <path
      d="M22,18.8l8-9.3h-1.9l-7,8.1l-5.6-8.1H9.2l8.4,12.2l-8.4,9.8h1.9l7.3-8.5l5.9,8.5h6.4L22,18.8L22,18.8z M19.4,21.8l-0.9-1.2
	l-6.8-9.7h2.9l5.5,7.8l0.9,1.2l7.1,10.2h-2.9L19.4,21.8L19.4,21.8z"
      fill={colors.twitter}
    />
  </svg>
));
