import React from "react";
import { colors } from "src/styles/variables";

import SVG from "../SVG";

export default SVG(() => (
  <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M33.025 13.93a3.364 3.364 0 00-2.357-2.371C28.6 11 20.292 11 20.292 11s-8.308 0-10.392.559c-1.148.302-2.04 1.208-2.356 2.371C7 16.015 7 20.365 7 20.365s0 4.35.559 6.434a3.364 3.364 0 002.356 2.371c2.07.56 10.377.56 10.377.56s8.307 0 10.391-.56c1.148-.302 2.04-1.208 2.357-2.371.543-2.084.543-6.434.543-6.434s0-4.35-.558-6.435z"
      fill={colors.youtube}
    />
    <path d="M17.573 24.322v-7.915l6.948 3.958-6.948 3.957z" fill="#fff" />
  </svg>
));
