import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { colors, icons } from "src/styles/variables";
import FloatingButton, { SIZES } from "src/molecules/FloatingButton";

import Close from "src/atoms/Vectors/Standard/Close";

const ANIMATION_DURATION = 300;

export default class VideoModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mounted: true,
      id: `yt-${
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15)
      }`,
    };
  }

  componentDidMount() {
    // Inject YouTube API script
    var tag = document.createElement("script");
    tag.src = "//www.youtube.com/player_api";
    var firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    // if API is ready, directly set
    if (window.onYouTubeIframeAPIReady) {
      this.setYoutubePlayer();
    } else {
      // this event is only triggered once, that's why I need to set a boolean
      // in the window to be able to know wheter or not it has been downloaded already
      window.onYouTubeIframeAPIReady = () => {
        this.setYoutubePlayer();
      };
    }

    window.addEventListener("keydown", this.onKeyDown, false);
  }

  componentWillUnmount() {
    window.removeEventListener("keydown", this.onKeyDown, false);
    clearTimeout(this.timeout);
  }

  setYoutubePlayer = () => {
    this.player = new window.YT.Player(this.state.id, {
      videoId: this.props.id,
      playerVars: {
        rel: 0,
        theme: "light",
        cc_load_policy: 1,
        iv_load_policy: 3,
        showinfo: 0,
        playsinline: 1,
        color: "white",
        enablejsapi: 1,
      },
      events: {
        onReady: () => this.player.playVideo(),
      },
    });
  };

  onKeyDown = (event) => {
    if (event.which === 27) {
      this.handleClose();
    }
  };

  handleClose = () => {
    this.player.stopVideo();
    this.setState({ mounted: false });
    this.timeout = setTimeout(this.props.onClose, ANIMATION_DURATION);
  };

  render() {
    return (
      <Wrapper mounted={this.state.mounted}>
        <div id={this.state.id} />
        <StyledFloatingButton onClick={this.handleClose} size={SIZES.medium}>
          <Close width={icons.m} />
        </StyledFloatingButton>
      </Wrapper>
    );
  }
}

VideoModal.propTypes = {
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${colors.white};
  animation: ${({ mounted }) =>
    `${mounted ? "fadeIn" : "fadeOut"} ${ANIMATION_DURATION}ms ease-in-out`};
  z-index: 999;

  iframe {
    width: 100%;
    height: 100%;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
`;

const StyledFloatingButton = styled(FloatingButton)`
  position: absolute;
  top: 50px;
  right: 50px;
`;
