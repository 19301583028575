import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { useInView } from "react-intersection-observer";
import { Container, Row, Col } from "react-awesome-styled-grid";

import { mediaquery, paddingWidth } from "src/styles/variables";
import offsetRelative from "src/utils/offsetRelative";
import throttle from "src/utils/throttle";
import animations from "./animations";

const Illustration = ({ name, ...rest }) => {
  const [inViewRef, inView] = useInView({});
  const reactRef = React.createRef();
  const [hasCompletedOnce, setHasCompletedOnce] = useState(false);
  const [scrollPercentage, setScrollPercentage] = useState(0);

  const onScroll = () => {
    if (!reactRef.current) {
      return;
    }
    const scrollPosition = window.pageYOffset;
    const { top } = offsetRelative(reactRef.current);
    const containerHeight = reactRef.current.offsetHeight;

    const start = 0;
    const stop = top + containerHeight;
    const engaged = Math.max(0, scrollPosition - start);
    const progress = Math.min(1, engaged / stop);

    setScrollPercentage(progress);
  };

  const onScrollThrottle = throttle(onScroll, 50);

  useEffect(() => {
    if (inView) {
      window.addEventListener("scroll", onScrollThrottle, false);
      return () =>
        window.removeEventListener("scroll", onScrollThrottle, false);
    }
  });

  useEffect(() => {
    setHasCompletedOnce(false);
  }, [name]);

  const Animation = animations[name];

  return (
    <Container {...rest}>
      <Row>
        <Col md={3}>
          <Outer>
            <Midder ref={inViewRef}>
              <Inner ref={reactRef}>
                <Animation
                  play={!hasCompletedOnce}
                  position={hasCompletedOnce ? 0.55 + scrollPercentage : 0}
                  onEnterFrame={(e) => {
                    if (e.currentTime >= 200) {
                      setHasCompletedOnce(true);
                    }
                  }}
                />
              </Inner>
            </Midder>
          </Outer>
        </Col>
      </Row>
    </Container>
  );
};

Illustration.propTypes = {
  name: PropTypes.oneOf(Object.keys(animations)),
};

const Outer = styled.div`
  position: relative;
  margin-left: -${paddingWidth.md}rem;
  margin-right: -${paddingWidth.md}rem;

  ${mediaquery.md(css`
    margin-left: -${paddingWidth.md}rem;
    margin-right: 0;
  `)}
  ${mediaquery.lg(css`
    margin-left: -${paddingWidth.lg}rem;
    margin-right: 0;
  `)}
  ${mediaquery.xl(css`
    margin-left: -${paddingWidth.xl}rem;
    margin-right: 0;
  `)}
`;
const Midder = styled.div`
  ${mediaquery.md(css`
    position: absolute;
    width: 100%;
    padding-top: 100%;
  `)}
`;
const Inner = styled.div`
  ${mediaquery.md(css`
    position: absolute;
    top: -10px;
    right: 0;
    bottom: 10px;
    left: 0;
  `)};
`;

export default Illustration;
