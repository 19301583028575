import React, { createContext } from "react";
import PropTypes from "prop-types";
import { TYPES } from "src/molecules/Card";

const Context = createContext();

export const LatestCardsStore = ({
  latestEditorialPhotography: { nodes: editorialPhotographyList },
  latestEditorialIllustration: { nodes: editorialIllustrationList },
  latestInfluencer: { nodes: influencerList },
  latestRecipe: { nodes: recipeList },
  latestFlavor: { nodes: flavorList },
  children,
}) => {
  const cards = [
    ...editorialPhotographyList.map((card) => ({
      ...card,
      __typename: TYPES.editorialPhotography,
    })),
    ...editorialIllustrationList.map((card) => ({
      ...card,
      __typename: TYPES.editorialIllustration,
    })),
    ...influencerList.map((card) => ({
      ...card,
      __typename: TYPES.influencer,
    })),
    ...recipeList.map((card) => ({ ...card, __typename: TYPES.recipe })),
    ...flavorList.map((card) => ({ ...card, __typename: TYPES.product })),
  ].sort((a, b) => (new Date(a.updatedAt) < new Date(b.updatedAt) ? 1 : -1));

  return <Context.Provider value={cards}>{children}</Context.Provider>;
};

LatestCardsStore.propTypes = {
  latestEditorialPhotography: PropTypes.shape({
    nodes: PropTypes.arrayOf(PropTypes.object.isRequired),
  }).isRequired,
  latestEditorialIllustration: PropTypes.shape({
    nodes: PropTypes.arrayOf(PropTypes.object.isRequired),
  }).isRequired,
  latestInfluencer: PropTypes.shape({
    nodes: PropTypes.arrayOf(PropTypes.object.isRequired),
  }).isRequired,
  latestRecipe: PropTypes.shape({
    nodes: PropTypes.arrayOf(PropTypes.object.isRequired),
  }).isRequired,
  latestFlavor: PropTypes.shape({
    nodes: PropTypes.arrayOf(PropTypes.object.isRequired),
  }).isRequired,
  children: PropTypes.node,
};

export default Context;
