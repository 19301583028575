import React from "react";

import SVG from "../SVG";

export default SVG(() => (
  <svg viewBox="0 0 24 24" fill="none">
    <path
      transform="translate(-5 0)"
      d="M9 14.027V10m7 7H6V7m6 6.955V7m3 6.955V10"
      stroke="#02BA44"
      strokeLinecap="round"
    />
  </svg>
));
