import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-awesome-styled-grid";

import { spacing } from "src/styles/variables";
import RichText from "./RichText";

const PageComponent = (props) => (
  <Container>
    <RowStyled as="section">
      <Col md={10} offset={{ md: 3 }}>
        <RichText enableRenderer {...props} />
      </Col>
    </RowStyled>
  </Container>
);

const RowStyled = styled(Row)`
  /* use a spacer instead, so that RichText+TwoColumns can exist space-free */
  /* margin: ${spacing.stack.xl}; */
`;

export default PageComponent;
