// Avoid getClientBoundingRect for performance maters
// https://gist.github.com/paulirish/5d52fb081b3570c81e3a
export default function offsetRelative(element) {
  var result = { left: 0, top: 0 };
  do {
    let offsetTop = element.offsetTop;
    let offsetLeft = element.offsetLeft;
    if (!isNaN(offsetTop)) result.top += offsetTop;
    if (!isNaN(offsetLeft)) result.left += offsetLeft;
    element =
      element.tagName === "BODY" ? element.parentElement : element.offsetParent;
  } while (element);
  return result;
}
