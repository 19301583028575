import React from "react";
import PropTypes from "prop-types";
import styled, { keyframes, css } from "styled-components";

import { colors, fontWeights } from "src/styles/variables";
import Donut from "src/atoms/Vectors/Charts/Donut";

const DonutProgressBar = React.memo(({ className, strokeColor, percent }) => {
  return (
    <DonutAnimation
      className={`donutProgressBar ${className}`}
      strokeColor={strokeColor}
      percent={percent}
    />
  );
});

DonutProgressBar.propTypes = {
  className: PropTypes.string,
  strokeColor: PropTypes.string,
  percent: PropTypes.number,
};

const donut = (percent) => keyframes`
 0% {
      stroke-dasharray: 0, 100;
  }
  100% {
      stroke-dasharray:  ${percent},
      ${100 - percent};
  }
`;

const donutAnimationFrames = (percent) => css`
  animation: 1s ${donut(percent)} ease forwards;
  animation-delay: 1s;
`;

const DonutAnimation = styled(Donut)`
  .donut-segment {
    ${(props) => donutAnimationFrames(props.percent || 0)}
  }

  .donut-percent {
    fill: ${colors.white};
    font-weight: ${fontWeights.bold};
    font-size: 0.5rem;
  }
`;

export default DonutProgressBar;
