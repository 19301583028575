import React from "react";
import styled from "styled-components";

import { spacing } from "src/styles/variables";

import PictureCarousel from "./PictureCarousel";

const PageComponent = (props) => <PictureCarouselStyled {...props} />;

const PictureCarouselStyled = styled(PictureCarousel)`
  margin: ${spacing.stack.xl};
`;

export default PageComponent;
