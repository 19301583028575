import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Picture, { FITS } from "src/atoms/Picture";

const LinkImage = React.memo(({ image, title, ...rest }) => {
  return (
    <Outer title={title} {...rest}>
      {image && <Picture fit={FITS.contain} small={image} />}
    </Outer>
  );
});

LinkImage.propTypes = {
  image: PropTypes.object.isRequired,
  title: PropTypes.string,
};

const Outer = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default LinkImage;
