import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { spacing, colors, icons } from "src/styles/variables";
import { SubHeaderM, Body } from "src/atoms/Typography";
import Accordion from "src/atoms/Accordion";
import RichText from "src/atoms/RichText";
import Plus from "src/atoms/Vectors/Links/Plus";
import Minus from "src/atoms/Vectors/Links/Minus";

const ProductFaq = ({
  title,
  titlePosition,
  collapsibleStyle,
  items,
  trackInteraction = undefined,
}) => {
  const [active, setActive] = useState();
  titlePosition = titlePosition || "left";
  collapsibleStyle = collapsibleStyle || "without separator";
  console.log("collapsible style in collapsible list: ", collapsibleStyle);
  return (
    <div>
      <Subtitle as="h3" position={titlePosition}>
        {title}
      </Subtitle>
      {items.map(({ id, question, answer }, idx) => (
        <Question
          key={id}
          hiddenLabel={
            <>
              <AlignedPlus
                size={icons.xs}
                stroke={
                  collapsibleStyle === "with separator"
                    ? colors.white
                    : colors.activiaGreen
                }
                fill="none"
                display={
                  collapsibleStyle === "with separator"
                    ? "inline-flex"
                    : "inline-block"
                }
                collapsibleStyle={collapsibleStyle}
              />
              <span className="question-title">{question}</span>
            </>
          }
          visibleLabel={
            <>
              <AlignedMinus
                size={icons.xs}
                stroke={
                  collapsibleStyle === "with separator"
                    ? colors.white
                    : colors.activiaGreen
                }
                fill="none"
                display={
                  collapsibleStyle === "with separator"
                    ? "inline-flex"
                    : "inline-block"
                }
                collapsibleStyle={collapsibleStyle}
              />
              <span className="question-title">{question}</span>
            </>
          }
          onChange={() => setActive(idx)}
          trackInteraction={trackInteraction}
          initial={idx === active}
          collapsibleStyle={collapsibleStyle}
        >
          <ParagraphStyled>
            <Answer>
              <RichText as="div" enableRenderer doc={answer} />
            </Answer>
          </ParagraphStyled>
        </Question>
      ))}
    </div>
  );
};

ProductFaq.propTypes = {
  title: PropTypes.string.isRequired,
  titlePosition: PropTypes.string,
  collapsibleStyle: PropTypes.string,
  items: PropTypes.array.isRequired,
  trackInteraction: PropTypes.func,
};

const Subtitle = styled(SubHeaderM)`
  color: ${colors.mediumGreen};
  margin: ${spacing.stack.md};
  text-align: ${({ position }) => position};
`;

const Question = styled(Accordion)`
  ${({ collapsibleStyle }) =>
    collapsibleStyle !== "with separator"
      ? `
  margin: ${spacing.stack.xs};`
      : null}
`;

const Answer = styled.div`
  padding: 0 1px;
  margin-bottom: ${spacing.default.md};
`;

const ParagraphStyled = styled(Body)`
  color: ${colors.darkGrey};
  margin: ${spacing.stack.lg};
`;

const AlignedPlus = styled(Plus)`
  ${({ collapsibleStyle }) =>
    collapsibleStyle === "with separator"
      ? `
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      padding: ${spacing.default.sm};
      background: ${colors.activiaMediumGreen};
      box-sizing: content-box;
      `
      : `transform: translateY(1px);`}

  svg {
    width: 16px;
    height: 16px;
  }

  path {
    fill: transparent;
  }
`;

const AlignedMinus = styled(Minus)`
  ${({ collapsibleStyle }) =>
    collapsibleStyle === "with separator"
      ? `
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      padding: ${spacing.default.sm};
      background: ${colors.activiaMediumGreen};
      box-sizing: content-box;
      `
      : `transform: translateY(1px);`}

  svg {
    width: 16px;
    height: 16px;
  }

  path {
    fill: transparent;
  }
`;

export default ProductFaq;
