import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Col, Visible } from "react-awesome-styled-grid";

import Picture from "src/atoms/Picture";
import { colors, spacing } from "src/styles/variables";
import { HeaderM, HeaderS } from "src/atoms/Typography";
import RichText from "src/atoms/RichText";
import Link, { TYPES } from "src/atoms/Link";
import Spacer from "src/organisms/PageComponentList/Spacer";

const ColStyled = styled(Col)`
  margin: ${spacing.stack.xl};
`;

const Wrapper = styled.div`
  position: relative;
`;

const PictureContainer = styled.div`
  width: ${({ fixedImageWidthValue }) => fixedImageWidthValue || "100%"};
`;

const StyledPicture = styled(Picture)`
  border-radius: ${({ isPictureStyled }) => (isPictureStyled ? "12px" : "")};
  box-shadow: ${({ isPictureStyled }) =>
    isPictureStyled ? "0 6px 10px 2px " + colors.translucidBlack : ""};
  width: 100%;

  & > div {
    display: none;
  }

  picture img {
    position: static !important;
  }
`;

const TextContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  padding: ${spacing.default.xl};
`;

const Cta = styled(Link)`
  margin-top: ${spacing.default.lg};
`;

const Inner = styled.div`
  width: 100%;
  height: 100%;
`;

const ImagePush = ({
  image,
  imageMobile,
  fixedImageWidth,
  isPictureStyled,
  title,
  cta,
  url,
  ...rest
}) => {
  const fixedImageWidthValue = fixedImageWidth && fixedImageWidth + "px";

  return (
    <ColStyled xs={8} md={12} offset={{ md: fixedImageWidth ? 3 : 2 }}>
      <Wrapper {...rest}>
        <Inner as={url && url.length ? Link : "div"} url={url}>
          <PictureContainer fixedImageWidthValue={fixedImageWidthValue}>
            <StyledPicture
              small={imageMobile}
              large={image}
              isPictureStyled={isPictureStyled}
            />
          </PictureContainer>
          {(title || cta) && (
            <TextContainer>
              {title && (
                <>
                  <Visible xs sm>
                    <RichText as={HeaderS} doc={title} />
                  </Visible>
                  <Visible md lg xl>
                    <RichText as={HeaderM} doc={title} />
                  </Visible>
                </>
              )}
              {cta && <Cta {...cta} type={TYPES.button} />}
            </TextContainer>
          )}
        </Inner>
      </Wrapper>
      <Spacer />
    </ColStyled>
  );
};

ImagePush.propTypes = {
  image: PropTypes.object.isRequired,
  imageMobile: PropTypes.object.isRequired,
  fixedImageWidth: PropTypes.number,
  isPictureStyled: PropTypes.bool,
  title: PropTypes.object.isRequired,
  cta: PropTypes.instanceOf(Object),
  url: PropTypes.string,
};

export default ImagePush;
