import React from "react";
import { colors } from "src/styles/variables";

import SVG from "../SVG";

export default SVG(() => (
  <svg viewBox="0 0 40 40" fill="none">
    <path
      d="M16.945 13.922v2.788H15v3.409h1.945V30.25h3.996V20.12h2.682s.251-1.635.373-3.423h-3.04v-2.33c0-.35.436-.818.867-.818H24V10h-2.96c-4.194 0-4.095 3.412-4.095 3.922z"
      fill={colors.facebook}
    />
  </svg>
));
