import React from "react";
import styled from "styled-components";

import { spacing } from "src/styles/variables";

import InfluencerPush from "./InfluencerPush";
import Spacer from "src/organisms/PageComponentList/Spacer";

const PageComponent = (props) => (
  <>
    <Spacer />
    <InfluencerPushStyled {...props} />
    <Spacer />
  </>
);

const InfluencerPushStyled = styled(InfluencerPush)`
  margin: ${spacing.stack.xl};
`;

export default PageComponent;
