import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import Card, { DIMENSIONS } from "src/molecules/Card";

const WAIT_FOR_REACT_TO_RENDER = 100;
const ANIMATION_STEP01_DURATION = 233;
const ANIMATION_STEP01_EASING = "linear";
const ANIMATION_STEP01_OVERLAP02 = 100;
const ANIMATION_STEP02_DURATION = 450;
const ANIMATION_STEP02_EASING = "ease-in";

export const ANIMATION_DURATION =
  WAIT_FOR_REACT_TO_RENDER +
  ANIMATION_STEP01_DURATION -
  ANIMATION_STEP01_OVERLAP02 +
  ANIMATION_STEP02_DURATION;

const CardNavigationTransition = ({ top: initialTop, left: initialLeft }) => {
  const [opacity, setOpacity] = useState(0);
  const [scale, setScale] = useState(1);
  const [top, setTop] = useState(initialTop);
  const [left, setLeft] = useState(initialLeft);

  // use setTimeout because useEffect doesn't seem to give enough time
  setTimeout(() => {
    // ANIMATION_STEP01_DURATION
    setOpacity(1);
    setTimeout(() => {
      // ANIMATION_STEP02_DURATION
      setScale((window.innerWidth / DIMENSIONS.width) * 1.05);
      setTop(
        (window.innerWidth / DIMENSIONS.width) * (DIMENSIONS.height / 2) -
          DIMENSIONS.height / 2
      );
      setLeft(window.innerWidth / 2 - DIMENSIONS.width / 2);
    }, ANIMATION_STEP01_DURATION - ANIMATION_STEP01_OVERLAP02);
  }, WAIT_FOR_REACT_TO_RENDER);

  return (
    <Outer>
      <Inner opacity={opacity} scale={scale} left={left} top={top}>
        <Card />
      </Inner>
    </Outer>
  );
};

CardNavigationTransition.propTypes = {
  top: PropTypes.number.isRequired,
  left: PropTypes.number.isRequired,
};

const Outer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
`;

const Inner = styled.div`
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  opacity: ${({ opacity }) => opacity};

  transform: scale(${({ scale }) => scale});
  transition: opacity ${ANIMATION_STEP01_DURATION / 1000}s
      ${ANIMATION_STEP01_EASING},
    transform ${ANIMATION_STEP02_DURATION / 1000}s ${ANIMATION_STEP02_EASING},
    top ${ANIMATION_STEP02_DURATION / 1000}s ${ANIMATION_STEP02_EASING},
    left ${ANIMATION_STEP02_DURATION / 1000}s ${ANIMATION_STEP02_EASING};
`;

export default CardNavigationTransition;
