import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-awesome-styled-grid";

import { spacing } from "src/styles/variables";
import Share from "./Share";

const PageComponent = (props) => (
  <Container>
    <RowStyled>
      <Col md={6} offset={{ md: 5 }}>
        <Share {...props} />
      </Col>
    </RowStyled>
  </Container>
);

const RowStyled = styled(Row)`
  margin: ${spacing.stack.xl};
`;

export default PageComponent;
