import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import InnerHTML from "dangerously-set-html-content";

const CodeEmbed = ({ embedCode }) => {
  return (
    <Container>
      <InnerHTML html={embedCode} />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;

  iframe {
    border: 0 !important;
  }
`;

CodeEmbed.propTypes = {
  embedCode: PropTypes.string.isRequired,
};

export default CodeEmbed;
