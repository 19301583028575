import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { spacing, mediaquery } from "src/styles/variables";

import CollapsibleList from "./CollapsibleList";
import FilteredList from "./FilteredList";
import Link, { TYPES } from "src/atoms/Link";

const QuestionAnswerList = ({
  collapsible,
  collapsibleStyle,
  title,
  titlePosition,
  items,
  ctaList,
  trackInteraction = undefined,
}) => {
  return (
    <>
      {!!collapsible && (
        <CollapsibleList
          title={title}
          titlePosition={titlePosition}
          items={items}
          collapsibleStyle={collapsibleStyle}
          trackInteraction={trackInteraction}
        />
      )}
      {!collapsible && (
        <FilteredList
          title={title}
          titlePosition={titlePosition}
          items={items}
        />
      )}
      {!!ctaList && (
        <CtasContainer position="center">
          {ctaList.map((ctaItem, key) => (
            <ButtonContainer position="center" key={key}>
              <Link {...ctaItem} type={TYPES.button} />
            </ButtonContainer>
          ))}
        </CtasContainer>
      )}
    </>
  );
};

QuestionAnswerList.propTypes = {
  collapsible: PropTypes.bool,
  collapsibleStyle: PropTypes.string,
  title: PropTypes.node.isRequired,
  titlePosition: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      question: PropTypes.string.isRequired,
      answer: PropTypes.object.isRequired,
      topics: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
        })
      ),
    })
  ),
  ctaList: PropTypes.arrayOf(PropTypes.object),
  trackInteraction: PropTypes.func,
};

const CtasContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0;
  margin-top: ${spacing.default.lg};

  ${mediaquery.sm(css`
    flex-direction: row;
    justify-content: center;
  `)}
`;

const ButtonContainer = styled.div`
  display: flex;
  margin: ${spacing.default.xs} 0 0;

  ${mediaquery.sm(css`
    margin: ${spacing.default.xs} ${spacing.default.xs} 0 0;
  `)}
  ${mediaquery.md(css`
    justify-content: center
    margin-right: calc(${spacing.default.xs} / 2);
    margin-left: calc(${spacing.default.xs} / 2);
  `)}
`;

export default QuestionAnswerList;
