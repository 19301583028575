import React from "react";

import SVG from "../SVG";

export default SVG(() => (
  <svg viewBox="0 0 32 32" fill="none">
    <path
      d="M19.454 16.546h-8.409m5.5-5l4.294 4.293a1 1 0 0 1 0 1.414l-4.293 4.293"
      stroke="#02BA44"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
));
