import React from "react";

import SVG from "../SVG";

export default SVG(() => (
  <svg viewBox="0 0 16 16" fill="none">
    <path
      d="M12.6742 7.15634C13.2917 7.54929 13.2917 8.45071 12.6742 8.84366L4.53688 14.022C3.87115 14.4456 3 13.9674 3 13.1783L3 2.82167C3 2.03258 3.87115 1.55437 4.53688 1.97801L12.6742 7.15634Z"
      fill="#02BA44"
    />
  </svg>
));
