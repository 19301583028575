import React from "react";
import PropTypes from "prop-types";

import styled, { css } from "styled-components";
import { colors, mediaquery } from "src/styles/variables";

const HeaderCurved = ({ children, backgroundColor, fixedHeight, ...rest }) => {
  return (
    <HeaderCurvedContainer fixedHeight={fixedHeight} {...rest}>
      <HeaderCurvedInner style={{ backgroundColor: backgroundColor }}>
        {children}
      </HeaderCurvedInner>
    </HeaderCurvedContainer>
  );
};

HeaderCurved.propTypes = {
  children: PropTypes.node,
  backgroundColor: PropTypes.string,
  fixedHeight: PropTypes.bool.isRequired,
};

export default HeaderCurved;
const HeaderCurvedInner = styled.div`
  position: relative;
  background: ${colors.activiaGreen};
  width: 120%;
  left: -10%;
  padding: 0 10% 50px 10%;
  padding-top: 2rem;
  overflow: hidden;
  border-radius: 0 0 90% 90% / 0 0 30vw 30vw;
  max-height: ${(props) => (props.fixedHeight ? "80vh" : null)};
  min-height: ${(props) => (props.fixedHeight ? null : "50vh")};
  display: flex;
  ${mediaquery.md(css`
    max-height: ${(props) => (props.fixedHeight ? "initial" : null)};
    min-height: ${(props) => (props.fixedHeight ? null : "38vh")};
  `)};
`;
const HeaderCurvedContainer = styled.div`
  overflow: hidden;
`;
