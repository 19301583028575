import React from "react";

import SVG from "../SVG";

export default SVG(({ stroke, fill }) => (
  <svg viewBox="0 0 16 16" fill={fill}>
    <path
      d="M8 2.99805L8 7.99805L3 7.99805"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M13 7.99805H8V12.998"
      stroke={stroke}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
));
