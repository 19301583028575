/*******************************************/
/* RECOMMENDED IF YOU'RE TWEAKING THE PATH */
/*            https://xvg.now.sh/          */
/*******************************************/

import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { InView } from "react-intersection-observer";
import { ScreenClass } from "react-awesome-styled-grid";

import EditorialPush, { PUSH_PADDING } from "src/molecules/EditorialPush";

import debounce from "src/utils/debounce";
import throttle from "src/utils/throttle";
import offsetRelative from "src/utils/offsetRelative";
import getViewport from "src/utils/viewport";

import { colors, rem } from "src/styles/variables";

// Offset value of a point's handle (e.g. point is at 600px => handle will be at 300/900 on small & 0/1200 on large)
const HANDLE_OFFSET = Object.freeze({
  small: 300,
  large: 600,
});

const EditorialPushList = ({ items }) => {
  const [inView, setInView] = useState(false);

  const pathRef = useRef(null);
  const container = useRef(null);
  const firstPush = useRef(null);

  const [pathLength, setPathLength] = useState(0); // used to update
  const [pathData, setPathData] = useState(""); // used to store the path data
  const [padding, setPadding] = useState(PUSH_PADDING.small);
  const [viewportWidth, setViewportWidth] = useState(0);
  const [curveHandleOffsetX, setCurveHandleOffsetX] = useState(
    HANDLE_OFFSET.small
  ); // used to know how far from a point its handles are
  const [mobileCurve, setMobileCurve] = useState(true);
  const [visibleLine, setVisibleLine] = useState(0); // used to hide the line while updating it
  const [scrollPercentage, setScrollPercentage] = useState(0); // used to update path's dash-offset value

  /******************/
  /*    STEP ONE    */
  /******************/
  // Handles the resize listeners
  // Gets called on componentDidMount
  useEffect(() => {
    const updateViewportWidthAfterResize = () => {
      setViewportWidth(getViewport().width);
    };

    const hideSvg = () => {
      setVisibleLine(0);
    };

    const onResizeEnd = debounce(updateViewportWidthAfterResize, 250);
    const onResizeStart = debounce(hideSvg, 250, true);

    window.addEventListener("resize", onResizeStart, false);
    window.addEventListener("resize", onResizeEnd, false);
    updateViewportWidthAfterResize(); // Call immediatly for initial path generation

    return () => {
      window.removeEventListener("resize", onResizeStart, false);
      window.removeEventListener("resize", onResizeEnd, false);
    };
  }, []);

  /******************/
  /*    STEP TWO    */
  /******************/
  // Handles the SVG line generation
  // Gets called on componentDidMount and when padding or viewportWidth got updated
  useEffect(() => {
    const pointX = viewportWidth / 2; // Base x position for the points
    const startY = firstPush.current.offsetHeight - padding; // Initial point is positionned under the first push minus the current padding value

    // using two variables here because it's not used the same way
    const desktopOffsetFromStartY = mobileCurve ? 0 : 50;
    const mobileOffsetFromStartY = mobileCurve ? -200 : 0;

    const containerHeight = container.current.offsetHeight;

    // This is the height of the svg from 1st point to the 2nd to last
    const totalCurvePathHeight =
      containerHeight -
      startY -
      PUSH_PADDING.large +
      desktopOffsetFromStartY +
      mobileOffsetFromStartY;

    //get the curve height from the total height and the number of items
    const curveHeight = totalCurvePathHeight / (items.length - 1);

    // divide the curve height to position the handles later on
    const divider = 3;
    const dividedCurveHeight = curveHeight / divider;

    // Generate path data for all points except the last one
    const curveData = items.reduce((acc, item, key) => {
      // Handle first point differently since it's the first
      if (key === 0) {
        return `M ${pointX} ${startY + desktopOffsetFromStartY} `;
      }

      const isOdd = key % 2;

      // point Y is based on the current key + the starting point's offset
      const pointY = curveHeight * key + startY;

      // decide on which side the handles are going to be based on the key's odd/even value
      const handleSide = isOdd
        ? pointX - curveHandleOffsetX
        : pointX + curveHandleOffsetX;

      // Generate first handle
      const firstHandle = `${handleSide} ${
        pointY - curveHeight + dividedCurveHeight
      }`;

      // Generate second handle
      const secondHandle = `${handleSide} ${
        pointY - curveHeight + dividedCurveHeight * (divider - 1)
      }`;

      const lastCurvePointOffsetX = isOdd ? -100 : 100;

      const curvePointX =
        key === items.length - 1 ? pointX + lastCurvePointOffsetX : pointX;
      const curvePointY =
        key === items.length - 1 ? pointY - desktopOffsetFromStartY : pointY;

      // Generate point
      const point = `${curvePointX} ${curvePointY}`;

      return acc + `C ${firstHandle} ${secondHandle} ${point} `;
    }, "");

    // Update pathData
    setPathData(
      `${curveData} C ${pointX - (items.length % 2 ? 15 : -15)} ${
        containerHeight - padding / 1.55
      } ${pointX} ${containerHeight} ${pointX} ${containerHeight}`
    );
  }, [, padding, viewportWidth]);

  /******************/
  /*   STEP THREE   */
  /******************/
  // Updates path's total length after an update
  // Gets called when the pathData is updated
  useEffect(() => {
    const length = pathRef.current.getTotalLength();
    setPathLength(length);
    pathRef.current.style.strokeDasharray = length;
    setVisibleLine(1);
  }, [pathData]);

  /*****************/
  /*   STEP FOUR   */
  /*****************/
  // Handles the percentage progression
  // Gets called when the list comes into the viewport
  useEffect(() => {
    const onScroll = () => {
      if (!container.current) return;
      window.requestAnimationFrame(() => {
        if (!container.current || !firstPush.current) return;
        const scrollPosition = window.pageYOffset;
        const { height: windowHeight } = getViewport();
        const { top } = offsetRelative(container.current);
        const containerHeight = container.current.offsetHeight;

        const start = top + firstPush.current.offsetHeight / 3;
        const end = containerHeight - windowHeight;
        const trigger = start;
        const engaged = Math.max(0, scrollPosition - trigger);
        const progress = Math.min(1, engaged / end);

        setScrollPercentage(progress);
      });
    };

    const onScrollThrottle = throttle(onScroll, 50);
    if (inView) {
      window.addEventListener("scroll", onScrollThrottle, false);
      onScroll();
    } else {
      window.removeEventListener("scroll", onScrollThrottle, false);
    }

    return () => window.removeEventListener("scroll", onScrollThrottle, false);
  }, [inView]);

  /*****************/
  /*   STEP FIVE   */
  /*****************/
  // set svg path's dash-offset value (explanation : https://css-tricks.com/almanac/properties/s/stroke-dashoffset/)
  // gets called whenever scrollPercentage or pathLength is updated
  useEffect(() => {
    pathRef.current.style.strokeDashoffset =
      pathLength - pathLength * scrollPercentage;
  }, [scrollPercentage, pathLength]);

  return (
    <InView
      onChange={(visible) => {
        setInView(visible);
      }}
    >
      <PushsContainer ref={container}>
        <Animation visible={visibleLine}>
          <path
            ref={pathRef}
            fill="none"
            stroke={colors.activiaGreen}
            strokeWidth="4"
            d={pathData}
          />
        </Animation>
        {items.map(({ doc, cards, background }, key) => {
          return (
            cards && (
              <StyledEditorialPush
                ref={key === 0 ? firstPush : undefined}
                key={`${cards[0].id}-${key}`}
                text={doc}
                cards={cards}
                background={background}
              />
            )
          );
        })}
        <ScreenClass
          render={(screen) => {
            if (["md", "lg", "xl"].includes(screen)) {
              setMobileCurve(false);
              setPadding(PUSH_PADDING.large);
              setCurveHandleOffsetX(HANDLE_OFFSET.large);
            } else {
              setMobileCurve(true);
              setPadding(PUSH_PADDING.small);
              setCurveHandleOffsetX(HANDLE_OFFSET.small);
            }
          }}
        />
      </PushsContainer>
    </InView>
  );
};

EditorialPushList.propTypes = {
  items: PropTypes.array.isRequired,
};

const PushsContainer = styled.div`
  position: relative;
`;

const StyledEditorialPush = styled(EditorialPush)`
  &:nth-child(2) {
    padding-top: ${rem(PUSH_PADDING.small)};
  }
  &:last-child {
    padding: ${rem(PUSH_PADDING.large)} 0;
  }
`;

const Animation = styled.svg`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 0;
  opacity: ${({ visible }) => visible};
  transition: ${({ visible }) => `opacity 300ms ${visible ? "300ms" : "0ms"}`};

  path {
    transition: stroke-dashoffset 100ms linear;
  }
`;

export default EditorialPushList;
