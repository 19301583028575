import React from "react";
import styled from "styled-components";

export default (SVG) =>
  React.memo((props) => (
    <Outer {...props}>
      <SVG {...props} />
    </Outer>
  ));

const Outer = styled.span`
  display: ${({ display }) => display || "inline-block"};
  width: ${({ width, size }) => width || size};
  height: ${({ height, size }) => height || size};
  position: relative;

  path {
    fill: ${({ fill }) => fill};
    stroke: ${({ stroke }) => stroke};
  }
`;
