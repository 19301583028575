import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Visible } from "react-awesome-styled-grid";

import { colors, mediaquery } from "src/styles/variables";
import LatestCardsProvider from "src/stores/LatestCards";
import Card, { DIMENSIONS } from "src/molecules/Card";
import TheGutLifeWelcome from "src/molecules/TheGutLifeWelcome";

const WIDTH = 21; // in cards - must be odd (so that the mobile view's Welcome card is centred, desktop's one is double-width so pushes half a card out left+right)
const HEIGHT = 3; // in cards

const TheGutLifePreview = ({
  welcome,
  backgroundStyle,
  cards = [],
  fill = true,
  className,
  ...rest
}) => {
  const latestCards = useContext(LatestCardsProvider);

  const allCards = fill
    ? Array.from(new Array(4 - cards.length)).reduce(
        (fillCards) => {
          const usedIds =
            (fillCards.length && [...fillCards.map(({ id }) => id)]) || [];
          fillCards.push(
            latestCards.find((card) => !usedIds.includes(card.id))
          );
          return fillCards;
        },
        [...cards]
      )
    : cards;

  const cardShellClass = backgroundStyle === "Solid" ? "hide-card" : "";

  return (
    <Outer
      className={className}
      {...rest}
      as="section"
      backgroundStyle={backgroundStyle}
    >
      <Inner backgroundStyle={backgroundStyle}>
        {Array.from(new Array(HEIGHT)).map((_, rowKey) => (
          <Row key={rowKey}>
            {Array.from(new Array(WIDTH)).map((__, cellKey) => (
              <Cell key={cellKey}>
                {rowKey === Math.floor(HEIGHT / 2) &&
                cellKey === Math.floor(WIDTH / 2) ? (
                  <>
                    {!!allCards[2] && (
                      <Visible xl>
                        <CardStyled {...allCards[2]} cardHighlighted={false} />
                      </Visible>
                    )}
                    {!!allCards[0] && (
                      <Visible lg xl>
                        <CardStyled {...allCards[0]} cardHighlighted={false} />
                      </Visible>
                    )}
                    <TheGutLifeWelcomeStyled {...welcome} />
                    {!!allCards[1] && (
                      <Visible lg xl>
                        <CardStyled {...allCards[1]} cardHighlighted={false} />
                      </Visible>
                    )}
                    {!!allCards[3] && (
                      <Visible xl>
                        <CardStyled {...allCards[3]} cardHighlighted={false} />
                      </Visible>
                    )}
                  </>
                ) : (
                  <CardStyled
                    className={cardShellClass}
                    backgroundStyle={backgroundStyle}
                  />
                )}
              </Cell>
            ))}
          </Row>
        ))}
      </Inner>
    </Outer>
  );
};

TheGutLifePreview.propTypes = {
  welcome: PropTypes.shape(TheGutLifeWelcome.propTypes),
  backgroundStyle: PropTypes.string,
  cards: PropTypes.arrayOf(PropTypes.shape(Card.propTypes)),
  fill: PropTypes.bool,
  className: PropTypes.string,
};

const gradient = (side) => `
  content: "";
  position: absolute;
  z-index: 1;
  left: 0;
  right: 0;
  pointer-events: none;
  height: ${DIMENSIONS.height + DIMENSIONS.gutter}px;
  ${side}: 0;
  background-image: linear-gradient(
      to ${side},
      ${colors.cardFadeGradient[1]} 0%,
      ${colors.cardFadeGradient[0]} 45%
    );

    ${mediaquery.lg(css`
      background-image: ${({ backgroundStyle }) =>
        backgroundStyle === "Solid"
          ? "none"
          : `linear-gradient(
      to ${side},
      ${colors.cardFadeGradient[1]} 0%,
      ${colors.cardFadeGradient[0]} 45%
    );`};
    `)}
`;

const Outer = styled.div`
  position: relative;
  overflow: hidden;
  height: ${DIMENSIONS.height * (HEIGHT - 1) + DIMENSIONS.gutter * HEIGHT}px;

  ${mediaquery.lg(css`
    ${({ backgroundStyle }) =>
      backgroundStyle === "Solid"
        ? `
    height: ${
      DIMENSIONS.height * (HEIGHT - 1) + DIMENSIONS.gutter * HEIGHT - 250
    }px;
    margin-top: 5rem;
    `
        : null}
  `)}
`;

const Inner = styled.div`
  position: absolute;
  height: 100%;
  top: -${DIMENSIONS.height / 2}px;
  left: 50%;
  transform: translateX(-50%);

  ${mediaquery.lg(css`
    ${({ backgroundStyle }) =>
      backgroundStyle === "Solid"
        ? `
    top: -${DIMENSIONS.height / 2 + 125}px;
    `
        : null}
  `)}
`;

const Row = styled.div`
  position: relative;
  display: flex;
  &:first-child:before {
    ${gradient("top")}
  }
  &:last-child:after {
    ${gradient("bottom")}
  }
`;

const Cell = styled.div`
  display: flex;
`;

const CardStyled = styled(Card)`
  margin: ${DIMENSIONS.gutter / 2}px;
  visibility: visible;
  opacity: 1;

  ${mediaquery.lg(css`
    &.hide-card {
      visibility: hidden;
      opacity: 0;
    }
  `)}
`;

const TheGutLifeWelcomeStyled = styled(TheGutLifeWelcome)`
  position: relative;
  z-index: 1;
  margin: ${DIMENSIONS.gutter / 2}px;
`;

export default TheGutLifePreview;
