import React from "react";

import SVG from "../SVG";

export default SVG(() => (
  <svg viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 16L6.153 9.977A1 1 0 0 1 6.13 8.61L6.682 8 12 13.478 17.318 8l.552.61a1 1 0 0 1-.023 1.367L12 16z"
      fill="#333"
      fillOpacity="0.4"
    />
  </svg>
));
