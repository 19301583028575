import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { colors, rem, lineHeight, fontWeights } from "src/styles/variables";

const Checkbox = React.memo(({ id, label, align, setIsChecked }) => {
  const checkboxChange = (e) => {
    setIsChecked(e.target.checked);
  };
  return (
    <Outer align={align}>
      <input
        type="checkbox"
        id={id}
        name={id}
        onChange={(e) => checkboxChange(e)}
      />
      <label htmlFor={id}>{label}</label>
    </Outer>
  );
});

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  align: PropTypes.string,
  setIsChecked: PropTypes.func.isRequired,
};

const Outer = styled.div`
  display: flex;

  input {
    appearance: none;
    opacity: 0;
    visibility: hidden;
    position: absolute;
    z-index: -10;
    width: 0;
    height: 0;
    border: 0;

    &:checked + label {
      &:before {
        background: ${colors.activiaGreen};
      }

      &:after {
        opacity: 1;
      }
    }
  }

  label {
    display: flex;
    position: relative;
    color: ${colors.mediumGreen};
    font-size: ${rem(16)};
    line-height: ${lineHeight(16, 30)};
    font-weight: ${fontWeights.book};
    cursor: pointer;

    &:before,
    &:after {
      content: "";
      transition: all 0.3s;
    }

    &:before {
      width: 20px;
      height: 20px;
      border: 2px solid ${colors.activiaGreen};
      border-radius: 6px;
      margin-top: 2px;
      background: ${colors.white};
      flex: 0 0 auto;
      margin-right: 10px;
    }

    &:after {
      position: absolute;
      top: 9px;
      left: 5px;
      width: 11px;
      height: 5px;
      border-bottom: 3px solid ${colors.white};
      border-left: 3px solid ${colors.white};
      transform: rotate(-45deg);
      opacity: 0;
    }
  }
`;

export default Checkbox;
