export const HOME_LINK = {
  label: "Home",
  title: "Click here to go to the homepage",
  url: "/",
  target: "_self",
  id: "header-link-home",
};

export const MOBILE_MENU_ANIMATION_DURATION = 750;
export const BEZIER = "cubic-bezier(0.785, 0.135, 0.15, 0.86)";

export const HEADER_HEIGHT = Object.freeze({
  mobile: 73,
  desktop: 93,
});
