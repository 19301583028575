import React from "react";

import SVG from "../SVG";

export default SVG(() => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 15 15">
    <path
      stroke="#fff"
      strokeLinecap="round"
      d="M9.667.667v8.11a5 5 0 01-5 5H1"
    />
    <path
      stroke="#fff"
      strokeLinecap="round"
      d="M5.333 4.556L8.96.929a1 1 0 011.414 0L14 4.556"
    />
  </svg>
));
