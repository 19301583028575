import styled from "styled-components";

import { spacing } from "src/styles/variables";

const Spacer = styled.div`
  width: 100%;
  height: ${spacing.default.xl};
`;

export default Spacer;
