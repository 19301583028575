import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import PageContext from "src/stores/Page";

import { colors } from "src/styles/variables";
import GlobalProvider from "src/styles/GlobalProvider";
import Header from "src/organisms/Header";
import Footer from "src/organisms/Footer";
import PopupsList from "src/organisms/PopupsList";
import CardNavigationTransition from "src/atoms/CardNavigationTransition";

import useScrollDirection, { DIRECTIONS } from "src/hooks/scrollDirection";

const Layout = ({
  children,
  header = true,
  footer = true,
  popupsList = true,
  hideHeaderOnScroll = true,
}) => {
  const {
    transparentHeader: [transparentHeader],
    visibleHeader: [visibleHeader, setVisibleHeader],
    forceVisibleHeader: [forceVisibleHeader],
    cardNavigating: [cardNavigating],
  } = useContext(PageContext);

  let scrollDir;

  if (!!header && hideHeaderOnScroll) {
    scrollDir = useScrollDirection({
      initialDirection: DIRECTIONS.up,
      thresholdPixels: 10,
      scrollThrottle: 100,
    });

    useEffect(() => {
      setVisibleHeader(scrollDir === DIRECTIONS.up);
    }, [scrollDir]);
  }

  return (
    <GlobalProvider>
      <>
        {!!header && (
          <StyledHeader
            green={!transparentHeader}
            visible={visibleHeader || forceVisibleHeader}
          />
        )}
        <main>{children}</main>
        {!!footer && <Footer />}
        {!!cardNavigating && (
          <CardNavigationTransition
            top={cardNavigating.top}
            left={cardNavigating.left}
          />
        )}
        {!!popupsList && <PopupsList />}
      </>
    </GlobalProvider>
  );
};

const StyledHeader = styled(Header)`
  position: fixed;
  z-index: 4;
  width: 100%;
  transition: transform 300ms ease-in-out;
  ${({ visible }) =>
    !visible &&
    css`
      transform: translateY(-150%);
    `}

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 150px;
    background: linear-gradient(
      to bottom,
      ${colors.mediumShadowGradient[0]},
      ${colors.mediumShadowGradient[1]}
    );
    opacity: ${({ green }) => (green ? "0" : "1")};
    transition: opacity 300ms;
    pointer-events: none;
  }
`;

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  header: PropTypes.bool,
  footer: PropTypes.bool,
  popupsList: PropTypes.bool,
  hideHeaderOnScroll: PropTypes.bool,
};

export default Layout;
