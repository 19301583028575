import PropTypes from "prop-types";

export const Category = {
  id: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  slug: PropTypes.string,
  categorylist: PropTypes.object,
};

export const Subcategory = {
  id: PropTypes.string,
  title: PropTypes.string,
  slug: PropTypes.string,
};

export const Flavor = {
  id: PropTypes.string,
  slug: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
};
