import React from "react";
import PropTypes from "prop-types";

import { spacingValues, rem } from "src/styles/variables";

const NegativeSpacer = ({ children }) => {
  return (
    <div style={{ marginTop: `-${rem(spacingValues.xl * 2)}` }}>{children}</div>
  );
};

NegativeSpacer.propTypes = {
  children: PropTypes.node,
};

export default NegativeSpacer;
