import React, { useState, useEffect, createContext } from "react";
import PropTypes from "prop-types";

const Context = createContext();

// https://codeburst.io/the-only-way-to-detect-touch-with-javascript-7791a3346685
export const TouchStore = ({ children }) => {
  const [isTouch, setIsTouch] = useState(false);
  useEffect(() => {
    if (isTouch) return;

    const handleTouchStart = () => setIsTouch(true);

    window.addEventListener("touchstart", handleTouchStart, false);
    return () =>
      window.removeEventListener("touchstart", handleTouchStart, false);
  });

  return <Context.Provider value={isTouch}>{children}</Context.Provider>;
};

TouchStore.propTypes = {
  children: PropTypes.node,
};

export default Context;
