import React from "react";
import PropTypes from "prop-types";

import styled, { css } from "styled-components";
import { Container, Col, Row } from "react-awesome-styled-grid";
import { HeaderL, HeaderS, LabelL } from "src/atoms/Typography";
import { fontWeights, spacing, mediaquery } from "src/styles/variables";
import HeaderCurved from "src/atoms/HeaderCurved/HeaderCurved";

import Picture, { FITS } from "src/atoms/Picture";

const TrackerCategories = ({
  title,
  subtitle,
  description,
  imageMobile,
  imageDesktop,
  icon,
  backgroundColor,
  slug,
  children,
}) => {
  return (
    <>
      <PictureWrapper
        fit={FITS.cover}
        small={imageMobile}
        large={imageDesktop}
      />
      <HeaderCurvedStyled fixedHeight={false} backgroundColor={backgroundColor}>
        <CategoryWrapper className="category-wrapper">
          <RowStyled as="section">
            <Col md={10} offset={{ md: 3 }}>
              {title && <CategoryHeader as="h1">{title}</CategoryHeader>}
              {subtitle && (
                <CategorySubtitle as="h3">{subtitle}</CategorySubtitle>
              )}
              {description && (
                <CategoryDescription>{description}</CategoryDescription>
              )}
            </Col>
          </RowStyled>
        </CategoryWrapper>
      </HeaderCurvedStyled>
      {children}
      {icon}
    </>
  );
};

TrackerCategories.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  imageMobile: PropTypes.object,
  imageDesktop: PropTypes.object,
  icon: PropTypes.object,
  backgroundColor: PropTypes.string.isRequired,
  slug: PropTypes.string,
  children: PropTypes.node,
};

export default TrackerCategories;

const RowStyled = styled(Row)`
  margin: ${spacing.stack.xl};
`;

const HeaderCurvedStyled = styled(HeaderCurved)`
  margin-top: -100px;

  & > div {
    min-height: 0;
    padding-top: calc(2rem + 100px);
  }
`;

const CategoryWrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  flex: 0 0 100%;
`;

const CategoryHeader = styled(HeaderL)`
  padding: 1rem 0;

  ${mediaquery.md(css`
    padding: 1.5rem 0;
  `)}
`;

const CategorySubtitle = styled(HeaderS)`
  margin-bottom: 1rem;

  ${mediaquery.md(css`
    margin-bottom: 1.5rem;
  `)}
`;

const CategoryDescription = styled(LabelL)`
  font-weight: ${fontWeights.book};
`;

const PictureWrapper = styled(Picture)`
  position: absolute !important;
`;
