import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Container, Row, Col } from "react-awesome-styled-grid";

import { spacing } from "src/styles/variables";
import RichText from "src/atoms/RichText";

const TwoColumns = ({ left, leftAlignment, right, rightAlignment }) => {
  return (
    <Container>
      <RowStyled>
        {[
          { modules: left, offset: 5, alignment: leftAlignment },
          { modules: right, offset: 0, alignment: rightAlignment },
        ].map(({ modules, offset, alignment }, key) => (
          <Col key={key} xs={8} md={3} offset={{ md: offset }}>
            {modules.map((markdown, key_) => (
              <RichText
                key={key_}
                enableRenderer
                doc={markdown}
                alignment={alignment}
              />
            ))}
          </Col>
        ))}
      </RowStyled>
    </Container>
  );
};

TwoColumns.propTypes = {
  left: PropTypes.arrayOf(PropTypes.object).isRequired,
  leftAlignment: PropTypes.string,
  right: PropTypes.arrayOf(PropTypes.object).isRequired,
  rightAlignment: PropTypes.string,
};

const RowStyled = styled(Row)`
  /* use a spacer instead, so that RichText+TwoColumns can exist space-free */
  /* margin: ${spacing.stack.xl}; */
`;

export default TwoColumns;
