import React from "react";
import ProductFlavorsCardsList from "./ProductFlavorsCardsList";
import Spacer from "src/organisms/PageComponentList/Spacer";

const PageComponent = (props) => (
  <>
    <ProductFlavorsCardsList {...props} />
    <Spacer />
  </>
);

export default PageComponent;
