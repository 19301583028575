import React, { createRef, useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import debounce from "src/utils/debounce";
import getLocalizedSlug from "src/utils/getLocalizedSlug";
import Settings from "src/stores/Settings";
import { navigate } from "gatsby";

import { colors, spacing, mediaquery, icons } from "src/styles/variables";
import Picture, { FITS } from "src/atoms/Picture";
import FloatingButton from "src/molecules/FloatingButton";
import ChevronLeft from "src/atoms/Vectors/Chevrons/ChevronLeft";
import ChevronRight from "src/atoms/Vectors/Chevrons/ChevronRight";

const ANIMATION_DURATION = 750;
const HOVER_ANIMATION_DURATION = 400;
const THUMBNAILS_HEIGHT = 40;
const ProductFlavorsCarousel = ({
  flavorsList,
  isThumbnailsCarousel,
  isFlavorCardHover,
  currentFlavorIdx,
  setCurrentFlavorIdx,
}) => {
  const { locale } = useContext(Settings);
  const isTouch = false; //useContext(TouchProvider);
  const [windowWidth, setWindowWidth] = useState(0);
  const [ready, setReady] = useState(false);
  const [leftPadding, setLeftPadding] = useState(0);
  const [rightPadding, setRightPadding] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [needsArrows, setNeedsArrows] = useState(true);
  const [[showLeftArrow, showRightArrow], setShowArrows] = useState([
    false,
    true,
  ]);
  const [isLargeScreen, setIsLargeScreen] = useState(false);
  const [cardOuterWidth, setCardOuterWidth] = useState(0);
  const position = createRef();
  const outer = createRef();
  const inner = createRef();

  const containersMobileHeight = isThumbnailsCarousel
    ? `calc(${THUMBNAILS_HEIGHT}px + ${spacing.default.sm})`
    : "100%";
  const containersDesktopHeight = isThumbnailsCarousel
    ? `calc(${THUMBNAILS_HEIGHT}px + ${spacing.default.sm})`
    : isFlavorCardHover
    ? "70%"
    : "100%";

  const doChecks = () => {
    if (
      !outer.current ||
      !outer.current.offsetParent ||
      !position.current ||
      !inner.current
    ) {
      return;
    }

    const {
      left: parentLeft,
      width: parentWidth,
    } = outer.current.offsetParent.getBoundingClientRect();
    const {
      left: positionLeft,
      width: positionWidth,
    } = position.current.getBoundingClientRect();

    const leftPadding = positionLeft - parentLeft;
    const rightPadding =
      parentLeft + parentWidth - (positionLeft + positionWidth);

    const { width: outerWidth } = outer.current.getBoundingClientRect();
    const { width: innerWidth } = inner.current.getBoundingClientRect();

    setCardOuterWidth(outerWidth);
    setLeftPadding(leftPadding);
    setRightPadding(rightPadding);
    setNeedsArrows(outerWidth < innerWidth);
    setIsLargeScreen(windowWidth >= 768);
    setReady(true);
  };

  useEffect(() => {
    setWindowWidth(window.innerWidth);
    window.addEventListener(
      "resize",
      () => {
        setWindowWidth(window.innerWidth);
      },
      false
    );
  }, []);

  useEffect(() => {
    doChecks();
    const debouncedDoChecks = debounce(doChecks, 250);
    window.addEventListener("resize", debouncedDoChecks, false);
    return () => window.removeEventListener("resize", debouncedDoChecks, false);
  }, [windowWidth]);

  useEffect(() => {
    slideToKey(currentFlavorIdx);
  }, [currentFlavorIdx]);

  const getPositionLeft = () => {
    const { left } = position.current.getBoundingClientRect();
    return left;
  };
  const getLeftPad = () => {
    const absoluteLeft = getPositionLeft();
    const {
      left: parentLeft,
    } = outer.current.offsetParent.getBoundingClientRect();

    return absoluteLeft - parentLeft;
  };

  const scrollTo = (x, constrain = true) => {
    const min = 0;
    const max = inner.current.clientWidth - outer.current.clientWidth;
    const constrained = constrain ? Math.min(Math.max(0, x), max) : x;
    setScrollPosition(constrained);
    setShowArrows([min < constrained, constrained < max]);
  };

  const getItemElements = () => {
    const children = (inner.current || {}).children || [];
    return Reflect.apply(Array.prototype.slice, children, []);
  };

  const getIndexOfItemClosestToLeft = () => {
    const leftPad = getPositionLeft();
    const els = getItemElements();
    const sortedEls = els // sorted by closest to left hand side (ones that are beyond left will have negative vals)
      .map((el) => {
        const { x } = el.getBoundingClientRect();
        return {
          el,
          x: Math.abs(x - leftPad),
        };
      })
      .sort((a, b) => (a.x > b.x ? 1 : -1));
    const currentEl = sortedEls[0]["el"];
    const currentElKey = els.indexOf(currentEl);
    return currentElKey;
  };

  const slideToKey = (key) => {
    const els = getItemElements();
    const nextEl = els[key];
    const leftPad = getLeftPad();
    const nextOffsetLeft = key === 0 ? 0 : nextEl.offsetLeft - leftPad;
    if (!isThumbnailsCarousel) {
      setCurrentFlavorIdx(key);
    }
    scrollTo(nextOffsetLeft);
    // console.log("nextOffsetLeft: ", nextOffsetLeft);
  };

  const slide = (direction) => {
    const els = getItemElements();
    const currentElKey = getIndexOfItemClosestToLeft();
    const nextElKey = Math.max(
      0,
      Math.min(els.length - 1, currentElKey + direction)
    );
    slideToKey(nextElKey);
    if (!isThumbnailsCarousel) {
      setCurrentFlavorIdx(nextElKey);
    }
    // console.log(flavorsList[currentFlavorIdx]);
  };

  const navigateToFlavor = (flavorSlug) => {
    const { navigationHistory } = (history && history.state) || {};
    const splittedUrl = window.location.href.split("/");
    const currentSlug = getLocalizedSlug(
      `/${splittedUrl[splittedUrl.length - 2]}`,
      locale
    );

    navigate(`${flavorSlug}`, {
      state: {
        navigationHistory:
          !navigationHistory || navigationHistory.length === 1
            ? [currentSlug, flavorSlug]
            : [...navigationHistory, currentSlug, flavorSlug],
      },
    });
  };

  return (
    <>
      <Position
        ref={position}
        isthumbnailscarousel={isThumbnailsCarousel}
        isflavorcardhover={isFlavorCardHover}
        mobileheight={containersMobileHeight}
        desktopheight={containersDesktopHeight}
      >
        <Outer
          ref={outer}
          ready={ready}
          isTouch={isTouch}
          itemscount={flavorsList.length}
          isthumbnailscarousel={isThumbnailsCarousel}
          isflavorcardhover={isFlavorCardHover}
          mobileheight={containersMobileHeight}
          desktopheight={containersDesktopHeight}
        >
          <Inner
            ref={inner}
            itemscount={flavorsList.length}
            isTouch={isTouch}
            padding={[leftPadding, rightPadding]}
            scrollPosition={scrollPosition}
            needsArrows={needsArrows}
            isthumbnailscarousel={isThumbnailsCarousel}
            outerwidth={cardOuterWidth}
          >
            {flavorsList.map(
              (flavor, key) =>
                (isThumbnailsCarousel && (
                  <ThumbnailPictureContainer
                    key={key}
                    className={currentFlavorIdx === key ? "active" : ""}
                    isthumbnailscarousel={isThumbnailsCarousel}
                    itemscount={flavorsList.length}
                    outerwidth={cardOuterWidth}
                    color={flavor.color}
                    onClick={(e) => {
                      e.preventDefault();
                      setCurrentFlavorIdx(key);
                    }}
                  >
                    <Picture fit={FITS.contain} small={flavor.image.fluid} />
                  </ThumbnailPictureContainer>
                )) || (
                  <PictureContainer
                    key={key}
                    isthumbnailscarousel={isThumbnailsCarousel}
                    onClick={() => {
                      navigateToFlavor(flavor.slug);
                    }}
                  >
                    <Picture fit={FITS.contain} small={flavor.image.fluid} />
                  </PictureContainer>
                )
            )}
          </Inner>
          {((isThumbnailsCarousel && isLargeScreen) ||
            (!isThumbnailsCarousel && !isLargeScreen)) &&
            !!needsArrows &&
            !!showLeftArrow && (
              <LeftContainer left={leftPadding}>
                <FloatingButtonStyled>
                  <ChevronLeft
                    size={isLargeScreen ? icons.s : icons.m}
                    onClick={() => slide(-1)}
                  />
                </FloatingButtonStyled>
              </LeftContainer>
            )}
          {((isThumbnailsCarousel && isLargeScreen) ||
            (!isThumbnailsCarousel && !isLargeScreen)) &&
            !!needsArrows &&
            !!showRightArrow && (
              <RightContainer right={rightPadding}>
                <FloatingButtonStyled>
                  <ChevronRight
                    size={isLargeScreen ? icons.s : icons.m}
                    onClick={() => slide(+1)}
                  />
                </FloatingButtonStyled>
              </RightContainer>
            )}
        </Outer>
      </Position>
    </>
  );
};

ProductFlavorsCarousel.propTypes = {
  flavorsList: PropTypes.arrayOf(PropTypes.object),
  isThumbnailsCarousel: PropTypes.bool,
  isFlavorCardHover: PropTypes.bool,
  currentFlavorIdx: PropTypes.number,
  setCurrentFlavorIdx: PropTypes.func,
};

const Position = styled.div`
  position: static; /* important! this is used to determine where the alignment points are */
  width: 100%;
  height: ${({ mobileheight }) => mobileheight};
  margin: ${({ isthumbnailscarousel }) =>
    isthumbnailscarousel
      ? `calc(-${spacing.default.md} - ${THUMBNAILS_HEIGHT}px) 0 ${spacing.default.md}`
      : spacing.stack.md};
  transition: all ${HOVER_ANIMATION_DURATION / 1000}s;

  ${mediaquery.md(css`
    height: ${({ desktopheight }) => desktopheight};
    margin: ${({ isthumbnailscarousel }) =>
      isthumbnailscarousel
        ? `-${spacing.default.md} 0 ${spacing.default.md}`
        : spacing.stack.md};
  `)};
`;

const Outer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  overflow: ${({ isTouch }) => (isTouch ? "scroll" : "hidden")};
  width: 100%;
  height: ${({ mobileheight }) => mobileheight};

  opacity: ${({ ready }) => (ready ? 1 : 0)};
  transition: all ${HOVER_ANIMATION_DURATION / 1000}s;

  ${mediaquery.md(css`
    height: ${({ desktopheight }) => desktopheight};
  `)};
`;

const Inner = styled.div`
  width: ${({ itemscount, isthumbnailscarousel }) =>
    isthumbnailscarousel ? "100%" : `calc(100% * ${itemscount})`};
  height: 100%;
  position: absolute;
  z-index: 0;
  display: flex;
  justify-content: center;
  padding: ${({ padding: [paddingLeft, paddingRight] }) =>
    `0 ${paddingRight}px 0 ${paddingLeft}px`};

  ${({ isTouch }) =>
    !isTouch &&
    css`
      transform: translateX(-${({ scrollPosition }) => scrollPosition}px);
      transition: transform ${ANIMATION_DURATION / 1000}s;
    `}

  ${({ needsArrows }) =>
    !needsArrows &&
    css`
      left: 50%;
      transform: translateX(-50%);
      transition: none;
    `};

  ${mediaquery.md(css`
    width: ${({ itemscount, isthumbnailscarousel, outerwidth }) =>
      isthumbnailscarousel
        ? itemscount <= 4
          ? "100%"
          : `calc(100% * ${((outerwidth / 4) * itemscount) / outerwidth})`
        : `calc(100% * ${itemscount})`};
    justify-content: flex-start;
  `)}
`;

const ArrowContainer = styled.div`
  position: absolute;
  z-index: 2;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-50%);

  path {
    fill: ${colors.darkGrey};
    fill-opacity: 1;

    ${mediaquery.md(css`
      fill: ${colors.activiaMediumGreen};
    `)};
  }
`;

const FloatingButtonStyled = styled(FloatingButton)`
  width: 32px;
  height: 32px;
  background: none;
  box-shadow: none;

  ${mediaquery.md(css`
    width: 24px;
    height: 24px;
    margin-top: -12px;
    background: ${colors.white};
    box-shadow: 0 4px 10px ${colors.translucidBlack};
  `)};
`;
const LeftContainer = styled(ArrowContainer)`
  left: 10px;
`;
const RightContainer = styled(ArrowContainer)`
  right: 10px;
`;

const PictureContainer = styled.button`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .gatsby-image-wrapper {
    max-width: 90%;
    max-height: 90%;
  }
`;

const ThumbnailPictureContainer = styled.a`
  //width: ${({ outerwidth }) => `calc(1px * ${outerwidth / 4})`};
  width: 20px;
  flex: 0 0 auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  padding-bottom: ${spacing.default.sm};

  &:before {
    content: "";
    display: block;
    background: ${colors.white};
    border: 1px solid ${colors.mediumGrey};
    // background: ${({ color }) => color};
    width: 8px;
    height: 8px;
    border-radius: 100%;
    transition: all 0.2s;
  }

  &.active {
    &:before {
      background: ${({ color }) => color};
      border-color: ${({ color }) => color};
      // width: 14px;
      // height: 14px;
    }
  }

  ${mediaquery.md(css`
    width: 100%;
    flex: 1;

    &:before {
      position: absolute;
      left: 50%;
      right: 50%;
      bottom: 0;
      width: auto;
      height: 3px;
      opacity: 0;
      visibility: hidden;
      border-radius: 10px;
      border: 0;
    }

    &.active {
      &:before {
        opacity: 1;
        visibility: visible;
        left: 20%;
        right: 20%;
      }
    }
  `)};

  .gatsby-image-wrapper {
    max-width: 45px;
    max-height: 45px;
    display: none;

    ${mediaquery.md(css`
      display: block;
    `)};
  }
`;

export default ProductFlavorsCarousel;
