import { css } from "styled-components";

export default css`
  html {
    font-family: "Gotham", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    background: #ffffff;
  }

  button {
    font-family: inherit;
    border: 0 none;
    padding: 0;
    background: inherit;
  }

  a {
    color: inherit;
  }

  body.overflow-hidden {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    overflow: hidden;
  }

  .csWidgetModal .csWidget {
    border: 0 !important;
  }
`;
