import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Container, Row, Col } from "react-awesome-styled-grid";
import { Link as GatsbyLink } from "gatsby";

import Settings from "src/stores/Settings";
import PageContext from "src/stores/Page";
import getLocalizedSlug from "src/utils/getLocalizedSlug";

import GridPadding from "src/atoms/GridPadding";
import Picture, {
  TYPES as PICTURE_TYPES,
  FITS as PICTURE_FITS,
} from "src/atoms/Picture";
import Link, { TYPES } from "src/atoms/Link";
import { colors, spacing } from "src/styles/variables";
import {
  HOME_LINK,
  HEADER_HEIGHT,
  MOBILE_MENU_ANIMATION_DURATION,
  BEZIER,
} from "./constants";

const isMaintenance = process.env.GATSBY_MAINTENANCE === "true";

const Header = React.memo(
  ({ green, opened, setOpened, willClose, setWillClose }) => {
    const { headerLinks, headerLogo, locale } = useContext(Settings);

    const {
      headerHeight: [, setHeaderHeight],
    } = useContext(PageContext);

    if (headerLinks.length === 3) {
      headerLinks.unshift(HOME_LINK);
    }

    useEffect(() => {
      setHeaderHeight(HEADER_HEIGHT.mobile);
    }, []);

    return (
      <Contained transparent={!green} opened={willClose}>
        <LogoContainer visible={!willClose}>
          <Logo to={getLocalizedSlug("/", locale)}>
            <Picture
              type={PICTURE_TYPES.fixed}
              small={headerLogo}
              fit={PICTURE_FITS.contain}
            />
          </Logo>
        </LogoContainer>
        {!isMaintenance && (
          <BurgerContainer>
            <Burger
              opened={willClose}
              onClick={() => {
                setWillClose(!opened);
                setTimeout(
                  () => setOpened(!opened),
                  opened ? MOBILE_MENU_ANIMATION_DURATION : 0
                );
              }}
            >
              {Array.from(new Array(3)).map((line, key) => (
                <BurgerLine key={key} />
              ))}
            </Burger>
          </BurgerContainer>
        )}

        {!isMaintenance && opened && (
          <Container>
            <Row>
              <Col>
                <MobileLinks>
                  {headerLinks.map((headerLink) => (
                    <MobileLink key={`${headerLink.label}-${headerLink.url}`}>
                      <LinkStyled
                        activeClassName="active"
                        {...headerLink}
                        type={
                          headerLinks.length > 4
                            ? TYPES.headerMobileSmall
                            : TYPES.headerMobile
                        }
                      />
                    </MobileLink>
                  ))}
                </MobileLinks>
              </Col>
            </Row>
          </Container>
        )}
      </Contained>
    );
  }
);

Header.propTypes = {
  green: PropTypes.bool,
  opened: PropTypes.bool,
  setOpened: PropTypes.func,
  willClose: PropTypes.bool,
  setWillClose: PropTypes.func,
};

const transition = (boolean, property) => css`
  transition: ${property} ${MOBILE_MENU_ANIMATION_DURATION * 0.3}ms
    ${!boolean ? MOBILE_MENU_ANIMATION_DURATION * 0.7 : 0}ms ${BEZIER};
`;

const Contained = styled.div`
  width: 100vw;
  height: 100%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: linear-gradient(
      to top,
      ${colors.activiaGradient[0]} 0%,
      ${colors.activiaGradient[1]} 100%
    );
    opacity: ${({ transparent }) => (transparent ? "0" : "1")};
    transition: opacity 300ms;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background: ${colors.activiaGreen};
    opacity: ${({ opened }) => (opened ? "1" : "0")};
    ${({ opened }) => transition(opened, "opacity")}
  }
`;

/* Mobile components */
const MobileLinks = styled.ul`
  margin-top: ${spacing.default.lg};
`;

const MobileLink = styled.li`
  padding: ${spacing.stack.lg};

  &:last-child {
    padding: 0;
  }
`;

/* Common components */
const LinkStyled = styled(Link)`
  color: ${colors.white};
  text-decoration: none;
  line-height: 1.15;

  &.active {
    color: ${colors.mediumGreen};
  }
`;

const LogoContainer = styled.div`
  align-self: center;
  margin-top: 24px;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  ${({ visible }) => transition(!visible, "opacity")}
`;

const Logo = styled(GatsbyLink)`
  display: block;
  width: 134px;
  height: 34px;
`;

const BurgerContainer = styled(GridPadding)`
  position: absolute;
  top: 0;
  right: 0;
  height: ${HEADER_HEIGHT.mobile}px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* Not quite vertically centered, so adjusting */
  padding-top: 5px;
`;

const Burger = styled.button`
  width: 18px;
  height: 16px;
  outline: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${({ opened }) =>
    opened &&
    css`
      & ${BurgerLine}:nth-child(2) {
        opacity: 0;
      }

      & ${BurgerLine}:first-child, & ${BurgerLine}:last-child {
        border-radius: 1px;
      }

      & ${BurgerLine}:first-child {
        transform: translateY(7px) rotateZ(45deg);
      }

      & ${BurgerLine}:last-child {
        transform: translateY(-7px) rotateZ(-45deg);
      }
    `}
`;

const BurgerLine = styled.div`
  width: 18px;
  height: 2px;
  background: ${colors.white};
  border-radius: 0 0 2px 2px;

  &:first-child,
  &:last-child {
    transition: transform ${MOBILE_MENU_ANIMATION_DURATION / 2}ms,
      border-radius ${MOBILE_MENU_ANIMATION_DURATION / 2}ms,
      width ${MOBILE_MENU_ANIMATION_DURATION / 2}ms;
  }

  &:nth-child(2) {
    transition: opacity ${MOBILE_MENU_ANIMATION_DURATION / 2}ms;
  }
`;

export default Header;
