import React from "react";

import SVG from "../SVG";

export default SVG(() => (
  <svg viewBox="0 0 32 32" fill="none">
    <path
      d="M21.0438 11.0143L9.21563 22.9435"
      stroke="#02BA44"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M15.4515 9.53553L21.5225 9.53553C22.0748 9.53553 22.5225 9.98325 22.5225 10.5355L22.5225 16.6066"
      stroke="#02BA44"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M15.6066 23.234L9.53553 23.234C8.98325 23.234 8.53553 22.7863 8.53553 22.234L8.53553 16.1629"
      stroke="#02BA44"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
));
