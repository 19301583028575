import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { colors, spacing } from "src/styles/variables";
import { SubHeaderM } from "src/atoms/Typography";
import RichText from "src/atoms/RichText";

const QuestionAnswer = ({ question, answer, ...rest }) => {
  return (
    <div {...rest}>
      <Question as="h3">{question}</Question>
      <Answer>
        <RichText as="div" enableRenderer doc={answer} />
      </Answer>
    </div>
  );
};

QuestionAnswer.propTypes = {
  question: PropTypes.node.isRequired,
  answer: PropTypes.object.isRequired,
};

const Question = styled(SubHeaderM)`
  color: ${colors.mediumGreen};
  margin: ${spacing.stack.md};
`;
const Answer = styled.div`
  margin: ${spacing.stack.xl};
  p {
    color: ${colors.darkGrey};
  }
`;

export default QuestionAnswer;
