import React, { useContext } from "react";
import styled, { css, ThemeProvider } from "styled-components";
import PropTypes from "prop-types";
import NumericFact from "src/atoms/NumericFact";
import NumericFactList from "src/molecules/NumericFactList";
import Link, { TYPES } from "src/atoms/Link";
import Picture, {
  TYPES as PICTURE_TYPES,
  FITS as PICTURE_FITS,
} from "src/atoms/Picture";
import {
  mediaquery,
  colors,
  rem,
  spacing,
  paddingWidth,
} from "src/styles/variables";
import {
  HeaderS,
  IntroText,
  LinkText,
  Body,
  LabelL,
} from "src/atoms/Typography";
import ChevronRight from "src/atoms/Vectors/Chevrons/ChevronRight";
import { Container, Row, Col } from "react-awesome-styled-grid";
import Settings from "src/stores/Settings";

const influencerTheme = {
  numericFact: {
    circleBorderColor: colors.lightGrey,
    circleTextColor: colors.black,
    labelColor: colors.mediumGrey,
    margin: spacing.default.xs,
  },
};

const InfluencerPush = ({
  title,
  description,
  country,
  instagramUrl,
  instagramStats,
  ...rest
}) => {
  const { influencerPushCountry, influencerPushInstagramLink } = useContext(
    Settings
  ).translations;
  return (
    <ThemeProvider theme={influencerTheme}>
      <Wrapper {...rest}>
        <Row>
          <Col xs={8} md={6} lg={10} offset={{ md: 1, lg: 4 }}>
            <InstagramCard>
              <CountryWrapper>
                <CountryPlaceholder>{influencerPushCountry}</CountryPlaceholder>
                <FlagPlaceholder>
                  <Flag>
                    <Picture
                      type={PICTURE_TYPES.fixed}
                      fit={PICTURE_FITS.cover}
                      small={country.flag}
                    />
                  </Flag>
                  {country.name}
                </FlagPlaceholder>
              </CountryWrapper>
              <StatsWrapper>
                <NumericFactList facts={instagramStats} />
              </StatsWrapper>
            </InstagramCard>
          </Col>
          <Col xs={8} md={6} lg={8} offset={{ md: 1 }}>
            <Title as="h2">{title}</Title>
            <Description as="p">{description.description}</Description>
            <InstagramLink>
              <Link
                url={instagramUrl}
                target="_blank"
                title="See full Instagram profile"
                type={TYPES.link}
              >
                {influencerPushInstagramLink}{" "}
                <ChevronRight stroke={colors.activiaGreen} size="16px" />
              </Link>
            </InstagramLink>
          </Col>
        </Row>
      </Wrapper>
    </ThemeProvider>
  );
};

InfluencerPush.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.object.isRequired,
  country: PropTypes.shape({
    name: PropTypes.string.isRequired,
    flag: PropTypes.object.isRequired,
  }),
  instagramUrl: PropTypes.string.isRequired,
  instagramStats: PropTypes.arrayOf(PropTypes.shape(NumericFact.propTypes)),
};

const Wrapper = styled(Container)`
  padding: 0;
`;

const InstagramCard = styled.div`
  border: 1px solid ${colors.lightGrey};
  border-left-width: 0;
  border-right-width: 0;
  margin: ${spacing.stack.lg};
  margin-left: -${paddingWidth.sm}rem;
  margin-right: -${paddingWidth.sm}rem;
  ${mediaquery.md(css`
    border-left-width: 1px;
    border-right-width: 1px;
    border-radius: ${rem(10)};
    margin: 0;
  `)}
`;

const Title = styled(HeaderS)`
  color: ${colors.mediumGreen};
  margin: ${spacing.stack.lg};
`;

const Description = styled(IntroText)`
  color: ${colors.mediumGreen};
  margin: ${spacing.stack.lg};
`;

const InstagramLink = styled(LinkText)`
  color: ${colors.activiaGreen};
`;

const CountryWrapper = styled.div`
  display: flex;
  border-bottom: 1px solid ${colors.lightGrey};
`;

const CountryPlaceholder = styled(Body)`
  padding: ${spacing.default.md};
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid ${colors.lightGrey};
`;

const FlagPlaceholder = styled(LabelL)`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: ${spacing.default.md};
`;

const StatsWrapper = styled.div`
  padding: ${spacing.default.md};
`;

const Flag = styled.div`
  border-radius: 24px;
  margin-right: ${spacing.default.sm};
  width: 24px;
  height: 24px;
  overflow: hidden;
`;

export default InfluencerPush;
