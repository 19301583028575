import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { spacing } from "src/styles/variables";
import Chip from "src/atoms/Chip";

const ChipSelector = ({
  items,
  onChange = (v) => v,
  selectedChips = [],
  ...rest
}) => {
  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (selectedChips !== undefined) {
      setSelected(selectedChips);
    }
  }, []);

  const toggleItem = (key, toggle) => {
    let nextSelected;
    if (toggle) {
      nextSelected = [...selected, key];
    } else {
      nextSelected = selected.filter((thisKey) => thisKey !== key);
    }

    onChange(nextSelected, key);
    setSelected(nextSelected);
  };

  return (
    <div {...rest}>
      <Inner>
        {Object.values(items).map(({ key, label }) => {
          const active = !!selected.find((thisKey) => thisKey === key);
          return (
            <ChipStyled
              key={key}
              text={label}
              active={active}
              onClick={() => toggleItem(key, !active)}
            />
          );
        })}
      </Inner>
    </div>
  );
};

ChipSelector.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  onChange: PropTypes.func,
  selectedChips: PropTypes.array,
};

const Inner = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

const ChipStyled = styled(Chip)`
  margin: 0 ${spacing.default.xs} ${spacing.default.sm} 0;
  cursor: pointer;
  user-select: none;

  &:last-child {
    margin-right: 0;
  }
`;

export default ChipSelector;
