import React from "react";

import SVG from "../SVG";

export default SVG(() => (
  <svg viewBox="0 0 16 16" fill="none">
    <circle cx="1" cy="8" r="1" fill="#02BA44" />
    <circle cx="6" cy="8" r="1" fill="#02BA44" />
    <circle cx="11" cy="8" r="1" fill="#02BA44" />
    <circle cx="15" cy="8" r="1" fill="#02BA44" />
  </svg>
));
