import React from "react";
import styled from "styled-components";

import { spacing } from "src/styles/variables";

import Illustration from "./Illustration";

const PageComponent = (props) => <IllustrationStyled {...props} />;

const IllustrationStyled = styled(Illustration)`
  margin: ${spacing.stack.xl};
`;

export default PageComponent;
