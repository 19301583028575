import React from "react";

import SVG from "../SVG";

export default SVG(() => (
  <svg viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 11.5l6.023-5.847a1 1 0 0 1 1.368-.023l.609.552L9.522 11.5 15 16.818l-.61.552a1 1 0 0 1-1.367-.023L7 11.5z"
      fill="#333"
      fillOpacity="0.4"
    />
  </svg>
));
