import React from "react";
import { Container, Row, Col } from "react-awesome-styled-grid";
import styled from "styled-components";
import { spacing } from "src/styles/variables";
import InstagramImagesCarousel from "./InstagramImagesCarousel";
import Spacer from "src/organisms/PageComponentList/Spacer";

const PageComponent = (props) => (
  <Container>
    <Row>
      <ColStyled xs={8} md={12} offset={{ md: 2 }}>
        <InstagramImagesCarousel {...props} />
        <Spacer />
      </ColStyled>
    </Row>
  </Container>
);

const ColStyled = styled(Col)`
  margin: ${spacing.stack.xl};
  color: red;
`;

export default PageComponent;
