import React from "react";

import SVG from "../SVG";

export default SVG(() => (
  <svg viewBox="0 0 32 32" fill="none">
    <path
      d="M10 21.5l5.293-5.293a1 1 0 0 1 1.414 0L22 21.5m-12-12l5.293 5.293a1 1 0 0 0 1.414 0L22 9.5"
      stroke="#02BA44"
      strokeWidth={2}
      strokeLinecap="round"
    />
  </svg>
));
