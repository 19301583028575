import React from "react";
import PropTypes from "prop-types";

import SVG from "react-inlinesvg";
import styled, { css } from "styled-components";
import { colors, mediaquery } from "src/styles/variables";
import { HeaderS } from "src/atoms/Typography";
import DonutProgressBar from "src/atoms/DonutProgressBar/DonutProgressBar";

const TrackerPanel = ({ title, percent, backgroundColor, icon }) => {
  return (
    <>
      <TrackerPanelWrapper style={{ backgroundColor }}>
        <SVGWrapper>
          <DonutProgressBarStyles
            strokeColor={colors.white}
            percent={percent}
          />
          <Icon
            src={icon.file.url}
            width={20}
            height="initial"
            title="Tracker results icon"
          />
        </SVGWrapper>
        <TrackerPanelHeaderMedium as="h3">{title}</TrackerPanelHeaderMedium>
      </TrackerPanelWrapper>
    </>
  );
};

TrackerPanel.propTypes = {
  title: PropTypes.string.isRequired,
  backgroundColor: PropTypes.string.isRequired,
  percent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.object,
};

const TrackerPanelWrapper = styled.div`
  position: relative;
  border-radius: 30px;
  color: ${colors.white};
  margin-bottom: 1.5rem;
  padding: 2rem 1rem 1.5rem;
  text-align: center;
  box-shadow: 0 6px 10px 2px ${colors.translucidBlack};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;

  &:before {
    content: "...";
    font-family: monospace;
    color: rgba(0, 0, 0, 0.3);
    font-size: 90px;
    letter-spacing: -27px;
    line-height: 0;
    display: block;
    height: 20px;
    width: 100%;
    text-align: right;
    padding: 0 15px;
    box-sizing: border-box;
    margin: -25px 0 20px;
  }
`;

const TrackerPanelHeaderMedium = styled(HeaderS)`
  text-transform: uppercase;
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 0rem;

  ${mediaquery.md(css`
    font-size: 1.5rem;
    letter-spacing: 0rem;
  `)}

  ${mediaquery.lg(css`
    font-size: 2.5rem;
    letter-spacing: 0rem;
  `)}
`;

const SVGWrapper = styled.div`
  position: relative;
`;

const DonutProgressBarStyles = styled(DonutProgressBar)`
  .donut-text text {
    transform: translate(0, 9px);

    ${mediaquery.sm(css`
      transform: translate(0, 7px);
    `)}
  }
`;

const Icon = styled(SVG)`
  position: absolute;
  height: initial;
  top: 30%;
  width: 65px;
  max-width: 20%;
  left: 50%;
  transform: translateX(-50%);

  ${mediaquery.sm(css`
    top: 26%;
  `)}
`;

export default TrackerPanel;
