import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import Settings from "src/stores/Settings";
import { Container, Row, Col } from "react-awesome-styled-grid";
import {
  rem,
  lineHeight,
  colors,
  spacing,
  icons,
  mediaquery,
} from "src/styles/variables";
import RichText from "src/atoms/RichText";
import Picture, { FITS } from "src/atoms/Picture";
import Link, { TYPES } from "src/atoms/Link";
import Button from "src/atoms/Button";
import FloatingButton, { SIZES } from "src/molecules/FloatingButton";
import Play from "src/atoms/Vectors/Standard/Play";
import VideoModal from "src/molecules/VideoModal";

const MediaAndText = ({
  image,
  imageMobile,
  youtubeVideoId,
  sideMediaTitle,
  sideText,
  ctaList,
  mediaAndTextAlignment,
  isFullWidth,
}) => {
  const [visible, setVisible] = useState(false);
  const { videoPlayButtonLabel } = useContext(Settings).translations;
  const defaulvideoPlayButtonLabel = videoPlayButtonLabel || "Play video";
  const sideTextAlignment =
    sideText.alignment !== null ? sideText.alignment : "left";
  const componentAlignment =
    mediaAndTextAlignment !== null ? mediaAndTextAlignment : false;
  // console.log("mediaAndTextAlignment: ", mediaAndTextAlignment);
  // console.log("componentAlignment: ", componentAlignment);
  const position = sideText.alignment;
  return (
    <Outer as="section">
      {(isFullWidth && (
        <Row>
          <Col lg={26} offset={{ lg: 3 }}>
            <FullWidthWrapper componentAlignment={componentAlignment}>
              <SideTextContainer position={sideTextAlignment}>
                <RichTextStyled
                  enableRenderer
                  headColor={colors.white}
                  bodyColor={colors.white}
                  doc={sideText.value}
                  alignment={sideTextAlignment}
                />
                <CtasRow>
                  <CtasContainer position={position}>
                    {!!ctaList &&
                      ctaList.map((ctaItem, key) => (
                        <ButtonContainer position={position} key={key}>
                          <Link {...ctaItem} type={TYPES.button} />
                        </ButtonContainer>
                      ))}
                    {!!youtubeVideoId && (
                      <ButtonContainer position={position}>
                        <Button
                          buttonstyle="Light"
                          onClick={() => setVisible(true)}
                          title={defaulvideoPlayButtonLabel}
                        >
                          <span>
                            {defaulvideoPlayButtonLabel}
                            <ButtonPlay />
                          </span>
                        </Button>
                      </ButtonContainer>
                    )}
                  </CtasContainer>
                </CtasRow>
              </SideTextContainer>
              <CurvedContainer>
                <Curved componentAlignment={componentAlignment}>
                  <Contained>
                    <Picture
                      fit={FITS.cover}
                      small={imageMobile}
                      large={image}
                    />
                  </Contained>
                </Curved>
              </CurvedContainer>
            </FullWidthWrapper>
          </Col>
        </Row>
      )) || (
        <RowStyled componentAlignment={componentAlignment}>
          <ColStyled
            xs={8}
            md={7}
            xl={12}
            offset={{
              md: componentAlignment ? 1 : 0,
              xl: componentAlignment ? 1 : 5,
            }}
            align="flex-start"
            componentAlignment={componentAlignment}
          >
            <RichText
              as="div"
              enableRenderer
              doc={sideText.value}
              alignment={sideTextAlignment}
            />
            <Row>
              <CtasContainer position={position}>
                {!!ctaList &&
                  ctaList.map((ctaItem, key) => (
                    <ButtonContainer position={position} key={key}>
                      <Link {...ctaItem} type={TYPES.button} />
                    </ButtonContainer>
                  ))}
              </CtasContainer>
            </Row>
          </ColStyled>
          <ImageContainer
            xs={8}
            md={8}
            xl={10}
            offset={{
              md: componentAlignment ? 0 : 1,
              xl: componentAlignment ? 0 : 1,
            }}
            align="flex-start"
            noGutter
            componentAlignment={componentAlignment}
          >
            {!!sideMediaTitle && (
              <Container>
                <MediaTitle>{sideMediaTitle}</MediaTitle>
              </Container>
            )}
            <MediaContainer>
              <Picture fit={FITS.cover} small={imageMobile} large={image} />
              {!!youtubeVideoId && (
                <StyledFloatingButton
                  onClick={() => setVisible(true)}
                  size={SIZES.medium}
                >
                  <Play width={icons.xs} />
                </StyledFloatingButton>
              )}
            </MediaContainer>
          </ImageContainer>
        </RowStyled>
      )}
      {!!visible && (
        <VideoModal id={youtubeVideoId} onClose={() => setVisible(false)} />
      )}
    </Outer>
  );
};

MediaAndText.propTypes = {
  image: PropTypes.object.isRequired,
  imageMobile: PropTypes.object.isRequired,
  youtubeVideoId: PropTypes.string,
  sideMediaTitle: PropTypes.string,
  sideText: PropTypes.object,
  ctaList: PropTypes.arrayOf(PropTypes.object),
  mediaAndTextAlignment: PropTypes.bool,
  isFullWidth: PropTypes.bool,
};

const Outer = styled.div`
  padding-bottom: 0;
`;

const RowStyled = styled(Row)`
  align-items: center;
  flex-direction: ${({ componentAlignment }) =>
    componentAlignment ? "row" : "row-reverse"};
`;

const ImageContainer = styled(Col)`
  background: #fff;
  flex: 0 0 auto;
  order: 1;
  margin-bottom: ${spacing.default.xl};

  ${mediaquery.xs(css`
    padding: 0;
    max-width: 100vw;
    margin-left: -1.8rem;
    margin-right: -1.8rem;
  `)}

  ${mediaquery.md(css`
    padding: inherit;
    margin-left: ${({ componentAlignment }) =>
      componentAlignment ? "0" : "inherit"};
    margin-right: ${({ componentAlignment }) =>
      componentAlignment ? "inherit" : "0"};
    max-width: 50%;
    margin-bottom: 0px;
  `)}

  ${mediaquery.xl(css`
    padding: inherit;
    margin-left: ${({ componentAlignment }) =>
      componentAlignment ? "10%" : "inherit"};
    margin-right: ${({ componentAlignment }) =>
      componentAlignment ? "inherit" : "10%"};
    max-width: 40%;
  `)}
`;

const RichTextStyled = styled(RichText)`
  h1,
  h2 {
    font-size: ${rem(50)};
    line-height: 1;
    font-weight: 400;

    ${mediaquery.lg(css`
      font-size: ${rem(90)};
    `)}
  }

  h3,
  h4 {
    font-size: ${rem(18)};
    line-height: ${lineHeight(18, 20)};
    font-weight: 500;

    ${mediaquery.lg(css`
      font-size: ${rem(28)};
      line-height: ${lineHeight(28, 32)};
    `)}
  }

  p {
    font-size: ${rem(16)};
    line-height: ${lineHeight(16, 20)};
  }
`;

const MediaTitle = styled.p`
  width: 100%;
  font-size: ${rem(18)};
  line-height: ${lineHeight(18, 30)};
  text-align: center;
  margin: ${spacing.stack.md};
  color: ${colors.activiaGreen};
`;

const ColStyled = styled(Col)`
  flex: 0 0 auto;
  order: 2;

  ${mediaquery.md(css`
    margin-left: ${({ componentAlignment }) =>
      componentAlignment ? spacing.default.xl : 0};
    margin-right: ${({ componentAlignment }) =>
      componentAlignment ? 0 : spacing.default.xl};
  `)};
`;

const MediaContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 0;
  top: 0;
  left: 0;

  ${mediaquery.md(css`
    border-radius: 16px;
    overflow: hidden;
  `)}
`;

const StyledFloatingButton = styled(FloatingButton)`
  position: absolute;
  bottom: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(50%);
`;

const CtasRow = styled(Row)`
  flex: 0 0 auto;
`;

const CtasContainer = styled(Col)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 0;

  ${mediaquery.sm(css`
    flex-direction: row;
  `)}
  ${mediaquery.md(css`
    justify-content: ${({ position }) =>
      ({ center: "center", right: "flex-end", left: "flex-start" }[position] ||
      "flex-start")};
  `)}
`;

const ButtonContainer = styled.div`
  display: flex;
  margin: ${spacing.default.xs} 0 0;

  ${mediaquery.sm(css`
    margin: ${spacing.default.xs} ${spacing.default.xs} 0 0;
  `)}
  ${mediaquery.md(css`
    justify-content: ${({ position }) =>
      ({ center: "center", right: "flex-end", left: "flex-start" }[position] ||
      "flex-start")};
    margin-right: ${({ position }) =>
      ({ center: "calc(" + spacing.default.xs + " / 2)", right: "0" }[
        position
      ] || spacing.default.xs)};
    margin-left: ${({ position }) =>
      ({ center: "calc(" + spacing.default.xs + " / 2)", left: "0" }[
        position
      ] || spacing.default.xs)};
  `)}
`;

const ButtonPlay = styled(Play)`
  width: 1.3rem;
  height: 1.3rem;
  border: 2px solid ${colors.activiaMediumGreen};
  border-radius: 100%;
  font-size: 5px;
  justify-content: center;
  align-items: center;
  padding: 3px 3px 3px 4px;
  margin-left: 5px;

  path {
    fill: ${colors.activiaMediumGreen};
  }
`;

const FullWidthWrapper = styled.div`
  background: ${colors.activiaMediumGreen};
  min-height: 600px;
  position: relative;
  display: flex;
  flex-direction: column;

  ${mediaquery.md(css`
    flex-direction: ${({ componentAlignment }) =>
      componentAlignment ? "row" : "row-reverse"};
  `)};
`;

const SideTextContainer = styled.div`
  width: 100%;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ position }) =>
    ({ center: "center", right: "flex-end", left: "flex-start" }[position] ||
    "flex-start")};
  padding: ${spacing.default.lg} ${spacing.default.md};
  order: 2;

  ${mediaquery.md(css`
    width: 40%;
  `)};
`;

const CurvedContainer = styled.div`
  flex: 0 0 auto;
  position: relative;
  z-index: 0;
  overflow: hidden;
  flex: 1;
  order: 1;
`;

const Curved = styled.div`
  position: relative;
  background: ${colors.white};
  width: 120%;
  left: -10%;
  height: 400px;
  overflow: hidden;
  border-radius: 0 0 50% 50% / 0 0 30px 30px;

  ${mediaquery.md(css`
    width: 100%;
    top: -15%;
    left: 0;
    height: 130%;
    border-radius: ${({ componentAlignment }) =>
      componentAlignment
        ? "0 10vw 10vw 0 / 0 70% 70% 0"
        : "10vw 0 0 10vw / 70% 0 0 70%"};
  `)};
`;

const Contained = styled.div`
  width: calc(100% + 20px);
  height: 100%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

export default MediaAndText;
