import React from "react";
import { Container, Row } from "react-awesome-styled-grid";
import ImagePush from "./ImagePush";

const PageComponent = (props) => (
  <Container>
    <Row>
      <ImagePush {...props} />
    </Row>
  </Container>
);

export default PageComponent;
