import React from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "react-awesome-styled-grid";
import styled, { css } from "styled-components";

import { spacing, colors, mediaquery } from "src/styles/variables";
import {
  HeaderM,
  IntroText,
  SubHeaderS,
  Body,
  HeaderS,
} from "src/atoms/Typography";
import Picture, { FITS, TYPES } from "src/atoms/Picture";
import RichText from "src/atoms/RichText";

const RecipeDetails = ({
  title,
  servingsLabel,
  servingsAmount,
  servingSizeLabel,
  servingSizeAmount,
  preparationLabel,
  preparationTimeInMinutes,
  cookingLabel,
  cookingTimeInMinutes,
  minutesLabel,
  ingredientsLabel,
  ingredientsList,
  steps,
}) => {
  return (
    <>
      <Container>
        <RowStyled>
          <Col md={6} offset={{ md: 5 }}>
            <Title>{title}</Title>
            <InfoItems>
              <InfoItem as="li">
                {servingsLabel} {servingsAmount}
              </InfoItem>
              <InfoItem as="li">
                {servingSizeLabel} {servingSizeAmount}
              </InfoItem>
              {preparationLabel && preparationTimeInMinutes && (
                <InfoItem as="li">
                  {`${preparationLabel} ${preparationTimeInMinutes}${minutesLabel}`}
                </InfoItem>
              )}
              {cookingLabel && cookingTimeInMinutes && (
                <InfoItem as="li">
                  {`${cookingLabel} ${cookingTimeInMinutes}${minutesLabel}`}
                </InfoItem>
              )}
            </InfoItems>
            <IngredientsTitle>{ingredientsLabel}</IngredientsTitle>
            <ul>
              {ingredientsList.map((item) => (
                <Ingredient as="li">{item}</Ingredient>
              ))}
            </ul>
          </Col>
        </RowStyled>
      </Container>
      <Container>
        {steps.map(({ title, image, description }) => (
          <RowStyled key={title} align="flex-start">
            {image && (
              <ImageContainer
                xs={8}
                md={4}
                lg={8}
                offset={{ md: 4, lg: 8 }}
                align="flex-start"
                noGutter
              >
                <Image type={TYPES.fluid} fit={FITS.contain} small={image} />
              </ImageContainer>
            )}
            <Col
              xs={8}
              md={image ? 4 : 9}
              lg={image ? 8 : 17}
              offset={{ md: image ? 1 : 4, lg: image ? 1 : 8 }}
              align="flex-start"
            >
              <StepTitle as="h3">{title}</StepTitle>
              <RichText
                enableRenderer
                doc={description.doc}
                alignment={description.alignment}
              />
            </Col>
          </RowStyled>
        ))}
      </Container>
    </>
  );
};

const RowStyled = styled(Row)`
  margin: ${spacing.stack.xl};
`;

const Title = styled(HeaderM)`
  color: ${colors.darkGreen};
  margin: ${spacing.stack.md};
`;

const InfoItems = styled.ul`
  margin: ${spacing.stack.xl};
`;

const InfoItem = styled(IntroText)`
  color: ${colors.darkGreen};
`;

const IngredientsTitle = styled(SubHeaderS)`
  color: ${colors.darkGreen};
`;

const Ingredient = styled(Body)`
  color: ${colors.darkGreen};
  list-style: disc;
  margin-left: 18px;
`;

const StepTitle = styled(HeaderS)`
  color: ${colors.darkGreen};
  margin: ${spacing.stack.md};
`;

const ImageContainer = styled(Col)`
  ${mediaquery.xs(css`
    padding: 0;
    max-width: 100vw;
    margin-left: -1.8rem;
    margin-right: -1.8rem;
  `)}

  ${mediaquery.md(css`
    padding: inherit;
    margin-left: 25%;
    margin-right: inherit;
    max-width: 25%;
  `)}
`;

const Image = styled(Picture)`
  margin: ${spacing.stack.sm};
`;

RecipeDetails.propTypes = {
  title: PropTypes.string.isRequired,
  servingsLabel: PropTypes.string.isRequired,
  servingsAmount: PropTypes.string.isRequired,
  servingSizeLabel: PropTypes.string.isRequired,
  servingSizeAmount: PropTypes.string.isRequired,
  preparationLabel: PropTypes.string,
  preparationTimeInMinutes: PropTypes.number,
  cookingLabel: PropTypes.string,
  cookingTimeInMinutes: PropTypes.number,
  minutesLabel: PropTypes.string,
  ingredientsLabel: PropTypes.string,
  ingredientsList: PropTypes.arrayOf(PropTypes.string).isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      image: PropTypes.object,
      description: PropTypes.object,
    })
  ).isRequired,
};

export default RecipeDetails;
