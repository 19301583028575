import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

import { colors } from "src/styles/variables";

export const SIZES = Object.freeze({
  large: "large",
  medium: "medium",
  small: "small",
});

const sizeMap = {
  [SIZES.large]: "70px",
  [SIZES.medium]: "50px",
  [SIZES.small]: "40px",
};

const FloatingCircle = styled.span`
  display: flex;
  background: ${({ translucid }) =>
    translucid ? colors.translucidWhite : colors.white};
  width: ${({ size }) => sizeMap[size || SIZES.medium]};
  height: ${({ size }) => sizeMap[size || SIZES.medium]};
  box-shadow: ${({ translucid }) =>
    translucid ? null : `0 4px 10px ${colors.translucidBlack}`};
  border-radius: 100%;
  justify-content: center;
  align-items: center;
`;

FloatingCircle.propTypes = {
  size: PropTypes.oneOf(Object.values(SIZES)),
};

export default FloatingCircle;
