import React, { useContext, useRef, useLayoutEffect, useCallback } from "react";
import styled, { css } from "styled-components";
import { Visible } from "react-awesome-styled-grid";

import Settings from "src/stores/Settings";

import {
  icons,
  colors,
  spacing,
  mediaquery,
  rem,
  lineHeight,
  fontWeights,
} from "src/styles/variables";
import Facebook from "src/atoms/Vectors/Social/Facebook";
import Pinterest from "src/atoms/Vectors/Social/Pinterest";
import Instagram from "src/atoms/Vectors/Social/Instagram";
import Twitter from "src/atoms/Vectors/Social/Twitter";
import Youtube from "src/atoms/Vectors/Social/Youtube";
import ChevronDown from "src/atoms/Vectors/Chevrons/ChevronDown";
import { Spacer } from "src/organisms/PageComponentList";

import Link, { TYPES } from "src/atoms/Link";
import { Detail } from "src/atoms/Typography";
import Picture, { TYPES as PICTURE_TYPES } from "src/atoms/Picture";
// import trackEvent from "./trackEvent";

const isMaintenance = process.env.GATSBY_MAINTENANCE === "true";

const Footer = () => {
  const {
    socialLinks,
    legalLinks,
    certificates,
    translations,
    locale,
    countryFlag,
    localizedSlugs,
  } = useContext(Settings);

  const {
    facebookLinkTitle,
    instagramLinkTitle,
    PinterestLinkTitle,
    xLinkTitle,
    youtubeLinkTitle,
  } = useContext(Settings).translations;

  const floatingSelectorMarkets = ["CH"];
  const isFloatingSelectorMarket = floatingSelectorMarkets.includes(
    locale.currentRegion.region
  );
  // console.log("local: ", isFloatingSelectorMarket);

  const languageSelectorRef = useRef(null);

  const {
    currentRegion: { languages },
    currentLanguage,
  } = locale;

  const icon = (name) =>
    ({
      facebook: { name: Facebook, title: facebookLinkTitle },
      pinterest: { name: Pinterest, title: PinterestLinkTitle },
      instagram: { name: Instagram, title: instagramLinkTitle },
      twitter: { name: Twitter, title: xLinkTitle },
      youtube: { name: Youtube, title: youtubeLinkTitle },
    }[name]);

  const changeLocale = useCallback(() => {
    const newPathPrefix = languageSelectorRef.current.value;
    const localizedSlug = localizedSlugs.find((localizedSlug) => {
      return localizedSlug.pathPrefix === newPathPrefix;
    });
    if (localizedSlug && localizedSlug.slug) {
      location.href = `${newPathPrefix}${localizedSlug.slug}`;
    }
  }, [languageSelectorRef.current, localizedSlugs]);

  useLayoutEffect(() => {
    // Ensuire language is properly set on initial load
    setTimeout(() => {
      if (languageSelectorRef.current) {
        languageSelectorRef.current.value = currentLanguage.pathPrefix;
      }
    }, 0);
  }, []);

  return (
    <FooterContainer>
      <Spacer />
      {!!socialLinks && (
        <Social>
          {socialLinks.map((socialLink, key) => {
            const Icon = icon(socialLink.name);
            const IconName = Icon.name;
            return (
              <SocialItem
                key={key}
                target="_blank"
                href={socialLink.url}
                rel="noopener"
                title={Icon.title}
                // onClick={() => {
                //   trackEvent(socialLink.name);
                // }}
              >
                <IconName width={icons.l} />
              </SocialItem>
            );
          })}
        </Social>
      )}
      {!isMaintenance && !!legalLinks && (
        <Legal>
          {legalLinks.map(
            (legalLink, key) =>
              (legalLink.url && (
                <LegalItem key={key} {...legalLink} type={TYPES.navigation} />
              )) ||
              null
          )}
        </Legal>
      )}
      <BottomContainer>
        <div>
          <StyledLabelXS as="p">{translations.footerCopyright}</StyledLabelXS>
          {translations.footerCopyright2 && (
            <StyledLabelXS as="p">
              {translations.footerCopyright2}
            </StyledLabelXS>
          )}
          {translations.footerCopyright3 && (
            <StyledLabelXS as="p">
              {translations.footerCopyright3}
            </StyledLabelXS>
          )}
          {translations.footerCopyright4 && (
            <StyledLabelXS as="p">
              {translations.footerCopyright4}
            </StyledLabelXS>
          )}
          {translations.footerCopyright5 && (
            <StyledLabelXS as="p">
              {translations.footerCopyright5}
            </StyledLabelXS>
          )}
        </div>
        <RegionContainer
          className={isFloatingSelectorMarket ? "floating-selector-region" : ""}
          isCertificates={!!certificates}
        >
          <LanguageContainer>
            <FlagContainer
              className={
                isFloatingSelectorMarket ? "floating-selector-flag" : ""
              }
            >
              <Picture type={PICTURE_TYPES.fixed} small={countryFlag} />
            </FlagContainer>
            {languages.length > 1 ? (
              <LanguageSelectorContainer
                className={
                  isFloatingSelectorMarket ? "floating-selector-container" : ""
                }
              >
                <ArrowContainer
                  className={
                    isFloatingSelectorMarket ? "floating-selector-arrow" : ""
                  }
                >
                  <ChevronDown size={icons.s} />
                </ArrowContainer>
                <LanguageSelector
                  ref={languageSelectorRef}
                  value={currentLanguage.pathPrefix}
                  onChange={changeLocale}
                >
                  {languages.map((lang) => {
                    return (
                      <React.Fragment key={lang.short}>
                        <Visible xs sm>
                          <option value={lang.pathPrefix}>
                            {lang.short.toUpperCase()}
                          </option>
                        </Visible>
                        <Visible md lg xl>
                          <option value={lang.pathPrefix}>{lang.long}</option>
                        </Visible>
                      </React.Fragment>
                    );
                  })}
                </LanguageSelector>
              </LanguageSelectorContainer>
            ) : (
              <StyledLabelXS>
                <Visible xs sm>
                  {currentLanguage.short.toUpperCase()}
                </Visible>
                <Visible md lg xl>
                  {currentLanguage.long}
                </Visible>
              </StyledLabelXS>
            )}
          </LanguageContainer>
          {!!certificates && (
            <Certificates>
              {certificates.map(
                (certificateLink, key) =>
                  (certificateLink.image && (
                    <CertificateItem
                      key={key}
                      {...certificateLink}
                      type={TYPES.linkImage}
                    />
                  )) ||
                  null
              )}
            </Certificates>
          )}
        </RegionContainer>
      </BottomContainer>
    </FooterContainer>
  );
};

const FooterContainer = styled.div`
  width: 100%;
  padding: ${spacing.inset.xl};
  background: ${colors.white};
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Social = styled.div`
  display: flex;
  margin: ${spacing.stack.lg};

  ${mediaquery.md(css`
    margin: ${spacing.stack.sm};
  `)}
`;

const SocialItem = styled.a`
  margin: 0 ${spacing.default.xs};
`;

const Legal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${spacing.stack.lg};

  ${mediaquery.md(css`
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  `)}
`;

const LegalItem = styled(Link)`
  color: ${colors.darkGreen};
  margin: ${spacing.stack.md};

  ${mediaquery.md(css`
    margin: ${spacing.default.md};
  `)}
`;

const BottomContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;

  ${mediaquery.md(css`
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
  `)}
`;

const StyledLabelXS = styled(Detail)`
  color: ${colors.darkGrey};
`;

const RegionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  //flex-wrap: ${({ isCertificates }) => (isCertificates ? "wrap" : "nowrap")};
  flex: 0 0 auto;

  &.floating-selector-region {
    position: fixed;
    top: 25px;
    left: 20px;
    padding: 0 6px;
    background: ${colors.white};
    border-radius: 50px;
    box-shadow: 0 6px 10px 2px rgb(0 0 0 / 5%);
    z-index: 10;

    ${mediaquery.md(css`
      top: auto;
      left: auto;
      bottom: 35px;
      right: 85px;
      padding: 5px 10px;
    `)}
  }

  ${mediaquery.md(css`
    padding-left: 20px;
    margin-top: 0;
    margin-bottom: 0;
    align-items: flex-start;
  `)}
`;

const LanguageContainer = styled.div`
  display: flex;
  align-items: center;
`;

const LanguageSelectorContainer = styled.div`
  width: 50px;
  height: 30px;
  overflow: hidden;
  position: relative;
  display: flex;

  ${mediaquery.md(css`
    width: 100px;
  `)}

  &.floating-selector-container {
    width: 30px;

    ${mediaquery.md(css`
      width: 100px;
    `)}
  }
`;

const FlagContainer = styled.div`
  width: 16px;
  height: 16px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;

  &.floating-selector-flag {
    flex: 0 0 auto;
  }
`;

const ArrowContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  pointer-events: none;

  &.floating-selector-arrow {
    display: none;

    ${mediaquery.md(css`
      display: block;
    `)}
  }
`;

const LanguageSelector = styled.select`
  background: transparent;
  -webkit-appearance: none;
  border: none;
  outline: none;
  width: 120%;
  flex-shrink: 0;
  font-size: ${rem(10)};
  line-height: ${lineHeight(10, 17)};
  font-weight: ${fontWeights.book};
  color: ${colors.darkGrey};
  ${mediaquery.md(css`
    font-size: ${rem(12)};
    line-height: ${lineHeight(12, 18)};
  `)}
`;

const Certificates = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: ${spacing.default.md};
  margin-top: ${spacing.default.sm};
  flex: 0 0 auto;

  ${mediaquery.md(css`
    justify-content: flex-start;
    //flex-direction: row;
    //flex-wrap: wrap;
  `)}
`;

const CertificateItem = styled(Link)`
  height: 80px;
  max-width: 100px;
  flex: 0 0 auto;

  .gatsby-image-wrapper {
    overflow: unset !important;

    & > div {
      display: none;
    }

    img {
      position: static !important;
    }
  }
`;

export default Footer;
