import React from "react";
import { colors } from "src/styles/variables";

import SVG from "../SVG";

export default SVG(() => (
  <svg viewBox="0 0 40 40" fill="none">
    <path
      d="M18.988 23.55c-.574 3.03-1.275 5.933-3.35 7.45-.641-4.578.94-8.016 1.675-11.666-1.252-2.122.15-6.393 2.792-5.34 3.25 1.294-2.815 7.89 1.257 8.714 4.25.86 5.986-7.426 3.35-10.12-3.808-3.89-11.086-.089-10.191 5.481.217 1.362 1.615 1.775.558 3.655-2.437-.544-3.165-2.48-3.071-5.06.15-4.224 3.77-7.181 7.399-7.59 4.59-.517 8.898 1.696 9.493 6.044.67 4.906-2.072 10.22-6.98 9.838-1.33-.104-1.89-.767-2.932-1.405z"
      fill={colors.pinterest}
    />
  </svg>
));
