import loadable from "@loadable/component";

const naturalbifidus = loadable(() =>
  import("src/atoms/Lottie/NaturalBifidus")
);

export default {
  alldaywork: loadable(() => import("src/atoms/Lottie/AllDayWork")),
  bifidustrain: loadable(() => import("src/atoms/Lottie/BifidusStrain")),
  bean: loadable(() => import("src/atoms/Lottie/Bean")),
  beanfact: loadable(() => import("src/atoms/Lottie/BeanFact")),
  billionsbifidus: loadable(() => import("src/atoms/Lottie/BillionsBifidus")),
  birthofbifidus: loadable(() => import("src/atoms/Lottie/BirthOfBifidus")),
  boostcount: loadable(() => import("src/atoms/Lottie/BoostCount")),
  bottles: loadable(() => import("src/atoms/Lottie/Bottles")),
  bottlesfact: loadable(() => import("src/atoms/Lottie/BottlesFact")),
  brainjelly: loadable(() => import("src/atoms/Lottie/BrainJelly")),
  circle: loadable(() => import("src/atoms/Lottie/Circle")),
  circlefact: loadable(() => import("src/atoms/Lottie/CircleFact")),
  fizzfermentation: loadable(() => import("src/atoms/Lottie/FizzFermentation")),
  garrison: loadable(() => import("src/atoms/Lottie/Garrison")),
  glass: loadable(() => import("src/atoms/Lottie/Glass")),
  glassfact: loadable(() => import("src/atoms/Lottie/GlassFact")),
  gut: loadable(() => import("src/atoms/Lottie/Gut")),
  gutfact: loadable(() => import("src/atoms/Lottie/GutFact")),
  happybelly: loadable(() => import("src/atoms/Lottie/HappyBelly")),
  introprobio: loadable(() => import("src/atoms/Lottie/IntroProbio")),
  lemons: loadable(() => import("src/atoms/Lottie/Lemons")),
  lemonsfact: loadable(() => import("src/atoms/Lottie/LemonsFact")),
  makingmilk: loadable(() => import("src/atoms/Lottie/MakingMilk")),
  map: loadable(() => import("src/atoms/Lottie/Map")),
  mapfact: loadable(() => import("src/atoms/Lottie/MapFact")),
  naturalbifidus: naturalbifidus,
  naturanbifidus: naturalbifidus,
  no1: loadable(() => import("src/atoms/Lottie/No1")),
  notequal: loadable(() => import("src/atoms/Lottie/NotEqual")),
  oat: loadable(() => import("src/atoms/Lottie/Oat")),
  oatfact: loadable(() => import("src/atoms/Lottie/OatFact")),
  oneofakind: loadable(() => import("src/atoms/Lottie/OneOfAKind")),
  onlynatural: loadable(() => import("src/atoms/Lottie/OnlyNatural")),
  p: loadable(() => import("src/atoms/Lottie/P")),
  pfact: loadable(() => import("src/atoms/Lottie/PFact")),
  pc100: loadable(() => import("src/atoms/Lottie/Pc100")),
  pc100fact: loadable(() => import("src/atoms/Lottie/Pc100Fact")),
  prepro: loadable(() => import("src/atoms/Lottie/Prepro")),
  probiotics: loadable(() => import("src/atoms/Lottie/Probiotics")),
  probioticseverything: loadable(() =>
    import("src/atoms/Lottie/ProbioticsEverything")
  ),
  probioticsfact: loadable(() => import("src/atoms/Lottie/ProbioticsFact")),
  question: loadable(() => import("src/atoms/Lottie/Question")),
  questionfact: loadable(() => import("src/atoms/Lottie/QuestionFact")),
  ratedg: loadable(() => import("src/atoms/Lottie/RatedG")),
  soy: loadable(() => import("src/atoms/Lottie/Soy")),
  soyfact: loadable(() => import("src/atoms/Lottie/SoyFact")),
  thermos: loadable(() => import("src/atoms/Lottie/Thermos")),
  tubes: loadable(() => import("src/atoms/Lottie/Tubes")),
  tubesfact: loadable(() => import("src/atoms/Lottie/TubesFact")),
  whatsgoin: loadable(() => import("src/atoms/Lottie/WhatsGoin")),
  yoga: loadable(() => import("src/atoms/Lottie/Yoga")),
};
