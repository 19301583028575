import PropTypes from "prop-types";
import { animations } from "src/atoms/Illustration";

export const CardShape = PropTypes.shape({
  cardImage: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    url: PropTypes.string,
  }),
  category: PropTypes.object,
  cardTitle: PropTypes.string,
  cardHighlighted: PropTypes.bool,
  slug: PropTypes.string,
  id: PropTypes.string,
});

export const FactCardShape = PropTypes.shape({
  category: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
  frontTitle: PropTypes.node.isRequired,
  backTitle: PropTypes.node.isRequired,
  illustration: PropTypes.shape({
    name: PropTypes.oneOf(Object.keys(animations)).isRequired,
  }).isRequired,
  id: PropTypes.string,
});

export const ProductCardShape = PropTypes.shape({
  image: PropTypes.shape({
    width: PropTypes.number,
    height: PropTypes.number,
    url: PropTypes.string,
  }),
  cardTitle: PropTypes.string,
  name: PropTypes.string,
  slug: PropTypes.string,
  id: PropTypes.string,
});

export const TrackerCategoryCardShape = PropTypes.shape({
  title: PropTypes.string,
  slug: PropTypes.string,
  backgroundColor: PropTypes.string.isRequired,
  percent: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.object,
  id: PropTypes.string,
  carryState: PropTypes.object,
});
