import React, { useContext } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Settings from "src/stores/Settings";

import NumericFact from "src/atoms/NumericFact";

const NumericFactList = React.memo(({ facts, className, align }) => {
  const { useTrafficLightNutritionFacts } = useContext(Settings);

  return (
    <Outer
      className={className}
      align={align}
      useTrafficLight={useTrafficLightNutritionFacts}
    >
      {facts.map((fact, key) => (
        <NumericFact key={key} {...fact} align={align} />
      ))}
    </Outer>
  );
});

NumericFactList.propTypes = {
  className: PropTypes.string,
  facts: PropTypes.arrayOf(PropTypes.shape(NumericFact.propTypes)),
  align: PropTypes.string,
};

const Outer = styled.div`
  display: flex;
  width: 100%;
  justify-content: ${({ align, useTrafficLight }) =>
    useTrafficLight
      ? "center"
      : align === "left"
      ? "flex-start"
      : "space-around"};

  flex-wrap: ${({ useTrafficLight }) => (useTrafficLight ? "nowrap" : "wrap")};
  gap: ${({ useTrafficLight }) => (useTrafficLight ? "1px" : "initial")};
`;

export default NumericFactList;
