import React from "react";

import SVG from "../SVG";

export default SVG(() => (
  <svg viewBox="0 0 24 24" fill="none">
    <g transform="translate(-3 0)">
      <path
        d="M14.045 12l-3.014 2v-4l3.014 2z"
        fill="#02BA44"
        stroke="#02BA44"
        strokeLinecap="round"
      />
      <path
        d="M19.572 12c0 4.14-3.372 7.5-7.536 7.5S4.5 16.14 4.5 12c0-4.14 3.372-7.5 7.536-7.5s7.536 3.36 7.536 7.5z"
        stroke="#02BA44"
      />
    </g>
  </svg>
));
