import React from "react";
import styled from "styled-components";

import { colors, rem } from "src/styles/variables";

const NotificationBadge = styled.div`
  display: flex;
  width: ${rem(18)};
  height: ${rem(18)};
  padding: 4px;
  background: ${colors.pink};
  border-radius: 100%;
  color: ${colors.white};
  font-size: ${rem(10)};
  font-weight: bold;
  align-items: center;
  justify-content: center;
`;

export default NotificationBadge;
