import React, { useState, useRef, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { colors, mediaquery, icons } from "src/styles/variables";
import { Visible } from "react-awesome-styled-grid";
import Picture, { FITS as PICTURE_FITS } from "src/atoms/Picture";
import Settings from "src/stores/Settings";
import FloatingButton, { SIZES } from "src/molecules/FloatingButton";
import Play from "src/atoms/Vectors/Standard/Play";
import Pause from "src/atoms/Vectors/Standard/Pause";
import SoundOn from "src/atoms/Vectors/Standard/SoundOn";
import SoundOff from "src/atoms/Vectors/Standard/SoundOff";
import Expand from "src/atoms/Vectors/Standard/Expand";
import Close from "src/atoms/Vectors/Standard/Close";
import ProgressBar from "src/atoms/ProgressBar";
import throttle from "src/utils/throttle";
import { Detail } from "src/atoms/Typography";

const LANDSCAPE = (9 / 16) * 100;
const PORTRAIT = (16 / 9) * 100;

const VideoPush = ({
  cloudinaryVideo,
  mobileCloudinaryVideo,
  videoCover,
  videoCoverMobile,
  forcePortrait = false,
  squareCorners = false,
  trackInteraction = undefined,
  ...rest
}) => {
  const { closeTitle } = useContext(Settings).translations;
  const [hasPlayed, setHasPlayed] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [timestamp, setTimestamp] = useState([]);
  const [isMuted, setIsMuted] = useState(false);
  const [isFullScreen, setFullScreen] = useState(false);
  const [videoProgress, setVideoProgress] = useState(0);
  const [protocol, setProtocol] = useState("secure_url");
  const [isDraggingProgrssBar, setIsDraggingProgressBar] = useState(false);
  const videoPlayer = useRef(null);
  const progressBar = useRef(null);
  const mobileVideo = mobileCloudinaryVideo || cloudinaryVideo;
  const { noVideoSupport } = useContext(Settings).translations;

  /**
   * Video controlling
   */
  const playVideo = () => {
    videoPlayer.current.play();
    setIsPlaying(true);
    if (!hasPlayed) {
      setHasPlayed(true);
    }
  };

  const pauseVideo = () => {
    videoPlayer.current.pause();
    setIsPlaying(false);
  };

  const toggleMuteVideo = () => {
    setIsMuted(!isMuted);
  };

  const togglePlayVideo = () => {
    if (trackInteraction) {
      trackInteraction();
    }
    if (isPlaying) {
      pauseVideo();
    } else {
      playVideo();
    }
  };

  const endVideo = () => {
    // Reset the poster image
    videoPlayer.current.load();
    setVideoProgress(0);
    setIsPlaying(false);
    setHasPlayed(false);
    closeFullScreen();
  };

  /**
   * Full screen
   */

  const goFullScreen = () => {
    setFullScreen(true);
    document.body.style.overflow = "hidden";
  };

  const closeFullScreen = () => {
    setFullScreen(false);
    document.body.style.overflow = "";
  };

  /**
   * Video position and progress bar
   */

  // Updates the progress bar length
  const updateVideoProgressPosition = () => {
    var progress =
      videoPlayer.current.currentTime / videoPlayer.current.duration;
    setVideoProgress(progress);
  };

  // Updates the numbers in the video timestamp for visibility
  const updateTimestamp = (e) => {
    if (hasPlayed) {
      const progress = getTimeInProgressBar(e.pageX);
      const totalSeconds = progress.time.toFixed();
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds - hours * 3600) / 60);
      const seconds = totalSeconds - hours * 3600 - minutes * 60;

      const uiHours = hours ? (hours < 10 ? `0${hours}:` : `${hours}:`) : "";
      const uiMinutes = minutes
        ? minutes < 10
          ? `0${minutes}:`
          : `${minutes}:`
        : "00:";
      const uiSeconds = seconds < 10 ? `0${seconds}` : seconds;

      const timestamp = `${uiHours}${uiMinutes}${uiSeconds}`;
      setTimestamp([timestamp, progress.position]);
    }
  };

  // Clears the timestamp values
  const removeTimestamp = () => {
    setTimestamp([]);
  };

  // Gets the video time and position based on a hover position in the progress bar
  const getTimeInProgressBar = (hoverPosition) => {
    const rect = progressBar.current.getBoundingClientRect();
    const left = hoverPosition - rect.left;
    const percentage = left / rect.width;
    return { time: videoPlayer.current.duration * percentage, position: left };
  };

  // Updates the current play time of the video to one selected in the progress bar
  const changeVideoProgress = (e) => {
    const progress = getTimeInProgressBar(e.pageX);
    videoPlayer.current.currentTime = progress.time;
    updateVideoProgressPosition();
    updateTimestamp(e);
  };

  /**
   * Progress bar events
   */
  const progressDragStart = (e) => {
    setIsDraggingProgressBar(true);
    changeVideoProgress(e);
  };

  const progressDrag = (e) => {
    if (isDraggingProgrssBar) {
      changeVideoProgress(e);
    } else {
      updateTimestamp(e);
    }
  };

  const progressDragEnd = (e) => {
    removeTimestamp();
    if (isDraggingProgrssBar) {
      setIsDraggingProgressBar(false);
    }
  };

  const throttledProgressBarMove = (e) => throttle(progressDrag(e), 100);

  useEffect(() => {
    if (location.protocol === "http:") {
      setProtocol("url");
    }

    videoPlayer.current.addEventListener(
      "timeupdate",
      updateVideoProgressPosition
    );
    videoPlayer.current.addEventListener("ended", endVideo);

    progressBar.current.addEventListener("click", changeVideoProgress);
    progressBar.current.addEventListener("mousedown", progressDragStart);
    progressBar.current.addEventListener("mousemove", throttledProgressBarMove);
    progressBar.current.addEventListener("mouseout", progressDragEnd);

    return () => {
      videoPlayer.current.removeEventListener(
        "timeupdate",
        updateVideoProgressPosition
      );
      videoPlayer.current.removeEventListener("ended", endVideo);

      progressBar.current.removeEventListener("click", changeVideoProgress);
      progressBar.current.removeEventListener("mousedown", progressDragStart);
      progressBar.current.removeEventListener(
        "mousemove",
        throttledProgressBarMove
      );
      progressBar.current.removeEventListener("mouseout", progressDragEnd);
    };
  });

  useEffect(() => {
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  return (
    <AspectRatio forcePortrait={forcePortrait} {...rest}>
      <VideoContainer
        squareCorners={squareCorners}
        isFullScreen={isFullScreen}
        isPlaying={isPlaying}
      >
        {!hasPlayed && (
          <Poster
            fit={PICTURE_FITS.cover}
            small={videoCoverMobile || videoCover}
            medium={videoCover}
          />
        )}
        <Video
          ref={videoPlayer}
          muted={isMuted}
          poster="data:image/gif,AAAA"
          onClick={togglePlayVideo}
          playsInline
          hasPlayed={hasPlayed}
        >
          <Visible md lg xl>
            <source
              src={cloudinaryVideo[protocol]}
              type={`video/${cloudinaryVideo.format}`}
            />
          </Visible>
          <Visible xs sm>
            <source
              src={mobileVideo[protocol]}
              type={`video/${mobileVideo.format}`}
            />
          </Visible>
          {noVideoSupport}
        </Video>
        <VideoProgressBar
          progress={videoProgress}
          className="progress-bar"
          ref={progressBar}
          hasPlayed={hasPlayed}
        />
        <PlayButton
          size={SIZES.medium}
          onClick={togglePlayVideo}
          isPlaying={isPlaying}
        >
          {isPlaying ? <Pause size={icons.xs} /> : <Play size={icons.xs} />}
        </PlayButton>
        {hasPlayed && (
          <MuteButton
            size={SIZES.medium}
            onClick={toggleMuteVideo}
            isPlaying={isPlaying}
          >
            {isMuted ? (
              <SoundOff size={icons.xs} />
            ) : (
              <SoundOn size={icons.xs} />
            )}
          </MuteButton>
        )}
        {(isFullScreen && (
          <CloseFullScreenButton
            size={SIZES.medium}
            onClick={closeFullScreen}
            isPlaying={isPlaying}
            title={closeTitle}
          >
            <Close size={icons.xs} />
          </CloseFullScreenButton>
        )) ||
          (hasPlayed && (
            <FullScreenButton
              size={SIZES.medium}
              onClick={goFullScreen}
              isPlaying={isPlaying}
            >
              <Expand size={icons.s} />
            </FullScreenButton>
          ))}
      </VideoContainer>
      <VideoTime position={timestamp[1]}>{timestamp[0]}</VideoTime>
    </AspectRatio>
  );
};

const AspectRatio = styled.div`
  padding-bottom: ${PORTRAIT}%;
  position: relative;

  ${mediaquery.md(css`
    padding-bottom: ${({ forcePortrait }) =>
      forcePortrait ? PORTRAIT : LANDSCAPE}%;
  `)}
`;

const VideoContainer = styled.div`
  width: ${({ isFullScreen }) => (isFullScreen ? "100vw" : "100%")};
  height: ${({ isFullScreen }) => (isFullScreen ? "100vh" : "100%")};
  position: ${({ isFullScreen }) => (isFullScreen ? "fixed" : "absolute")};
  z-index: ${({ isFullScreen }) => (isFullScreen ? "5" : null)};
  top: 0;
  left: 0;
  background: #000;
  border-radius: ${({ isFullScreen, squareCorners }) =>
    isFullScreen || squareCorners ? "0" : "16px"};
  overflow: hidden;
`;

const Poster = styled(Picture)`
  position: absolute;
  top: 0;
  left: 0;
`;

const Video = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: ${({ hasPlayed }) => (hasPlayed ? "1" : "0")};
`;

const MediaButton = styled(FloatingButton)`
  opacity: ${({ isPlaying }) => (isPlaying ? "0" : "1")};
  position: absolute;
  cursor: pointer;
  transition: opacity 1s cubic-bezier(0.47, 0, 0.37, 1) 0.3s;

  ${VideoContainer}:hover & {
    opacity: 1;
  }
`;

const PlayButton = styled(MediaButton)`
  bottom: 28px;
  left: 20px;
`;

const FullScreenButton = styled(MediaButton)`
  bottom: 28px;
  right: 20px;
`;

const CloseFullScreenButton = styled(MediaButton)`
  top: 20px;
  right: 20px;
`;

const MuteButton = styled(MediaButton)`
  bottom: 98px;
  left: 20px;
`;

const VideoProgressBar = styled(ProgressBar)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  cursor: pointer;
  display: ${({ hasPlayed }) => (hasPlayed ? "block" : "none")};
`;

const VideoTime = styled(Detail)`
  position: absolute;
  padding: 5px;
  bottom: 16px;
  background: ${colors.white};
  color: ${colors.activiaGreen};
  border-radius: 3px;
  opacity: ${({ position }) => (position ? "1" : "0")};
  transition: opacity 0.3s cubic-bezier(0.47, 0, 0.37, 1);
  z-index: 2;
  left: ${({ position }) => position || "0"}px;
  transform: translateX(-50%);
  box-shadow: 0 4px 10px ${colors.darkGrey};
  display: inline-block;
`;

VideoPush.propTypes = {
  cloudinaryVideo: PropTypes.shape({
    url: PropTypes.string.isRequired,
    secure_url: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired,
  }).isRequired,
  mobileCloudinaryVideo: PropTypes.shape({
    url: PropTypes.string.isRequired,
    secure_url: PropTypes.string.isRequired,
    format: PropTypes.string.isRequired,
  }),
  videoCover: PropTypes.object.isRequired,
  videoCoverMobile: PropTypes.object.isRequired,
  forcePortrait: PropTypes.bool,
  squareCorners: PropTypes.bool,
  trackInteraction: PropTypes.func,
};

export default VideoPush;
