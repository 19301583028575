import React from "react";

import SVG from "../SVG";

export default SVG(() => (
  <svg viewBox="0 0 16 16" fill="none">
    <path
      d="M7.00609 8.9203C6.03382 7.94803 5.90821 6.48265 6.72697 5.66389L9.40653 2.98433C10.2253 2.16557 11.6907 2.29118 12.6629 3.26345C13.6352 4.23572 13.7608 5.70111 12.9421 6.51986L10.9696 8.49232"
      stroke="white"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.4203 7.50609C9.39257 8.47837 9.51818 9.94375 8.69942 10.7625L6.01986 13.4421C5.2011 14.2608 3.73572 14.1352 2.76345 13.1629C1.79118 12.1907 1.66557 10.7253 2.48433 9.90654L4.45678 7.93408"
      stroke="white"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
));
