import React, { useState, useEffect } from "react";
import SVG from "react-inlinesvg";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import { colors, mediaquery, spacing } from "src/styles/variables";
import { LabelS } from "src/atoms/Typography";

const LinkPill = React.memo(({ children, icon, url, ...rest }) => {
  const [activeClass, setActiveClass] = useState("");

  useEffect(() => {
    decodeURI(window.location.href).endsWith(url + "/") ||
    decodeURI(window.location.href).indexOf(url + "/?") > 0
      ? setActiveClass("active")
      : setActiveClass("");
  }, []);

  return (
    <Outer {...rest} className={activeClass}>
      {!!icon && (
        <Icon src={icon.file.url} width={20} height="100%" title="React" />
      )}
      <LabelS>{children}</LabelS>
    </Outer>
  );
});

LinkPill.propTypes = {
  children: PropTypes.node,
  icon: PropTypes.instanceOf(Object),
  url: PropTypes.string,
};

const Outer = styled.button`
  background: ${colors.white};
  height: 50px;
  padding: 0 30px;
  color: ${colors.activiaMediumGreen};
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  position: relative;
  transition: all 0.2s;

  /* forcing flex as the component can be used as an a tag */
  display: flex;
  align-items: center;
  justify-content: center;

  /* prevent weird text overflow behaviour */
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 0;
    background: ${colors.activiaMediumGreen};
    transition: all 0.1s;
  }

  &:hover,
  &.active {
    border-bottom-width: 3px;

    &:before {
      height: 3px;
    }
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }

  ${mediaquery.md(css`
    width: auto;
    border-radius: 999px;
    border: 1px solid ${colors.activiaMediumGreen};

    &:hover,
    &.active {
      background: ${colors.activiaMediumGreen};
      color: ${colors.white};

      svg {
        fill: ${colors.white};
      }
    }

    &:before {
      display: none;
    }
  `)}
`;

const Icon = styled(SVG)`
  fill: ${colors.activiaMediumGreen};
  margin-right: ${spacing.default.xs};
  transition: all 0.2s;
`;

export default LinkPill;
