import React from "react";
import SVG from "react-inlinesvg";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import { colors, mediaquery, outline, spacing } from "src/styles/variables";
import { Body, LabelS } from "src/atoms/Typography";

export const SIZES = {
  medium: "medium",
  small: "small",
};

const innerMap = {
  [SIZES.medium]: Body,
  [SIZES.small]: LabelS,
};

const Button = React.memo(({ size, children, buttonstyle, icon, ...rest }) => {
  const Inner = innerMap[size || SIZES.medium];

  return (
    <Outer size={size} buttonstyle={buttonstyle} {...rest}>
      {!!icon && (
        <Icon
          fill={
            buttonstyle === "Light" ? colors.activiaMediumGreen : colors.white
          }
          src={icon.file.url}
          width={20}
          height="100%"
          title="React"
        />
      )}
      <Inner>{children}</Inner>
    </Outer>
  );
});

Button.propTypes = {
  size: PropTypes.oneOf(Object.values(SIZES)),
  children: PropTypes.node,
  buttonstyle: PropTypes.string,
  icon: PropTypes.instanceOf(Object),
};

const heightMap = {
  [SIZES.medium]: "50px",
  [SIZES.small]: "40px",
};

const paddingMap = {
  [SIZES.medium]: "0 30px",
  [SIZES.small]: "0 24px",
};

const Outer = styled.button`
  background: linear-gradient(
    to top,
    ${({ buttonstyle }) =>
        buttonstyle === "Light"
          ? colors.activiaLightGradient[0]
          : colors.activiaGradient[0]}
      0%,
    ${({ buttonstyle }) =>
        buttonstyle === "Light"
          ? colors.activiaLightGradient[1]
          : colors.activiaGradient[1]}
      100%
  );
  height: ${({ size }) => heightMap[size || SIZES.medium]};
  padding: ${({ size }) => paddingMap[size || SIZES.medium]};
  border-radius: 999px;
  color: ${({ buttonstyle }) =>
    buttonstyle === "Light" ? colors.activiaMediumGreen : colors.white};
  cursor: pointer;
  text-decoration: none;
  width: 100%;
  ${mediaquery.sm(css`
    width: auto;
  `)}

  /* forcing flex as the component can be used as an a tag */
  display: flex;
  align-items: center;
  justify-content: center;

  /* prevent weird text overflow behaviour */
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;

  &:focus {
    outline: none;
    box-shadow: ${outline};
  }
`;

const Icon = styled(SVG)`
  margin-right: ${spacing.default.xs};
`;

export default Button;
