import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Visible } from "react-awesome-styled-grid";

import Settings from "src/stores/Settings";
import Menu from "./Menu";
import LongMenu from "./LongMenu";
import MobileMenu from "./MobileMenu";

import { colors, mediaquery } from "src/styles/variables";
import {
  HOME_LINK,
  MOBILE_MENU_ANIMATION_DURATION,
  HEADER_HEIGHT,
  BEZIER,
} from "./constants";

const Header = React.memo(({ green = true, className }) => {
  const { headerLinks } = useContext(Settings);

  const [opened, setOpened] = useState(false);
  const [willClose, setWillClose] = useState(opened);

  if (headerLinks.length === 3) {
    headerLinks.unshift(HOME_LINK);
  }

  return (
    <header className={className}>
      <Visible xs sm md>
        <CurvedContainer>
          <Curved opened={willClose} curved>
            <MobileMenu
              green={green}
              opened={opened}
              setOpened={setOpened}
              willClose={willClose}
              setWillClose={setWillClose}
            />
          </Curved>
        </CurvedContainer>
      </Visible>
      <Visible lg xl>
        {(headerLinks.length > 4 && (
          <CurvedContainer>
            <Curved opened={willClose} curved={false}>
              <LongMenu
                green={green}
                opened={opened}
                setOpened={setOpened}
                setWillClose={setWillClose}
              />
            </Curved>
          </CurvedContainer>
        )) || (
          <CurvedContainer>
            <Curved opened={willClose} curved>
              <Menu
                green={green}
                opened={opened}
                setOpened={setOpened}
                setWillClose={setWillClose}
              />
            </Curved>
          </CurvedContainer>
        )}
      </Visible>
    </header>
  );
});

Header.propTypes = {
  green: PropTypes.bool,
  className: PropTypes.string,
};

const CurvedContainer = styled.div`
  overflow: hidden;
`;

const transition = (boolean, property) => css`
  transition: ${property} ${MOBILE_MENU_ANIMATION_DURATION * 0.3}ms
    ${!boolean ? MOBILE_MENU_ANIMATION_DURATION * 0.7 : 0}ms ${BEZIER};
`;

const Curved = styled.div`
  position: relative;
  height: ${({ opened }) => (opened ? "570px" : `${HEADER_HEIGHT.mobile}px`)};
  border-radius: ${({ curved, opened }) =>
    curved
      ? `0 0 100% 100% / 0 0 ${opened ? 150 : 40}px ${opened ? 150 : 40}px`
      : 0};
  width: 120%;
  left: -10%;
  padding: 0 10%;
  transition: height ${MOBILE_MENU_ANIMATION_DURATION}ms ${BEZIER},
    border-radius ${MOBILE_MENU_ANIMATION_DURATION}ms ${BEZIER};
  transform: translate3d(
    0,
    0,
    0
  ); /* fixes a bug on safari, caused by the border-radius/overflow/transition combo */
  overflow: hidden;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50px;
    background: linear-gradient(
      to top,
      ${colors.activiaGreenGradient[0]} 0%,
      ${colors.activiaGreenGradient[1]} 100%
    );
    opacity: ${({ opened }) => (opened ? 1 : 0)};
    ${({ opened }) => transition(opened, "opacity")}
    pointer-events: none;
  }

  ${mediaquery.md(css`
    height: ${({ opened }) => (opened ? "500px" : `${HEADER_HEIGHT.mobile}px`)};
  `)}

  /* Desktop style */
  ${mediaquery.lg(css`
    height: ${HEADER_HEIGHT.desktop}px;
  `)}
`;

export default Header;
