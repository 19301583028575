import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

import {
  colors,
  spacing,
  mediaquery,
  paddingWidth,
} from "src/styles/variables";
import Link, { TYPES } from "src/atoms/Link";
import { Container, Row, Col } from "react-awesome-styled-grid";

const LinkNavigation = ({ ctaList }) => {
  const offset = { lg: 3 };

  return (
    <Outer as="section">
      <ContainerStyled>
        <Row>
          <Col lg={26} offset={offset}>
            <CtasContainer>
              {!!ctaList &&
                ctaList.map((ctaItem, key) => (
                  <ButtonContainer key={key}>
                    <Link {...ctaItem} type={TYPES.linkPill} />
                  </ButtonContainer>
                ))}
            </CtasContainer>
          </Col>
        </Row>
      </ContainerStyled>
    </Outer>
  );
};

LinkNavigation.propTypes = {
  ctaList: PropTypes.arrayOf(PropTypes.object),
};

// const TheGutLifeWelcomeStyled = styled(TheGutLifeWelcome)`
//   position: relative;
//   z-index: 1;
//   margin: ${DIMENSIONS.gutter / 2}px;
// `;

const Outer = styled.div`
  padding-bottom: 10px;
  overflow: auto;

  ${mediaquery.md(css`
    padding-bottom: 0;
    overflow: hidden;
  `)}
`;

const ContainerStyled = styled(Container)`
  ${mediaquery.xs(css`
    padding: 0;
  `)};
  ${mediaquery.md(css`
    border-bottom: 0;
    padding: 0 ${paddingWidth.md}rem;
  `)}
  ${mediaquery.lg(css`
    padding: 0 ${paddingWidth.lg}rem;
  `)}
${mediaquery.xl(css`
    padding: 0 ${paddingWidth.xl}rem;
  `)}
`;

const CtasContainer = styled.div`
  display: flex;
  padding: 0;

  ${mediaquery.sm(css`
    flex-direction: row;
  `)}
  ${mediaquery.md(css`
    justify-content: flex-start;
    flex-wrap: wrap;
  `)}
`;

const ButtonContainer = styled.div`
  display: flex;
  flex: 1 1 33.33%;
  border-bottom: 1px solid ${colors.activiaMediumGreen};

  ${mediaquery.md(css`
    justify-content: center;
    margin: ${spacing.default.xs} ${spacing.default.xs} 0 0;
    flex: 0 0 auto;
    border-bottom: 0;
  `)}
`;

export default LinkNavigation;
